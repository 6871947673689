import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { organizationHandoffKeys } from 'utils/query-keys'

const useOrganizationHandoff = (orgId: string) => {
  return useQuery(
    organizationHandoffKeys.index(orgId),
    () => fetchOrganizationHandoff(orgId),
    {
      enabled: !!orgId,
      retry: 1,
    }
  )
}

const fetchOrganizationHandoff = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/organization_handoff`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )

  response = handleErrors(response)
  const json = await response.json()
  return json
}

export default useOrganizationHandoff
