import useOrganizationHubspotNotes from 'hooks/use-organization-hubspot-notes'
import { Organization } from 'interfaces/organization'
import moment from 'moment'
import { Card, Table } from 'react-bootstrap'

interface OrganizationHubspotNotesProps {
  orgId: string
  org?: Organization
}
const OrganizationHubspotNotes = ({
  orgId,
  org,
}: OrganizationHubspotNotesProps) => {
  const { data: hubspotNotes } = useOrganizationHubspotNotes(orgId)
  return (
    <Card className="mt-4">
      <Card.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '24px',
          }}
        >
          Customer Notes
        </p>
        {org?.internal_crm_url && (
          <span
            style={{
              backgroundColor: '#303030',
              color: '#fff',
              padding: '8px',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(org.internal_crm_url, '_blank')
            }}
          >
            Hubspot Deal
          </span>
        )}
      </Card.Header>
      <Card.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>Created</th>
              <th>Author</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {hubspotNotes && hubspotNotes.length > 0
              ? hubspotNotes.map((note) => (
                  <tr key={note.hs_object_id}>
                    <td>{moment(note.hs_timestamp).format('MM/DD/YYYY')}</td>
                    <td>
                      {note.hubspot_owner.first_name}{' '}
                      {note.hubspot_owner.last_name} ({note.hubspot_owner.email}
                      )
                    </td>
                    <td>
                      <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: note.hs_note_body,
                        }}
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
export default OrganizationHubspotNotes
