import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { EntitlementGrouping } from 'interfaces/entitlement-grouping'

interface ResponseData {
  data: {
    entitlement_groupings: EntitlementGrouping[]
  }
}

const fetchEntitlementGroupings = async () => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v2/entitlement_groupings`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: ResponseData = await response.json()
    return json.data.entitlement_groupings
  } catch (error) {
    console.error('error', error)
  }
}

export const useEntitlementGroupings = () => {
  return useQuery(
    [`admin/api/v3/entitlement_groupings`],
    () => fetchEntitlementGroupings(),
    {
      enabled: true,
    }
  )
}
