import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { LinqProduct } from 'interfaces/linq-product'
import useProductJigTemplates from 'hooks/use-product-jig-templates'
import { ProductJigTemplate } from 'interfaces/product-jig-template'

const ProductJigTemplatesPage = () => {
  const history = useHistory()

  const [productJigTemplates, setProductJigTemplates] = useState<
    ProductJigTemplate[]
  >([])

  const { data, isFetching } = useProductJigTemplates()

  useEffect(() => {
    if (data) {
      setProductJigTemplates(data.product_jig_templates)
    }
  }, [data])

  const handleProductJigTemplateClicked = (
    productJigTemplate: ProductJigTemplate
  ) => {
    history.push(`/product-jig-templates/${productJigTemplate.id}/edit`)
  }

  const handleNewProductJigTemplateClick = () => {
    history.push(`/create-product-jig-template`)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/product_jig_templates">
          Product Jig Templates
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Product Jig Templates</h3>
      <Button
        className="mb-3"
        variant="success"
        onClick={handleNewProductJigTemplateClick}
      >
        Create New Jig Template
      </Button>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Jig Name</th>
            <th>Updated At</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            productJigTemplates?.map((productJigTemplate) => (
              <tr
                className="pointer"
                key={productJigTemplate.id}
                onClick={() =>
                  handleProductJigTemplateClicked(productJigTemplate)
                }
              >
                <td>{productJigTemplate.id}</td>
                <td className="">{productJigTemplate.jig_name}</td>
                <td>{moment(productJigTemplate.updated_at).format('lll')}</td>
                <td>{moment(productJigTemplate.created_at).format('lll')}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default ProductJigTemplatesPage
