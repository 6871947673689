import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { User } from 'interfaces/user'

const fetchUsersWithQuery = async (query: string) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v1/users/search?query=${encodeURIComponent(
        query
      )}`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: {
      users: User[]
    } = await response.json()
    return json
  } catch (error) {
    console.error('error', error)
  }
}

const useAdminUsersSearch = (query: string) => {
  return useQuery(
    ['admin/api/v1/users/search', query],
    () => fetchUsersWithQuery(query),
    {
      enabled: !!query,
    }
  )
}

export default useAdminUsersSearch
