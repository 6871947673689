import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import LinqProductForm from 'components/linq-product-form'
import useMutationCreateLinqProduct from 'hooks/use-mutation-create-linq-product'
import { LinqProductData } from 'interfaces/linq-product'
import ZakekeProductForm from 'components/zakeke-product-form'
import { ZakekeData, ZakekeProduct } from 'interfaces/zakeke-product'
import useZakekeProducts from 'hooks/use-zakeke-products'
import useUpdateZakekeProduct from 'hooks/use-update-zakeke-product'

const ZakekeProductEditPage = () => {
  const { zakekeProductId } = useParams<any>()
  const [zakekeProduct, setZakekeProduct] = useState<any>({})
  const updateZakekeProductMutation = useUpdateZakekeProduct()
  const history = useHistory()
  const { data, isFetching } = useZakekeProducts()

  useEffect(() => {
    if (data) {
      const zakekeProduct = data.zakeke_products.find(
        (zakekeProduct) => zakekeProduct.id === parseInt(zakekeProductId)
      )
      setZakekeProduct(zakekeProduct)
    }
    // eslint-disable-next-line
  }, [data])

  const handleSaveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    updateZakekeProductMutation.mutate(
      { zakekeProduct },
      {
        onSuccess: (data) => {
          // history.push(`/product-jig-templates/${productJigTemplate.id}/edit`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/zakeke_products">
          Zakeke Products
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href={`/zakeke_products/${zakekeProduct.id}/edit`}
          active
        >
          Edit Zakeke Product
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <h3>Loading...</h3>
      ) : (
        <>
          <h3 className="mt-4 mb-4">Edit Zakeke Product</h3>
          <ZakekeProductForm
            zakekeProduct={zakekeProduct}
            setZakekeProduct={setZakekeProduct}
            handleSaveClick={handleSaveClick}
          />
        </>
      )}
    </Container>
  )
}

export default ZakekeProductEditPage
