import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import { useMutation, useQueryClient } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import {
  organizationProductCreditsKeys,
  organizationSubscriptionItemsKeys,
} from 'utils/query-keys'

const useOrganizationDeleteProductCredit = (orgId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (productId: string) =>
      fetch(
        `${BASE_API_URL}/api/v3/organizations/${orgId}/product_credits/${productId}`,
        {
          method: API_METHODS.DELETE,
          headers: apiHeaders(store.getState()),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          organizationProductCreditsKeys.detail(orgId)
        )
        queryClient.invalidateQueries(
          organizationSubscriptionItemsKeys.detail(orgId)
        )
      },
    }
  )
}

export default useOrganizationDeleteProductCredit
