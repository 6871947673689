import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { BulkUpload } from 'interfaces/bulk-upload'

interface ResponseData {
  data: {
    bulk_uploads: BulkUpload[]
  }
}

const fetchOrganizationBulkUploads = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/organizations/${orgId}/bulk_uploads`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json: ResponseData = await response.json()
  return json.data.bulk_uploads
}

const useOrganizationBulkUploads = ({ orgId }: { orgId: string }) => {
  return useQuery(organizationsKeys.bulkUploads.index(orgId), () =>
    fetchOrganizationBulkUploads(orgId)
  )
}

export default useOrganizationBulkUploads
