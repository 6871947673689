import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import useConstructUrl from './use-construct-url'
import { Organization } from 'interfaces/organization'
import { PaginatedData } from 'interfaces/paginated-data'

const fetchOrganizations = async (url: string) => {
  let response = await fetch(url, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json: {
    data: {
      organizations: Organization[]
    } & PaginatedData
  } = await response.json()
  return json.data
}

type ApiParams = {
  query: string
  page: string
  results_per_page: string
}

const useOrganizationsSearch = ({
  query,
  page,
  results_per_page,
}: ApiParams) => {
  const { constructUrl } = useConstructUrl()
  const apiParams = {
    query,
    page,
    results_per_page,
  }
  const url = constructUrl(
    `${BASE_API_URL}/admin/api/v2/organizations/search`,
    apiParams
  )
  return useQuery(
    [organizationsKeys.all, query],
    () => fetchOrganizations(url),
    {
      enabled: !!query,
    }
  )
}

export default useOrganizationsSearch
