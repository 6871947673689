import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { internalAdmins } from 'utils/query-keys'
import { User } from 'interfaces/user'

type Data = {
  data: {
    internal_admins: User[]
  }
}

const fetchInternalAdmins = async () => {
  let response = await fetch(`${BASE_API_URL}/admin/api/v2/internal_admins`, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json: Data = await response.json()
  return json.data.internal_admins
}

export const useGetInternalAdmins = () => {
  return useQuery(internalAdmins.all, () => fetchInternalAdmins(), {
    enabled: true,
  })
}
