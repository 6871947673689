import React, { useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import LinqProductForm from 'components/linq-product-form'
import useMutationCreateLinqProduct from 'hooks/use-mutation-create-linq-product'
import { LinqProductData } from 'interfaces/linq-product'

const ProductCreatePage = () => {
  const [linqProduct, setLinqProduct] = useState<LinqProductData>({
    name: '',
    prefix: '',
  })
  const createLinqProductMutation = useMutationCreateLinqProduct()
  const history = useHistory()

  const handleSaveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    if (!linqProduct?.prefix?.endsWith('-')) {
      alert('The product prefix must end in a "-", you idiot sandwich!')
      return
    }
    createLinqProductMutation.mutate(
      { linqProduct },
      {
        onSuccess: (data) => {
          history.push(`/products/${data.id}`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Linq Products</Breadcrumb.Item>
        <Breadcrumb.Item href="/create-linq-product" active>
          Create Linq Product
        </Breadcrumb.Item>
      </Breadcrumb>
      <>
        <h3 className="mt-4 mb-4">Create New Product</h3>
        <LinqProductForm
          linqProduct={linqProduct}
          setLinqProduct={setLinqProduct}
          handleSaveClick={handleSaveClick}
        />
      </>
    </Container>
  )
}

export default ProductCreatePage
