import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import TextInformationLine from 'components/text-information-line'
import useEntitlementGrouping from 'hooks/use-entitlement-grouping'
import { Entitlement } from 'interfaces/entitlement'
import useUpdateEntitlementGrouping from 'hooks/use-update-entitlement-grouping'

const EntitlementGroupingPage = () => {
  const { entitlementGroupingId } = useParams<any>()
  const [entitlements, setEntitlements] = useState<Entitlement[]>([])
  const { data: entitlementGrouping, isFetching } = useEntitlementGrouping(
    entitlementGroupingId
  )
  const updateEntitlementGrouping = useUpdateEntitlementGrouping(
    entitlementGroupingId
  )

  useEffect(() => {
    setEntitlements(entitlementGrouping?.entitlements || [])
  }, [entitlementGrouping])

  const handleSaveClick = () => {
    const entitlementIds: number[] = []
    entitlements.forEach((entitlement) => {
      if (entitlement.enabled) {
        entitlementIds.push(entitlement.id)
      }
    })

    updateEntitlementGrouping.mutate({ entitlement_ids: entitlementIds })
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/entitlement-groupings">
          Entitlement Groupings
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {entitlementGrouping?.title || `Entitlement Grouping`}
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <h3>Loading...</h3>
      ) : (
        <>
          <h3 className="mt-4 mb-4">{entitlementGrouping?.title}</h3>
          <Button onClick={handleSaveClick} variant="success" className="mb-4">
            Save
          </Button>
          <Row>
            <Col>
              <Card className="mt-2">
                <div className="card-body">
                  <h4>Data</h4>
                  <TextInformationLine
                    label="Id"
                    data={entitlementGrouping?.id}
                  />
                  <TextInformationLine
                    label="Updated At"
                    data={moment(entitlementGrouping?.updated_at).format('lll')}
                  />
                  <TextInformationLine
                    label="Created At"
                    data={moment(entitlementGrouping?.created_at).format('lll')}
                  />
                </div>
              </Card>
            </Col>
          </Row>

          <Table striped bordered hover responsive variant="dark">
            <thead>
              <tr>
                <th>Name</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {isFetching ? (
                <tr>
                  <td>Loading...</td>
                </tr>
              ) : (
                entitlements?.map((entitlement, index) => (
                  <tr key={entitlement.id}>
                    <td>{entitlement.title}</td>
                    <td>
                      <input
                        key={`${entitlement.id}-enabled`}
                        className="form-check-input"
                        type="checkbox"
                        checked={entitlement.enabled}
                        onChange={(e) => {
                          const newEntitlements = entitlements.map(
                            (entity, i) => {
                              if (index === i) {
                                return { ...entity, enabled: e.target.checked }
                              }

                              return entity
                            }
                          )
                          setEntitlements(newEntitlements)
                        }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default EntitlementGroupingPage
