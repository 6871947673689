import React, { useState } from 'react'
import { Alert, Button, FormControl, Modal } from 'react-bootstrap'
import { createProductionCodeCSV } from 'utils/product-code-csv-util'
import { LinqProduct } from 'interfaces/linq-product'

const CreateProductProductionCodesModal = ({
  product,
  show,
  handleClose,
}: {
  product: LinqProduct
  show: boolean
  handleClose: () => void
}) => {
  const [rowCount, setRowCount] = useState(0)
  const [eventCode, setEventCode] = useState('')

  const onClose = () => {
    handleClose()
  }

  const handleGenerateCSVClicked = () => {
    createProductionCodeCSV(product, rowCount, eventCode)
  }

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Create {product?.name} Production Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mt-4 mb-1">
          <FormControl
            onChange={(event) => setRowCount(event.target.value as any)}
            value={rowCount}
            type="number"
            placeholder="Number of Codes"
            aria-label="Number of Codes"
          />
          <label htmlFor="floatingInput">Number of Codes</label>
        </div>
        {product?.is_event_badge ? (
          <>
            <div className="form-floating mt-4 mb-1">
              <FormControl
                onChange={(event) => setEventCode(event.target.value)}
                value={eventCode}
                type="text"
                placeholder="Event Code for The Event"
                aria-label="Event Code for The Event"
              />
              <label htmlFor="floatingInput">Event Code for Event</label>
            </div>
            <Alert variant="warning">
              This event code needs to be discussed with the engineers. If you
              have not discussed this yet with the engineering team please
              contact them to figure out the event code.
            </Alert>
          </>
        ) : (
          <></>
        )}

        <Button
          onClick={handleGenerateCSVClicked}
          variant="success"
          className="mt-1 mb-4"
        >
          <ion-icon name="document" /> Generate CSV
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateProductProductionCodesModal
