import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import {
  organizationProductCreditsKeys,
  organizationSubscriptionItemsKeys,
} from 'utils/query-keys'
import { ProductCredit } from 'interfaces/product-credit'

const orgProductCredits = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v3/organizations/${orgId}/product_credits`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.product_credits as ProductCredit[]
}

const useOrganizationProductCredits = (orgId: string) => {
  return useQuery(
    organizationProductCreditsKeys.detail(orgId),
    () => orgProductCredits(orgId),
    {
      enabled: !!orgId,
    }
  )
}

export default useOrganizationProductCredits
