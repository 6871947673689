import useAdminAnalyticsInsights from 'hooks/use-admin-analytics-insights'
import { chunk } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  Row,
  Spinner,
  useAccordionToggle,
} from 'react-bootstrap'
import { Card as LinqCard } from 'interfaces/card'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

const InsightsPage = () => {
  const history = useHistory()
  const [sendQuery, setSendQuery] = useState(false)

  // ----- Search Defaults ------
  const dateLimit = new Date(moment().subtract(7, 'days') as any)
  const [cardCount, setCardCount] = useState(100)
  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(7, 'days').toString())
  )

  const [endDate, setEndDate] = useState(new Date())
  const [eventType, setEventType] = useState('views')
  const [inOrganization, setInOrganization] = useState(false)
  // ----------------------------

  // Search Query
  const analyticInsightsQuery = useAdminAnalyticsInsights(
    {
      card_count: cardCount,
      start_date: startDate,
      end_date: endDate,
      event_type: eventType,
      in_organization: inOrganization,
    },
    sendQuery
  )
  const insightsData = analyticInsightsQuery?.data

  useEffect(() => {
    initiateSearch()
  }, [])

  const handleClickDiscoverButton = () => {
    initiateSearch()
  }

  const initiateSearch = () => {
    setSendQuery(true)
    setTimeout(() => setSendQuery(false), 100)
  }

  const handleViewCardClick = (card: LinqCard) => {
    window.open(card.connecting_url, '_blank')
  }
  const handleViewUserHubblePageClick = (card: LinqCard) => {
    history.push(`/user/${card?.user_uuid}`)
  }
  const handleOnChangeStartDate = (date: Date | null) => {
    if (
      moment(date).isSame(dateLimit, 'day') ||
      moment(date).isAfter(dateLimit, 'day')
    ) {
      setStartDate(date as Date)
    } else {
      toast('Cannot go back more than 7 days', { icon: `❌` })
    }
  }

  // This was pulled from the React Bootstrap docs. If we add type of custom toggle, I think they need their own files, but
  // this one was so small that I thought it was fine.
  // It's used for the "Advance" button that expands/collapses the advanced settings.
  const CustomToggle: React.FC<{ eventKey: string }> = ({
    children,
    eventKey,
  }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {})
    return (
      <Button
        style={{ marginBottom: '10px' }}
        type="button"
        variant="secondary"
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    )
  }

  return (
    <Container>
      <h3 className="mt-2 mb-3">Insights</h3>
      {analyticInsightsQuery.error instanceof Error && (
        <Alert variant="danger">{analyticInsightsQuery.error.message}</Alert>
      )}
      <Row>
        <Col lg={{ offset: 2, span: 8 }}>
          <Card className="mt-2 mb-3 p-2 text-white bg-dark">
            <Card.Body>
              <Card.Title>
                Find the most engaged/popular Linq Pages 😮
              </Card.Title>
            </Card.Body>
            <div className="form-floating mt-2 mb-3">
              <select
                onChange={(e) => setEventType(e.target.value)}
                className="form-select"
                id="seatEventType"
                value={eventType}
                aria-label="Top Pages by..."
              >
                <option value="views">Views</option>
                <option value="shares">Shares</option>
                <option value="product_views">Product Views</option>
                <option value="clicks">Clicks</option>
                <option value="tiles">Tiles</option>
              </select>
              <label htmlFor="seatEventType">Top Pages by...</label>
            </div>
            <Accordion>
              <Accordion.Collapse eventKey="0">
                <div>
                  <div className="form-floating mt-2 mb-3">
                    <FormControl
                      onChange={(event) =>
                        setCardCount(event.target.value as any)
                      }
                      value={cardCount}
                      type="number"
                      placeholder="Number of Pages"
                      aria-label="Number of Pages"
                    />
                    <label htmlFor="floatingInput">Result Count</label>
                  </div>
                  <div className="mt-2 mb-3">
                    <Row>
                      <Col>
                        <Form.Label>Start Date</Form.Label>
                        <div style={{ display: 'block !important' }}>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => handleOnChangeStartDate(date)}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Label>End Date</Form.Label>
                        <div style={{ display: 'block !important' }}>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date as Date)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-floating mt-2 mb-3">
                    <select
                      onChange={(e) =>
                        setInOrganization(e.target.value === 'true')
                      }
                      className="form-select"
                      id="only"
                      value={inOrganization as any}
                      aria-label="Only LFT pages"
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                    <label htmlFor="inOrganizationSelect">
                      Only Linq For Teams Pages
                    </label>
                  </div>
                </div>
              </Accordion.Collapse>
              <Card>
                <CustomToggle eventKey="0">+ Advanced</CustomToggle>
              </Card>
            </Accordion>
            <Button variant="primary" onClick={handleClickDiscoverButton}>
              Discover
            </Button>
          </Card>
        </Col>
      </Row>
      {analyticInsightsQuery.isFetching && (
        <div className="w-100" style={{ display: 'flex' }}>
          <Spinner style={{ margin: 'auto' }} animation="border" />
        </div>
      )}
      {insightsData && insightsData.length > 0 ? (
        chunk(insightsData, 6).map((insightChunk, index) => (
          <Row key={`${index}-${Math.random()}`}>
            {insightChunk.map((insight) => (
              <Col
                xs={6}
                lg={3}
                xl={2}
                key={insight.card.id}
                style={{ paddingTop: '10px' }}
              >
                <Card>
                  <Card.Img
                    variant="top"
                    src={insight.card.large_thumbnail_url}
                  />
                  <Card.Body>
                    <Card.Title>{insight.card.name}</Card.Title>
                    <Card.Text>/{insight.card.connecting_code}</Card.Text>
                    <Card.Text>
                      {insight.card.title && (
                        <span style={{ display: 'block' }}>
                          <small>{insight.card.title}</small>
                        </span>
                      )}
                      {insight.card.company && (
                        <span style={{ display: 'block' }}>
                          <small>{insight.card.company}</small>
                        </span>
                      )}
                    </Card.Text>
                    <Card.Text>
                      <b>{insight.result}</b>
                    </Card.Text>
                    <Button
                      className="mb-2"
                      variant="primary"
                      onClick={() => handleViewCardClick(insight.card)}
                    >
                      View User's Linq Page
                    </Button>
                    <Button
                      variant="success"
                      onClick={() =>
                        handleViewUserHubblePageClick(insight.card)
                      }
                    >
                      View User's Hubble Page
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ))
      ) : insightsData ? (
        <Alert variant="secondary">No results found.</Alert>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default InsightsPage
