import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import useQueryWithKey from './use-query-with-key'
import { Card } from 'interfaces/card'

interface CardsForUserResponseData {
  cards: Card[]
}

const fetchUserCards = async (userUuid: string) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/api/v1/users/${userUuid}/cards`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: CardsForUserResponseData = await response.json()
    return json
  } catch (error) {
    console.error('error', error)
  }
}

const useCardsForUser = (uuid: string) => {
  return useQueryWithKey(
    [`/api/v1/users/:user_uuid/cards`, { uuid }],
    () => fetchUserCards(uuid),
    {
      enabled: !!uuid,
    }
  )
}

export default useCardsForUser
