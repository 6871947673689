import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Breadcrumb,
  Col,
  Row,
  Card,
  Table,
  Button,
  Modal,
  Form,
  FormControl,
  FormGroup,
} from 'react-bootstrap'
import useOrganization from 'hooks/use-organization'
import moment from 'moment'
import useOrganizationIntegrationConnections from 'hooks/use-organization-integration-connections'
import useSaveIntegrationConnection from 'hooks/use-save-integration-connection'
import useIntegrations from 'hooks/use-integrations'
import useTestIntegrationConnection from 'hooks/use-test-integration-connection'
import toast from 'react-hot-toast'
import { Integration, IntegrationConnection } from 'interfaces/integration'

const OrganizationIntegrationConnectionsPage = () => {
  const params = useParams<any>()
  const { id: orgId } = params
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [selectedIntegrationConnection, setSelectedIntegrationConnection] =
    useState<any>({})
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  const { data: org, isLoading: orgIsLoading } = useOrganization(orgId)

  const saveIntegrationConnection = useSaveIntegrationConnection()
  const testIntegrationConnection = useTestIntegrationConnection()
  const [integrations, setIntegrations] = useState<Integration[]>([])

  const {
    data: integrationConnections = [],
    isLoading: integrationConnectionsIsLoading,
  } = useOrganizationIntegrationConnections({ orgId })

  const defaultData = {
    pages: [],
  }

  const {
    data: integrationsData = defaultData,
    isLoading: customIntegrationsIsLoading,
    fetchNextPage,
    hasNextPage,
  } = useIntegrations({ isCustom: true, resultsPerPage: 1000 })

  const { pages: integrationsPages } = integrationsData

  useEffect(() => {
    if (!customIntegrationsIsLoading) {
      const allIntegrations = integrationsPages
        .map((integrationsPage) => integrationsPage.data?.integrations)
        .flat()
        .sort((a, b) => a.name.localeCompare(b.name))
      setIntegrations(allIntegrations)
    }
  }, [customIntegrationsIsLoading, integrationsPages])

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
    // eslint-disable-next-line
  }, [integrationsData, hasNextPage])

  const handleEditClicked = (integrationConnection: IntegrationConnection) => {
    setSelectedIntegrationConnection(integrationConnection)
    setShowDetailModal(true)
  }

  const handleTestClicked = (integrationConnection: IntegrationConnection) => {
    testIntegrationConnection.mutate(
      { integrationConnectionId: integrationConnection.id },
      {
        onSuccess: () => {
          toast(
            'Test succeeded on our end! Check the CRM to verify that the contact made it all the way.',
            { icon: `👍` }
          )
        },
      }
    )
  }

  const handleDetailDataModalClosed = () => {
    setShowDetailModal(false)
    setSelectedIntegrationConnection({})
  }

  const handleAddIntegrationClick = () => {
    setSelectedIntegrationConnection({
      name: 'Untitled Integration',
      integration_id: null,
      webhook_url: '',
      organization_id: orgId,
    })
    setShowDetailModal(true)
  }

  const handleInputChange = (newValue: string, key: string) => {
    setSelectedIntegrationConnection((integrationConnection: Integration) => ({
      ...integrationConnection,
      [key]: newValue,
    }))
  }

  const handleSaveClick = () => {
    saveIntegrationConnection.mutate(
      { integrationConnection: selectedIntegrationConnection },
      {
        onSuccess: () => {
          handleDetailDataModalClosed()
        },
      }
    )
  }

  useEffect(() => {
    if (
      selectedIntegrationConnection?.integration_id !== 'false' &&
      selectedIntegrationConnection?.webhook_url
    ) {
      setSaveButtonDisabled(false)
    } else {
      setSaveButtonDisabled(true)
    }
  }, [selectedIntegrationConnection])

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
        <Breadcrumb.Item href={`/organizations/${orgId}`}>
          {org?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Integrations</Breadcrumb.Item>
      </Breadcrumb>
      <Button className="ms-2" onClick={handleAddIntegrationClick}>
        Add New Integration
      </Button>
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Header>
              <h4>Integrations</h4>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Integration Type</th>
                    <th>Webhook Url</th>
                    <th>Custom</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {(orgIsLoading || integrationConnectionsIsLoading) && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                  {integrationConnections.map(
                    (integrationConnection, index) => (
                      <tr key={index}>
                        <td>
                          <Button
                            className="m-1"
                            onClick={() =>
                              handleEditClicked(integrationConnection)
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            className="m-1"
                            variant="light"
                            onClick={() =>
                              handleTestClicked(integrationConnection)
                            }
                          >
                            Test
                          </Button>
                        </td>
                        <td>{integrationConnection.id}</td>
                        <td>{integrationConnection.name}</td>
                        <td>{integrationConnection.integration?.name}</td>
                        <td>{integrationConnection.webhook_url}</td>
                        <td>
                          {integrationConnection.integration?.is_custom?.toString()}
                        </td>

                        <td>
                          {moment(integrationConnection.created_at).format(
                            'lll'
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        show={showDetailModal}
        onHide={handleDetailDataModalClosed}
      >
        <Modal.Header>
          <Modal.Title>Organization Integration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4 mt-4">
              <Form.Label>Name</Form.Label>
              <FormControl
                autoComplete="off"
                onChange={(event) =>
                  handleInputChange(event.target.value, 'name')
                }
                value={selectedIntegrationConnection?.name}
                type="text"
                placeholder="Name"
                aria-label="Name"
              />
            </Form.Group>
            <FormGroup className="mb-4 mt-4">
              <Form.Label>Webhook Url</Form.Label>
              <FormControl
                autoComplete="off"
                onChange={(event) =>
                  handleInputChange(event.target.value, 'webhook_url')
                }
                value={selectedIntegrationConnection?.webhook_url}
                type="text"
                placeholder="Webhook Url"
                aria-label="Name"
              />
            </FormGroup>
            <Form.Group>
              <Form.Label>Integration Type</Form.Label>
              <select
                className="form-select"
                disabled={customIntegrationsIsLoading}
                defaultValue={
                  selectedIntegrationConnection?.integration_id || false
                }
                onChange={(event) =>
                  handleInputChange(event.target.value, 'integration_id')
                }
              >
                <option value="false">Select one...</option>
                {integrations?.map((integration) => {
                  return (
                    <option key={integration.id} value={integration.id}>
                      {integration.name}
                    </option>
                  )
                })}
              </select>
            </Form.Group>
            <Button
              className="mt-4 mb-4"
              size="lg"
              variant="success"
              onClick={(e) => handleSaveClick()}
              disabled={saveButtonDisabled}
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDetailDataModalClosed}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default OrganizationIntegrationConnectionsPage
