import { Organization } from 'interfaces/organization'
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap'

const nestedKeyLookup = (obj: Record<string, any>, key: string) => {
  if (key === undefined) return
  return key
    .split('.')
    .reduce(
      (cur, keyPart) =>
        cur !== undefined && keyPart in cur ? cur[keyPart] : undefined,
      obj
    )
}

interface EditOrgFieldsModalProps {
  showEditOrgField: boolean
  setShowEditOrgField: (show: boolean) => void
  orgFieldModalParams: React.ComponentProps<typeof FormControl>
  handleSaveFieldClick: (org: Partial<Organization>, field: string) => void
  isUpdatingOrg: boolean
  editableOrg?: Partial<Organization>
  handleEditOrgFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const EditOrgFieldsModal = ({
  showEditOrgField,
  setShowEditOrgField,
  orgFieldModalParams,
  handleSaveFieldClick,
  isUpdatingOrg,
  editableOrg,
  handleEditOrgFieldChange,
}: EditOrgFieldsModalProps) => {
  return (
    <Modal show={showEditOrgField} onHide={() => setShowEditOrgField(false)}>
      <Modal.Header>
        <Modal.Title>{orgFieldModalParams.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={handleEditOrgFieldChange}
            defaultValue={
              editableOrg
                ? (nestedKeyLookup(
                    editableOrg,
                    orgFieldModalParams.fieldKey
                  ) as unknown as string)
                : ''
            }
            type={orgFieldModalParams.type}
            placeholder={orgFieldModalParams.placeholder}
            aria-label={orgFieldModalParams.ariaLabel}
            aria-describedby={orgFieldModalParams.ariaDescribedBy}
          />
          {editableOrg && (
            <Button
              id={orgFieldModalParams.buttonId}
              onClick={(e) =>
                handleSaveFieldClick(editableOrg, orgFieldModalParams.title)
              }
              disabled={isUpdatingOrg}
            >
              {isUpdatingOrg ? 'Loading...' : 'Save'}
            </Button>
          )}
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowEditOrgField(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
