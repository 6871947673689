import { Card, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export const OrganizationAddOnsCard = ({ orgId }: { orgId?: string }) => {
  const history = useHistory()

  const handleIntegrationsClick = () => {
    history.push(`/organizations/${orgId}/integrations`)
  }

  const handleSSOClick = () => {
    history.push(`/organizations/${orgId}/sso`)
  }
  return (
    <Card className="mt-4">
      <Card.Header>
        <p
          style={{
            fontSize: '24px',
          }}
        >
          Add-ons
        </p>
      </Card.Header>
      <Card.Body
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <Button onClick={handleIntegrationsClick}>Integrations</Button>
        <Button onClick={handleSSOClick}>SSO & HR</Button>
      </Card.Body>
    </Card>
  )
}
