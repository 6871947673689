import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { DirectoryMapping } from 'interfaces/directory-mapping'
import { ID } from 'utils/types'

interface ResponseData {
  data: {
    directory_mappings: DirectoryMapping[]
  }
}

const fetchDirectoryMappings = async (orgId: ID) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v3/organizations/${orgId}/directory_mappings`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json: ResponseData = await response.json()
  return json.data.directory_mappings
}

const useDirectoryMappings = (orgId: ID) => {
  return useQuery(organizationsKeys.directoryMappings.index(orgId), () =>
    fetchDirectoryMappings(orgId)
  )
}

export default useDirectoryMappings
