import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { UserOrganization } from 'interfaces/user-organization'

type Data = {
  data: {
    user_organizations: UserOrganization[]
  }
}

const fetchUserOrganizations = async (organizationId: string) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/api/v2/organizations/${organizationId}/user_organizations`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: Data = await response.json()
    return json
  } catch (error) {
    console.error('error', error)
  }
}

export const useGetOrganizationUsers = (organizationId: string) => {
  const queryKey = `api/v1/organizations/${organizationId}/user_organizations`
  return {
    ...useQuery(
      [`api/v1/organizations/${organizationId}/user_organizations`],
      () => fetchUserOrganizations(organizationId),
      {
        enabled: !!organizationId,
      }
    ),
    queryKey,
  }
}
