import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

const fetchCreateUserOrganization = async (
  user_id: string,
  organization_id: string,
  is_admin: boolean
) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v2/user_organizations`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({ user_id, organization_id, is_admin }),
    }
  )
  handleErrors(response)
}

const useMutationAddUserToOrganization = ({ orgId }: { orgId: string }) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      userId,
      organizationId,
      isAdmin,
    }: {
      userId: string
      organizationId: string
      isAdmin: boolean
    }) => {
      return fetchCreateUserOrganization(userId, organizationId, isAdmin)
    },
    {
      // When mutate is called:
      onMutate: () => {},
      onSuccess: (data, variables, context) => {
        toast('User added to organization', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(
          `api/v1/organizations/${orgId}/user_organizations`
        )
      },
    }
  )
}

export default useMutationAddUserToOrganization
