import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { defaultFilters } from 'utils/filters'
import { handleErrors } from 'utils/handle-errors'
import { userContactsKeys } from 'utils/query-keys'
import useConstructUrl from './use-construct-url'
import { UserContactSearch } from 'interfaces/user-contact-search'
import { UserContactSearchFilters } from 'interfaces/user-contact-search-filters'
import { store } from 'index'

type UserContactsResponseData = {
  data: {
    user_contacts: UserContactSearch[]
  }
}

interface ApiParams {
  results_per_page: number
  page: number
  user_uuid: string
  query: string
  filters?: UserContactSearchFilters
}

const fetchUserContacts = async (url: string, apiParams: ApiParams) => {
  let response = await fetch(url, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify(apiParams),
  })
  response = handleErrors(response)
  const json: UserContactsResponseData = await response.json()
  return json.data.user_contacts
}

const useGetUserContacts = ({
  uuid,
  query,
  filters = defaultFilters(),
}: {
  uuid: string
  query: string
  filters?: UserContactSearchFilters
}) => {
  const { constructUrl } = useConstructUrl()
  const apiParams = {
    results_per_page: 10,
    page: 1,
    user_uuid: uuid,
    query,
    filters,
  }
  const url = `${BASE_API_URL}/api/v3/user_contacts/search`
  return useQuery(
    userContactsKeys.index(uuid).concat(query),
    () => fetchUserContacts(url, apiParams),
    {
      enabled: !!uuid,
    }
  )
}

export default useGetUserContacts
