export const enum OrganizationSubscriptionStatus {
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
  Trialing = 'trialing',
  TrialExpired = 'trial_expired',
}

export interface OrganizationSubscription {
  id: number
  organization_id: number
  subscription_id: string
  subscription_kind: string
  subscription_status: OrganizationSubscriptionStatus
  product_identifier?: string
  seat_count: number
  created_at: Date
  updated_at: Date
  trial_end?: string
  sale_rep_id?: number | string
  stripe_customer_id?: string
  subscription_status_override?: string
}
