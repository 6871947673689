import React, { useState } from 'react'
import {
  Button,
  FormControl,
  Modal,
  Table,
  InputGroup,
  Dropdown,
} from 'react-bootstrap'
import useAdminUsersSearch from 'hooks/use-admin-users-search'
import useDebounce from 'hooks/use-debounce'
import useMutationTransferCard from 'hooks/use-mutation-transfer-card'
import { Card } from 'interfaces/card'
import { User } from 'interfaces/user'
import { QueryKey } from 'react-query'

const TransferCardToUserModal = ({
  show,
  card,
  oldUser,
  handleClose,
  cardsForUserQueryKey,
}: {
  show: boolean
  card: Card
  oldUser: User | null
  handleClose: () => void
  cardsForUserQueryKey: QueryKey
}) => {
  const [query, setQuery] = useState('')

  const onClose = () => {
    handleClose()
  }

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuery(event.target.value)
  }

  const handleTransferUserClicked = ({
    newUser,
    makePrimary,
  }: {
    newUser: User
    makePrimary: boolean
  }) => {
    if (
      window.confirm(
        `Are you sure you want to transfer '${card?.name}' to '${newUser?.name}'?\n\nYou will have to remember the owner of '${card?.name}' (${oldUser?.name}) in order transfer it back.\n\nAlso, if the current owner ('${oldUser?.name}') will have 0 pages after the transfer, the app won't work for them. Create a new page for '${oldUser?.name}' first or delete their account after.`
      ) === true
    ) {
      transferCardMutation.mutate(
        {
          recipientUserUuid: newUser?.uuid,
          cardCode: card.viewing_code,
          makePrimary,
        },
        {
          onSuccess: () => {
            handleClose()
          },
        }
      )
    }
  }

  const transferCardMutation = useMutationTransferCard({ cardsForUserQueryKey })

  const debouncedQuery = useDebounce(query, 500)
  const { data, isFetching } = useAdminUsersSearch(debouncedQuery)
  const users = data?.users

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          Transfer page '{card?.name}' from user '{oldUser?.name}' to new user.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={handleSearchInputChange}
            value={query}
            type="phone"
            placeholder="Search"
            aria-label="Search Users"
          />
        </InputGroup>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Transfer to</th>
              <th>View User</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <div className="m-2">Searching..</div>
            ) : (
              <>
                {users?.map((user) => (
                  <tr>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success">
                          Transfer...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleTransferUserClicked({
                                newUser: user,
                                makePrimary: true,
                              })
                            }
                          >
                            ...and make default page
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleTransferUserClicked({
                                newUser: user,
                                makePrimary: false,
                              })
                            }
                          >
                            ...and NOT make default page
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          window.open(`/user/${user.uuid}`, '_blank')
                        }
                      >
                        View
                      </Button>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.phone_number}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TransferCardToUserModal
