import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import { useMutation, useQueryClient } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import {
  organizationProductCreditsKeys,
  organizationSubscriptionItemsKeys,
} from 'utils/query-keys'

const useOrganizationCreateProductCredit = (orgId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      shopifyProductId,
      quantity,
    }: {
      shopifyProductId: string
      quantity: number
    }) =>
      fetch(`${BASE_API_URL}/api/v3/organizations/${orgId}/product_credits`, {
        method: API_METHODS.POST,
        headers: apiHeaders(store.getState()),
        body: JSON.stringify({
          shopify_id: shopifyProductId,
          quantity: quantity,
        }),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          organizationProductCreditsKeys.detail(orgId)
        )
        queryClient.invalidateQueries(
          organizationSubscriptionItemsKeys.detail(orgId)
        )
      },
    }
  )
}

export default useOrganizationCreateProductCredit
