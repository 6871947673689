import { useQuery } from 'react-query'
import { store } from '..'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'
import { contactsKeys } from 'utils/query-keys'

const fetchContactDetails = async (contactId: string) => {
  let response = await fetch(`${BASE_API_URL}/api/v2/contacts/${contactId}`, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json.data.contact
}

const useGetContactDetails = (contactId: string) => {
  return useQuery(
    contactsKeys.detail(contactId),
    () => fetchContactDetails(contactId),
    {
      enabled: !!contactId,
    }
  )
}

export default useGetContactDetails
