export const FETCHING = (actionType: string) => `${actionType}_FETCHING`
export const SUCCESS = (actionType: string) => `${actionType}_SUCCESS`
export const FAILURE = (actionType: string) => `${actionType}_FAILURE`

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const LOGOUT_USER = `LOGOUT_USER`
export const REQUEST_AUTH_CONFIRMATION = 'REQUEST_AUTH_CONFIRMATION'
export const VALIDATE_AUTH_CODE = 'VALIDATE_AUTH_CODE'

export const SET_USERS_SEARCH_QUERY_STRING = 'SET_USERS_SEARCH_QUERY_STRING'
