import { useMutation, UseMutationOptions } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'

const fetchDeleteUser = async (userUuid: string) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v1/users/${userUuid}/delete`,
    {
      method: API_METHODS.DELETE,
      headers: apiHeaders(store.getState()),
    }
  )
  handleErrors(response)
}

type MutationOptions = UseMutationOptions<void, unknown, string>

const useMutationAdminDeleteUser = (
  sideEffectsObject: Partial<MutationOptions>
) => {
  return useMutation(
    (userUuid: string) => {
      return fetchDeleteUser(userUuid)
    },
    {
      ...sideEffectsObject,
      onError: (e) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useMutationAdminDeleteUser
