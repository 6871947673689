import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { Organization } from 'interfaces/organization'

const updateOrganization = async (organization: Partial<Organization>) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/organizations/${organization.id}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(organization),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json: { data: { organization: Organization } } = await response.json()
  return json.data.organization
}

const useUpdateOrganization = (organizationId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ organization }: { organization: Partial<Organization> }) =>
      updateOrganization(organization),
    {
      onError: (e: Error) => {
        alert(`Uh oh! There was an error. ${e.message}`)
      },
      onSettled: () => {
        queryClient.invalidateQueries(organizationsKeys.detail(organizationId))
      },
    }
  )
}

export default useUpdateOrganization
