import React, { useState } from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  InputGroup,
  Button,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestAuthConfirmation } from 'store/actions/user-actions'
import { RootState } from 'index'

const LoginPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isFetching = useSelector((state: RootState) => state.user.isFetching)
  const [authInputValue, setAuthInputValue] = useState('')
  const handleLoginClick = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(requestAuthConfirmation(authInputValue, history))
  }

  return (
    <Container>
      <Row>
        <Col lg={4} />
        <Col lg={4}>
          <Card className="mt-4 p-4 text-white bg-dark">
            <div className="card-body">
              <h4 className="card-title">Login</h4>
              <div className="card-subtitle m-b-2 text-muted">
                Login with your admin account.
              </div>
              <form onSubmit={handleLoginClick}>
                <InputGroup className="mb-4 mt-4">
                  <FormControl
                    onChange={(event) => setAuthInputValue(event.target.value)}
                    value={authInputValue}
                    type="number"
                    inputMode="numeric"
                    placeholder="Mobile Number"
                    aria-label="Mobile Number"
                  />
                </InputGroup>
                <Button disabled={isFetching} variant="success" type="submit">
                  Login
                </Button>
              </form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginPage
