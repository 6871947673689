import UserCardCard from 'components/user-card-card'
import useDeleteCard from 'hooks/use-delete-card'
import useUnclaimedCards from 'hooks/use-unclaimed-cards'
import { Card } from 'react-bootstrap'

export const OrganizationUnClaimedCard = ({ orgId }: { orgId: string }) => {
  const { data: unclaimedCards = [], isLoading: isUnclaimedCardsLoading } =
    useUnclaimedCards(orgId)

  const deleteCardMutation = useDeleteCard()

  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Unclaimed Cards</h4>
      </Card.Header>
      <Card.Body>
        {isUnclaimedCardsLoading ? (
          <div className="m-2">Loading...</div>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {unclaimedCards.map((card) => (
              <UserCardCard
                key={card.id}
                card={card}
                deleteCardForUserMutation={deleteCardMutation}
                user={null}
                cardsForUserQueryKey={null || ''}
              />
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
