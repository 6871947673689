import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

const fetchGeneratePrintFile = async (
  zakekeLineItemIds: string[],
  templateId: number
) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/artis_jet/generate_print_file`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({
        zakeke_line_item_ids: zakekeLineItemIds,
        product_jig_template_id: templateId,
      }),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useMutationGeneratePrintFile = () => {
  return useMutation(
    ({
      lineItemIds,
      productJigTemplateId,
    }: {
      lineItemIds: string[]
      productJigTemplateId: number
    }) => {
      return fetchGeneratePrintFile(lineItemIds, productJigTemplateId)
    },
    {
      onMutate: () => {
        toast(
          'Print file is being generated - Check out the print files page to download it',
          { icon: `🕓` }
        )
      },
      onSuccess: (data, variables, context) => {},
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useMutationGeneratePrintFile
