import React, { useState } from 'react'
import { Button, FormControl, Modal, Table, InputGroup } from 'react-bootstrap'
import useAdminUsersSearch from 'hooks/use-admin-users-search'
import useDebounce from 'hooks/use-debounce'
import { User } from 'interfaces/user'

const UserSearchAndSelectModal = ({
  show,
  handleClose,
  modalTitle,
  userButtonText,
  handleUserButtonClick,
}: {
  show: boolean
  handleClose: () => void
  modalTitle: string
  userButtonText: string
  handleUserButtonClick: (user: User) => void
}) => {
  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 500)
  const { data, isFetching } = useAdminUsersSearch(debouncedQuery)
  const users = data?.users

  const onClose = () => {
    handleClose()
  }

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuery(event.target.value)
  }

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={handleSearchInputChange}
            value={query}
            type="phone"
            placeholder="Search"
            aria-label="Search Users"
          />
        </InputGroup>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>{userButtonText}</th>
              <th>View User</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <tr className="m-2">
                <th>Searching...</th>
              </tr>
            ) : (
              <>
                {users?.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone_number}</td>
                    <td>
                      <Button
                        variant="success"
                        onClick={() => handleUserButtonClick(user)}
                      >
                        {userButtonText}
                      </Button>
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          window.open(`/user/${user.uuid}`, '_blank')
                        }
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserSearchAndSelectModal
