export const LINQ_ONE_DISCOUNTS = [
  { id: 'LpEsWxbO', percent_off: 99 },
  { id: 'SrryABBo', percent_off: 98 },
  { id: 'YuSQIdwy', percent_off: 97 },
  { id: 'G64lmutu', percent_off: 96 },
  { id: 'Wrf2v35Y', percent_off: 95 },
  { id: 'WZDSSbu0', percent_off: 94 },
  { id: 'HkKQ0ERX', percent_off: 93 },
  { id: 'oUXMR0nP', percent_off: 92 },
  { id: 'uULXxHkL', percent_off: 91 },
  { id: '4S1jdURP', percent_off: 90 },
  { id: 'nb9lqjxy', percent_off: 89 },
  { id: 'UBItxdim', percent_off: 88 },
  { id: 'F88ZHSpd', percent_off: 87 },
  { id: 'wPusq0Jz', percent_off: 86 },
  { id: '9EI7JcLd', percent_off: 85 },
  { id: 'QGjiaKaW', percent_off: 84 },
  { id: 'axdgj1Yj', percent_off: 83 },
  { id: '90Ymd5F8', percent_off: 82 },
  { id: 'krG0BANe', percent_off: 81 },
  { id: 'CIjO9kFi', percent_off: 80 },
  { id: 'crApI7OL', percent_off: 79 },
  { id: 'pb1XGwn1', percent_off: 78 },
  { id: '4zhtojEs', percent_off: 77 },
  { id: '1AmimTRJ', percent_off: 76 },
  { id: 'uaT8aE1g', percent_off: 75 },
  { id: 'kpNByP7P', percent_off: 74 },
  { id: 'OeZ4GCqr', percent_off: 73 },
  { id: 'sISvcBmX', percent_off: 72 },
  { id: '8J09Juff', percent_off: 71 },
  { id: 'ff9iCVgt', percent_off: 70 },
  { id: 'qVlUGvKr', percent_off: 69 },
  { id: '4kphd0U2', percent_off: 68 },
  { id: 'Rgk6WKbi', percent_off: 67 },
  { id: 'wR791DVW', percent_off: 66 },
  { id: '1Pu8RdlR', percent_off: 65 },
  { id: '0nd90Jig', percent_off: 64 },
  { id: 'pY1FzJsI', percent_off: 63 },
  { id: 'USNvhP16', percent_off: 62 },
  { id: 'iwVcUjtd', percent_off: 61 },
  { id: '2b7uITiJ', percent_off: 60 },
  { id: 'GT78jHpr', percent_off: 59 },
  { id: 'BTDyaAKl', percent_off: 58 },
  { id: 'coBHbEqr', percent_off: 57 },
  { id: '09jfkO4N', percent_off: 56 },
  { id: 'GJp9ycf9', percent_off: 55 },
  { id: '2mZvcLhz', percent_off: 54 },
  { id: 'FPAAoUOC', percent_off: 53 },
  { id: 'NVi35X3v', percent_off: 52 },
  { id: 'EpeOSVVq', percent_off: 51 },
  { id: 'D4mGg7dg', percent_off: 50 },
  { id: 'Pq3IzJBp', percent_off: 49 },
  { id: 'gbWss7tQ', percent_off: 48 },
  { id: 'R2YENZLH', percent_off: 47 },
  { id: 'nx8n6rcH', percent_off: 46 },
  { id: 'XRuBmLqR', percent_off: 45 },
  { id: '2cTqhuV2', percent_off: 44 },
  { id: 'yM8E2rbN', percent_off: 43 },
  { id: 'INL7Gf1X', percent_off: 42 },
  { id: 'Y87ejnxQ', percent_off: 41 },
  { id: 'Tr2xPlei', percent_off: 40 },
  { id: 'VM7Rlc0K', percent_off: 39 },
  { id: 'Z6nFZOla', percent_off: 38 },
  { id: 'IfAHCPMP', percent_off: 37 },
  { id: 'PFGQWSpA', percent_off: 36 },
  { id: 'jAgaxTAd', percent_off: 35 },
  { id: 'NGv0VVgJ', percent_off: 34 },
  { id: 'vDxdMM0u', percent_off: 33 },
  { id: '0ET6JmHK', percent_off: 32 },
  { id: 'ai6C5ROS', percent_off: 31 },
  { id: 'Cb8z6JQ5', percent_off: 30 },
  { id: 'Qq52Munq', percent_off: 29 },
  { id: '7kI4KuVx', percent_off: 28 },
  { id: 'qwM49DkA', percent_off: 27 },
  { id: 'lBzuEGyU', percent_off: 26 },
  { id: '7tkfx3bt', percent_off: 25 },
  { id: 'nliIdkYm', percent_off: 24 },
  { id: 'zpickLa3', percent_off: 23 },
  { id: '2RH7S1Kz', percent_off: 22 },
  { id: 'i9dlpW8Z', percent_off: 21 },
  { id: 'tNejXZ4d', percent_off: 20 },
  { id: 'FGaqTtzf', percent_off: 19 },
  { id: 'GRList2s', percent_off: 18 },
  { id: 'yNk7o6V5', percent_off: 17 },
  { id: 'kFSEuB5N', percent_off: 16 },
  { id: 'YOiGnkiE', percent_off: 15 },
  { id: 'PXN1JdZc', percent_off: 14 },
  { id: '6Oo1v9Oc', percent_off: 13 },
  { id: 'ejnKk2mv', percent_off: 12 },
  { id: 'fYdxfJyE', percent_off: 11 },
  { id: '7vEuTcTC', percent_off: 10 },
  { id: 'yvYaGKsI', percent_off: 9 },
  { id: 'Cs78dpRh', percent_off: 8 },
  { id: 'AOmwtnSJ', percent_off: 7 },
  { id: 'U8ed3OEG', percent_off: 6 },
  { id: 'tEAj6IU4', percent_off: 5 },
  { id: 'dz2v1i1a', percent_off: 4 },
  { id: '6fYDrO78', percent_off: 3 },
  { id: '0CIONPw2', percent_off: 2 },
  { id: 'kqCbCyB5', percent_off: 1 },
]
