import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { LineItem } from 'interfaces/line-item'
import { PaginatedData } from 'interfaces/paginated-data'

const fetchZakekeLineItems = async (
  page: string,
  productName: string,
  variant: string
) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v1/zakeke_line_items?hello=1&page=${page}&product_name=${productName}&variant=${variant}`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: { zakeke_line_items: LineItem[] } & PaginatedData =
      await response.json()
    return json
  } catch (error) {
    console.error('error', error)
  }
}

export const useGetAllZakekeLineItems = (
  page: string,
  productName: string,
  variant: string
) => {
  return useQuery(
    [`admin/api/v1/zakeke_line_items`],
    () => fetchZakekeLineItems(page, productName, variant),
    {
      enabled: !!productName && !!variant,
    }
  )
}
