import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { usersQueryKeys } from 'utils/query-keys'

const useUserUserTeams = (userUuid: string) => {
  return useQuery(
    usersQueryKeys.userTeams.index(userUuid),
    () => fetchUserUserTeams(userUuid),
    { enabled: !!userUuid }
  )
}

const fetchUserUserTeams = async (userUuid: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${userUuid}/user_teams`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.user_teams
}

export default useUserUserTeams
