import useMutationCreateWorkosOrganization from 'hooks/use-mutation-create-workos-organization'
import { Organization } from 'interfaces/organization'
import { useState } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap'

const SetupSSOForm = ({ organization }: { organization: Organization }) => {
  const [domain, setDomain] = useState('')

  const { mutate: createWorkosOrganization } =
    useMutationCreateWorkosOrganization()

  const addSSO = () => {
    createWorkosOrganization({
      organization_id: organization.id,
      domains: [domain],
    })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        addSSO()
      }}
    >
      <FormGroup className="mb-4 mt-4">
        <FormLabel>Domain</FormLabel>
        <FormControl
          autoComplete="off"
          onChange={(e) => setDomain(e.target.value)}
          value={domain}
          type="text"
          placeholder="Domain"
          aria-label="Domain"
        />
      </FormGroup>
      <Button type="submit">Add To Workos</Button>
    </Form>
  )
}

export default SetupSSOForm
