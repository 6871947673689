import useCreateOrganizationHandoff from 'hooks/use-create-organization-handoff'
import useOrganizationHandoff from 'hooks/use-organization-handoff'
import moment from 'moment'
import { Card, Button } from 'react-bootstrap'
import toast from 'react-hot-toast'

export const OrganizationHandoffCard = ({ orgId }: { orgId: string }) => {
  const { data: organizationHandoff, isLoading: isOrganizationHandoffLoading } =
    useOrganizationHandoff(orgId)

  const createOrganizationHandoff = useCreateOrganizationHandoff({ orgId })

  const handleHandoffClicked = () => {
    createOrganizationHandoff.mutate(null, {
      onSuccess: () => {
        toast(`Successfully update user's max pages`, { icon: `👍` })
      },
    })
  }
  return (
    <Card className="mt-4">
      <Card.Header className="bg-primary">LFT Sales Handoff</Card.Header>
      <Card.Body>
        {organizationHandoff ? (
          <>
            <p>
              <strong>Status:</strong> {organizationHandoff.status}
            </p>
            <p>
              <strong>Handoff Initiated At:</strong>{' '}
              {moment(organizationHandoff.created_at).format(
                'dddd, MMMM Do YYYY, h:mm:ss a'
              )}
            </p>
            <p>
              <strong>Handoff Updated At:</strong>{' '}
              {organizationHandoff.updated_at
                ? moment(organizationHandoff.updated_at).format(
                    'dddd, MMMM Do YYYY, h:mm:ss a'
                  )
                : 'N/A'}
            </p>
            <p>
              <strong>Handoff Email Sent At:</strong>{' '}
              {organizationHandoff.sent_at
                ? moment(organizationHandoff.sent_at).format(
                    'dddd, MMMM Do YYYY, h:mm:ss a'
                  )
                : 'N/A'}
            </p>
            <p>
              <strong>Customer io email delivery code:</strong>{' '}
              {organizationHandoff.cio_delivery_id || 'N/A'}
            </p>
            <p>
              <strong>Shopify Coupon code:</strong>{' '}
              {organizationHandoff.shopify_coupon_code || 'N/A'}
            </p>
            {organizationHandoff.status === 'failed' && (
              <>
                <p>
                  <strong>Failure Reason:</strong>{' '}
                  {organizationHandoff.failure_reason || 'N/A'}
                </p>
                <Button
                  variant="info"
                  disabled={createOrganizationHandoff.isLoading}
                  className="mb-3"
                  onClick={() => handleHandoffClicked()}
                >
                  Re-try Handoff
                </Button>
              </>
            )}
          </>
        ) : (
          <Button
            variant="success"
            disabled={
              createOrganizationHandoff.isLoading ||
              isOrganizationHandoffLoading === true
            }
            className="mb-3"
            onClick={() => handleHandoffClicked()}
          >
            Start Handoff
          </Button>
        )}
      </Card.Body>
    </Card>
  )
}
