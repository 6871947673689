import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { teamsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'
import { Team } from 'interfaces/team'

const fetchCreateUserTeam = async (newUserTeam: Team) => {
  let response = await fetch(`${BASE_API_URL}/api/v2/user_teams`, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify(newUserTeam),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json.data.user_team
}

const useCreateUserTeam = () => {
  const queryClient = useQueryClient()
  return useMutation((newUserTeam: Team) => fetchCreateUserTeam(newUserTeam), {
    onError: (e) => {
      console.error(e)
      alert(
        'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
      )
    },
    onSuccess: (data) => {
      toast('User added to team', { icon: `👍` })
    },
    onSettled: (userTeam) => {
      queryClient.invalidateQueries(
        teamsKeys.userTeams.index(userTeam?.team_id)
      )
    },
  })
}

export default useCreateUserTeam
