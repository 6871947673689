import { BASE_API_URL } from 'constants/env'
import {
  FAILURE,
  FETCHING,
  GET_CURRENT_USER,
  REQUEST_AUTH_CONFIRMATION,
  SUCCESS,
  VALIDATE_AUTH_CODE,
} from 'constants/action-types'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { Dispatch } from 'redux'
import { AppDispatch, AppDispatchThunk, RootState } from 'index'
import { History } from 'history'

export const getCurrentUser = (userUUID: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    return fetch(
      `${BASE_API_URL}/api/v1/users/${
        userUUID || getState()?.user?.currentUser?.uuid
      }`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(getState()),
      }
    )
      .then(
        (response) => response.json(),
        (error) => console.error('An error occurred.', error)
      )
      .then((json) => {
        if (json.user.is_admin === true) {
          dispatch({
            type: SUCCESS(GET_CURRENT_USER),
            json,
          })
        } else {
          throw Error('Not a valid admin account.')
        }
      })
      .catch((error) => console.error('An error occurred.', error))
  }
}

export const requestAuthConfirmation = (
  authInput: string,
  history: History
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: FETCHING(REQUEST_AUTH_CONFIRMATION) })
    return fetch(`${BASE_API_URL}/api/v1/auth/create_confirmation`, {
      method: API_METHODS.POST,
      headers: apiHeaders(getState()),
      body: JSON.stringify({
        input: authInput,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: SUCCESS(REQUEST_AUTH_CONFIRMATION),
          json,
        })
        history.push(`/confirmation/${json.auth_uuid}`)
      })
      .catch((e) => {
        console.error(e)
        const errorMessage = `Could not send sign in code with that information.`
        alert(errorMessage)
        dispatch({ type: FAILURE(REQUEST_AUTH_CONFIRMATION) })
      })
  }
}

export const validateAuthCode = (
  authConfirmationUUID: string,
  authCode: string,
  history: History
) => {
  return (dispatch: AppDispatchThunk, getState: () => RootState) => {
    dispatch({ type: FETCHING(VALIDATE_AUTH_CODE) })
    return fetch(
      `${BASE_API_URL}/api/v1/auth/validate_code?auth_uuid=${authConfirmationUUID}&auth_code=${authCode}`,
      {
        method: API_METHODS.POST,
        headers: apiHeaders(getState()),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (!json.api_token) {
          throw Error('Api token returned is invalid.')
        }

        history.push(`/home`)
        dispatch({
          type: SUCCESS(VALIDATE_AUTH_CODE),
          json,
        })
        dispatch(getCurrentUser(json.uuid))
      })
      .catch((e) => {
        console.error(e)
        const errorMessage = `Could not validate that code.`
        alert(errorMessage)
        dispatch({ type: FAILURE(VALIDATE_AUTH_CODE) })
      })
  }
}
