import { Button } from 'react-bootstrap'
import React, { useState } from 'react'

const CopyToClipboardButton = ({
  copyText,
  copiedText,
  stringToCopy,
  buttonVariant,
}: {
  copyText: string
  copiedText: string
  stringToCopy: string
  buttonVariant?: string
}) => {
  const [copiedSuccess, setCopiedSuccess] = useState(false)

  const handleCopyToClipboardClicked = () => {
    navigator.clipboard.writeText(stringToCopy)
    setCopiedSuccess(true)
    setTimeout(() => {
      setCopiedSuccess(false)
    }, 2000)
  }

  return (
    <Button
      variant={buttonVariant || 'success'}
      onClick={handleCopyToClipboardClicked}
    >
      {copiedSuccess ? (
        <>
          <ion-icon name="checkmark-outline" /> {copiedText}
        </>
      ) : (
        <>
          <ion-icon name="copy-outline" /> {copyText}
        </>
      )}
    </Button>
  )
}

export default CopyToClipboardButton
