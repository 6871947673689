import useEntitlementGrouping from 'hooks/use-entitlement-grouping'
import { useEntitlementGroupings } from 'hooks/use-entitlement-groupings'
import { Organization } from 'interfaces/organization'
import { Card, Row, Col, Dropdown, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export const OrganizationEntitlementsCard = ({
  org,
  handleSaveFieldClick,
}: {
  org?: Organization
  handleSaveFieldClick: (org: Organization, field: string) => void
}) => {
  const history = useHistory()
  const workosOrganizationEnabledKeys = [
    'linq_for_teams_and_hr',
    'linq_for_teams_and_sso',
    'linq_enterprise',
  ]
  const { data: entitlementGrouping } = useEntitlementGrouping(
    org?.entitlement_grouping_id
  )
  const { data: entitlementGroupings } = useEntitlementGroupings()
  const showWorkosOrganizationLink = workosOrganizationEnabledKeys.includes(
    entitlementGrouping?.key || ''
  )
  if (!org) return <></>
  const handleSSOClick = () => {
    history.push(`/organizations/${org.id}/sso`)
  }
  return (
    <Card className="mt-4">
      <Card.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Entitlement Grouping</h4>
          {showWorkosOrganizationLink && (
            <Button
              className="py-0 px-2"
              variant="link"
              onClick={handleSSOClick}
            >
              WorkOS Organization
            </Button>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="mb-2">
          <Col lg="6" className="mt-2">
            <Card>
              <Dropdown className="my-2">
                <Dropdown.Toggle variant="info" className="p-1">
                  {entitlementGrouping?.title || 'Select Entitlement Grouping'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {entitlementGroupings?.map((entitlementGroupingEntity) => (
                    <Dropdown.Item
                      key={entitlementGroupingEntity.id}
                      onClick={() => {
                        const newOrg = {
                          ...org,
                          entitlement_grouping_id: entitlementGroupingEntity.id,
                        }
                        handleSaveFieldClick(newOrg, 'Entitlement Grouping')
                      }}
                    >
                      {entitlementGroupingEntity.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
