import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationHandoffKeys } from 'utils/query-keys'
import { toast } from 'react-hot-toast'

const createOrganizationHandoff = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/organization_handoff`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data
}

const useCreateOrganizationHandoff = ({ orgId }: { orgId: string }) => {
  const queryClient = useQueryClient()
  return useMutation((x: null) => createOrganizationHandoff(orgId), {
    onError: (e: Error) => {
      toast('Error starting organization handoff')
    },
    onSettled: () => {
      queryClient.invalidateQueries(organizationHandoffKeys.index(orgId))
    },
  })
}

export default useCreateOrganizationHandoff
