import {
  LOGOUT_USER,
  SET_USERS_SEARCH_QUERY_STRING,
} from 'constants/action-types'
import { Reducer } from 'redux'

interface UserSearchState {
  queryString: string
}

const initialState = {
  queryString: '',
}

const usersSearch: Reducer<UserSearchState, any> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case LOGOUT_USER:
      return initialState
    case SET_USERS_SEARCH_QUERY_STRING:
      return {
        ...state,
        queryString: action.queryString,
      }
    default:
      return state
  }
}

export default usersSearch
