import TextInformationLine from 'components/text-information-line'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { Organization } from 'interfaces/organization'
import { useEffect, useState } from 'react'
import { useGetInternalAdmins } from 'hooks/use-get_internal-admins'
import { UpdateAccountManagerOrSaleRepToOrgModal } from 'components/add-account-manager-or-sale-rep-to-org-modal'
import moment from 'moment'
import useUpdateOrganizationOrganizationSubscriptionId from 'hooks/use-update-organization-subscription'

interface OrganizationDetailsCardProps {
  org: Organization | undefined
  setShowUpdateSaleRepModal: (show: boolean) => void
  handleEditTierClicked: () => void
  resetTier: () => void
  setShowUpdateAccountManagerModal: (show: boolean) => void
  setShowUpdateSubscriptionStatusOverrideModal: (show: boolean) => void
  handleEditHubspotUrlClicked: () => void
  showUpdateSaleRepModal: boolean
  showUpdateAccountManagerModal: boolean
  orgId: string
  isInHouseTrial: boolean
}

const OrganizationDetailsCard = ({
  org,
  setShowUpdateSaleRepModal,
  handleEditTierClicked,
  resetTier,
  setShowUpdateAccountManagerModal,
  setShowUpdateSubscriptionStatusOverrideModal,
  handleEditHubspotUrlClicked,
  showUpdateSaleRepModal,
  showUpdateAccountManagerModal,
  orgId,
  isInHouseTrial,
}: OrganizationDetailsCardProps) => {
  const [accountManager, setAccountManager] = useState('')
  const [saleRep, setSaleRep] = useState('')

  const { data: internalAdmins, isLoading: isInternalAdminsLoading } =
    useGetInternalAdmins()
  const { mutate: updateOrgSubscriptionId } =
    useUpdateOrganizationOrganizationSubscriptionId(orgId)

  useEffect(() => {
    if (org?.account_manager_user_id) {
      const accountManagerName = internalAdmins?.find(
        (admin) => admin.id === org.account_manager_user_id
      )?.name
      setAccountManager(accountManagerName || '')
    }
  }, [org, internalAdmins])

  useEffect(() => {
    if (org?.organization_subscription?.sale_rep_id) {
      const saleRep = internalAdmins?.find(
        (admin) => admin.id === org?.organization_subscription?.sale_rep_id
      )?.name
      setSaleRep(saleRep || '')
    }
  }, [org, internalAdmins])

  const handleResetTrialDate = () => {
    updateOrgSubscriptionId(
      {
        reset_trial: true,
      },
      {
        onSuccess: () => {
          setShowUpdateSubscriptionStatusOverrideModal(false)
        },
      }
    )
  }
  return (
    <>
      <Card className="mt-4">
        <Card.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: '24px',
            }}
          >
            {org?.name}
          </p>
          <span
            style={{
              backgroundColor: '#303030',
              color: '#fff',
              padding: '8px',
              fontSize: '16px',
              borderRadius: '4px',
            }}
          >
            ID:{org?.id}
          </span>
        </Card.Header>

        <Card.Body>
          <Row className="mb-2">
            <Col lg="4">
              <img
                style={{ maxWidth: '200px' }}
                src={org?.badge_logo_url}
                alt="org logo"
              />
            </Col>
            <Col>
              <div
                style={{
                  marginLeft: '16px',
                }}
              >
                <TextInformationLine label="ID" data={org?.id} />
                <TextInformationLine
                  label="Account Manager"
                  data={accountManager || 'N/A'}
                  onUpdateClick={() => setShowUpdateAccountManagerModal(true)}
                />
                <TextInformationLine label="Created" data={org?.created_at} />

                <TextInformationLine
                  label="Subscription Status"
                  data={org?.organization_subscription?.subscription_status}
                  onUpdateClick={() =>
                    setShowUpdateSubscriptionStatusOverrideModal(true)
                  }
                  buttonLabel="Override"
                />
                {isInHouseTrial && (
                  <TextInformationLine
                    label="Trial End"
                    data={moment(
                      org?.organization_subscription?.trial_end
                    ).format('MM/DD/YYYY')}
                    onUpdateClick={handleResetTrialDate}
                    buttonLabel="Reset Trial Date"
                  />
                )}
                <TextInformationLine
                  label="Seat Count"
                  data={org?.organization_subscription?.seat_count}
                />
                <TextInformationLine
                  label="Sale Representative"
                  data={saleRep || 'N/A'}
                  onUpdateClick={() => setShowUpdateSaleRepModal(true)}
                />

                <TextInformationLine
                  label="Tier"
                  data={org?.organization_internal_metadatum?.tier}
                  onUpdateClick={handleEditTierClicked}
                >
                  <Button
                    className="py-0 px-2"
                    style={{ marginTop: '-8px' }}
                    variant="link"
                    onClick={resetTier}
                  >
                    Reset
                  </Button>
                </TextInformationLine>
                <TextInformationLine
                  label="Seat Count"
                  data={org?.organization_subscription?.seat_count}
                />
                <TextInformationLine
                  label="Open in Hubspot"
                  buttonLabel={
                    org?.internal_crm_url
                      ? 'Edit Hubspot Url'
                      : 'Add Hubspot Url'
                  }
                  onUpdateClick={handleEditHubspotUrlClicked}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* Add account manager to organization */}
      <UpdateAccountManagerOrSaleRepToOrgModal
        show={showUpdateSaleRepModal}
        handleClose={() => setShowUpdateSaleRepModal(false)}
        orgId={orgId}
        internalAdmins={internalAdmins}
        isInternalAdminsLoading={isInternalAdminsLoading}
        modalName="saleRep"
      />
      <UpdateAccountManagerOrSaleRepToOrgModal
        show={showUpdateAccountManagerModal}
        handleClose={() => setShowUpdateAccountManagerModal(false)}
        orgId={orgId}
        internalAdmins={internalAdmins}
        isInternalAdminsLoading={isInternalAdminsLoading}
        modalName="accountManager"
      />
    </>
  )
}

export default OrganizationDetailsCard
