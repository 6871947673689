import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { toast } from 'react-hot-toast'
import { OrganizationSubscription } from 'interfaces/organization-subscription'

type OrgSubscriptionData = Partial<OrganizationSubscription> & {
  reset_trial?: boolean
}

const updateOrganizationSubscription = async (
  orgId: string,
  organizationSubscription: OrgSubscriptionData
) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/organization_subscriptions`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(organizationSubscription),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUpdateOrganizationSubscription = (orgId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (organizationSubscription: OrgSubscriptionData) =>
      updateOrganizationSubscription(orgId, organizationSubscription),
    {
      onError: (e: Error) => {
        toast('Error updated organization subscription_id')
      },
      onSuccess: (data) => {
        toast('Successfully updated organization subscription_id!')
      },
      onSettled: () => {
        queryClient.invalidateQueries(organizationsKeys.detail(orgId))
      },
    }
  )
}

export default useUpdateOrganizationSubscription
