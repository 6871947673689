import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { organizationsKeys } from 'utils/query-keys'
import { ID } from 'utils/types'
import { OrganizationInternalMetadatum } from 'interfaces/organization-internal-metadatum'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

interface ApiResponse {
  data: {
    organization_internal_metadatum: OrganizationInternalMetadatum
  }
}

const useUpdateOrgInternalMetadatum = (organizationId: ID) => {
  const queryClient = useQueryClient()

  const updateOrgInternalMetadatum = async (
    data: OrganizationInternalMetadatum
  ) => {
    let response = await fetch(
      `${BASE_API_URL}/api/v2/organizations/${organizationId}/internal_metadata/${data.id}`,
      {
        method: API_METHODS.PUT,
        headers: apiHeaders(store.getState()),
        body: JSON.stringify(data),
      }
    )
    response = handleErrors(response)
    const json = await response.json()

    return json.data.organization_internal_metadatum
  }

  return useMutation(
    (data: OrganizationInternalMetadatum) => updateOrgInternalMetadatum(data),

    {
      onError: (e: Error) => {
        toast('Error updated organization primary use case')
      },
      onSuccess: (data) => {
        toast('Successfully updated organization primary use case!')
      },
      onSettled: () => {
        queryClient
          .invalidateQueries(organizationsKeys.detail(organizationId))
          .catch(console.error)
      },
    }
  )
}
export default useUpdateOrgInternalMetadatum
