import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import { QueryOptions, useQuery } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'

const fetchWorkosOrganizationAdminPortalLink = async (id: string | number) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v2/workos_organizations/${id}/portal_link`,
      {
        method: 'GET',
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: { data: { portal_link: string } } = await response.json()
    return json.data.portal_link
  } catch (error) {
    console.error('error', error)
  }
}

const useWorkosOrganizationPortalLink = (id: string | number) => {
  return useQuery(
    organizationsKeys.detail(id),
    () => fetchWorkosOrganizationAdminPortalLink(id),
    { enabled: false }
  )
}

export default useWorkosOrganizationPortalLink
