import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'
import { usersQueryKeys } from 'utils/query-keys'
import { ID } from 'utils/types'

const fetchDeletePhoneNumber = async (phoneNumberId: ID) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v2/phone_numbers/${phoneNumberId}`,
    {
      method: API_METHODS.DELETE,
      headers: apiHeaders(store.getState()),
    }
  )
  handleErrors(response)
}

const useDeletePhoneNumber = () => {
  const queryClient = useQueryClient()
  return useMutation(fetchDeletePhoneNumber, {
    onSuccess: (data, variables, context) => {
      toast('Worknumber successfully deleted', { icon: `👍` })
    },
    onError: (e, variables, context) => {
      console.error(e)
      alert(
        'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
      )
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(usersQueryKeys as any)
    },
  })
}

export default useDeletePhoneNumber
