import { Button, Table } from 'react-bootstrap'
import useMutationDeleteUserLinqProduct from 'hooks/use-mutation-delete-user-product'
import { LinqProduct } from 'interfaces/linq-product'
import { Card } from 'interfaces/card'

const UserActivatedProductsTable = ({
  userLinqProducts,
  allLinqProducts,
  cards,
  userUUID = '',
}: {
  userLinqProducts: LinqProduct[]
  allLinqProducts?: LinqProduct[]
  cards?: Card[]
  userUUID?: string
}) => {
  const deleteUserLinqProductMutation = useMutationDeleteUserLinqProduct({
    userUUID,
  })

  const handleDeactivateProductClick = (id: number) => {
    if (window.confirm('Are you sure you want to deactivate this product?'))
      deleteUserLinqProductMutation.mutate({ userLinqProductId: id })
  }

  return (
    <Table striped bordered hover size="sm" variant="dark">
      <tbody>
        {userLinqProducts?.map((userLinqProduct) => (
          <tr className="pointer" key={userLinqProduct.id}>
            <td>
              <img
                src={`${
                  allLinqProducts?.find(
                    (lp) => lp.name === userLinqProduct.kind
                  )?.image_path
                }`}
                alt={userLinqProduct.nick_name}
                className="bg-white img-fluid img-thumbnail my-1"
                style={{ maxWidth: '90px' }}
              />
            </td>
            <td>
              <h5 className="my-1">{userLinqProduct.nick_name}</h5>
              <p className="mb-1 text-white-50">{userLinqProduct.kind}</p>
              <p className="mb-1 text-white-50">
                /
                {
                  cards?.find(
                    (card) => userLinqProduct.alias.aliasable_id === card.id
                  )?.connecting_code
                }
              </p>
              <p className="mb-1 text-white-50">
                <code>/{userLinqProduct.alias.code}</code>
              </p>
            </td>
            <td>
              <Button
                disabled={deleteUserLinqProductMutation.isLoading}
                onClick={() => handleDeactivateProductClick(userLinqProduct.id)}
                variant="danger"
              >
                Deactivate
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
export default UserActivatedProductsTable
