import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { usersQueryKeys } from 'utils/query-keys'

const fetchUser = async (userUuid: string) => {
  let response = await fetch(`${BASE_API_URL}/api/v1/users/${userUuid}`, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUserDetail = (uuid: string) => {
  return useQuery(usersQueryKeys.detail(uuid), () => fetchUser(uuid), {
    enabled: !!uuid,
  })
}

export default useUserDetail
