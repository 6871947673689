export function handleErrors(response: Response) {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

export function handleErrorsAndJSON(response: Response) {
  if (response.ok === true) {
    return response
  }
  return response.json().then((json) => {
    const error = {
      ...json,
      error: true,
      status: response.status,
      statusText: response.statusText,
      message: json.error?.messages.join(': '),
    }
    return Promise.reject<Response>(error)
  })
}
