import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  FormControl,
  Table,
  Breadcrumb,
  Button,
  FormGroup,
} from 'react-bootstrap'
import moment from 'moment'
import useZakekeOrderDetail from 'hooks/use-zakeke-order-detail'
import useMutationUpdateZekekeOrder from 'hooks/use-mutation-update-zakeke-oder'
import { useHistory } from 'react-router-dom'

const ZakekeOrderEditPage = () => {
  const [order, setOrder] = useState<any>({})
  const { orderId } = useParams<any>()
  const history = useHistory()
  const { data, isFetching } = useZakekeOrderDetail(orderId)
  const updateZekekeOrder = useMutationUpdateZekekeOrder(orderId)

  const handleInPutChange = ({
    target: { value },
  }: {
    target: { value: string }
  }) => {
    setOrder({ ...order, quantity: value })
  }

  const handleSaveClick = (e: React.MouseEvent<HTMLElement | MouseEvent>) => {
    e.preventDefault()
    updateZekekeOrder.mutate(order)
    history.push(`/zakeke_orders`)
  }

  useEffect(() => {
    if (data) {
      setOrder(data)
    }
  }, [data])

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/zakeke_orders">Zakeke Orders</Breadcrumb.Item>
        <Breadcrumb.Item active href="/zakeke_orders/:orderId/edit">
          Edit Order {order?.order_id}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>Shopify Order ID</th>
            <th>Zakeke Order ID</th>
            <th>Quantity</th>
            <th>Name</th>
            <th>Contains Color</th>
            <th>Contains White</th>
            <th>Images</th>
            <th>PNG Url</th>
            <th>Preview</th>
            <th>Order Date</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td>loading.......</td>
            </tr>
          ) : (
            <tr>
              <td>{order.friendly_shopify_order_id}</td>
              <td>{order.order_id}</td>
              <td>
                <FormGroup>
                  <FormControl
                    autoComplete="off"
                    defaultValue={order?.quantity}
                    type="text"
                    onChange={(e) => handleInPutChange(e)}
                  />
                </FormGroup>
              </td>
              <td>{order.name}</td>
              <td>{order.image_contains_color ? 'Yes' : 'No'}</td>
              <td>{order.image_contains_white ? 'Yes' : 'No'}</td>
              <td>
                <a target="_blank" rel="noreferrer" href={order.zip_url}>
                  {' '}
                  Download All{' '}
                </a>
              </td>
              <td>
                <a target="_blank" rel="noreferrer" href={order.design_png_url}>
                  {' '}
                  Download PNG{' '}
                </a>
              </td>
              <td>
                <a target="_blank" rel="noreferrer" href={order.preview_url}>
                  <img
                    alt="order"
                    className="bg-white img-fluid img-thumbnail"
                    style={{ maxWidth: '90px' }}
                    src={order.preview_url}
                  />
                </a>
              </td>
              <td>{moment(order?.ordered_at).format('lll')}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Button
        className="mt-4 mb-4"
        size="lg"
        variant="success"
        onClick={handleSaveClick}
        type="submit"
      >
        Save
      </Button>
    </div>
  )
}
export default ZakekeOrderEditPage
