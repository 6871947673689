import React, { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import useSendAnalyticsEmail from 'hooks/use-send-analytics-email'

const WeeklyAnalyticsEmailPage = () => {
  const [confirmSend, setConfirmSend] = useState(false)
  const [sending, setSending] = useState(false)
  const sendAnalyticsEmail = useSendAnalyticsEmail()

  const handleSendEmailClicked = async () => {
    toast('Sending email...', { icon: '✈️' })
    setSending(true)
    sendAnalyticsEmail.mutate(null, {
      onSuccess: () => {
        setSending(false)
      },
      onSettled: () => {
        setSending(false)
      },
    })
  }

  return (
    <Container>
      <Row>
        <Col lg={4}>
          <Card className="m-4">
            <div className="card-body">
              <h4 className="card-title">Weekly Analytics Email</h4>
              <div className="card-subtitle m-b-2 text-muted">
                Ready to send out the weekly analytics email?
              </div>
              <div className="card-subtitle mt-2 mb-2">
                ⚠️ This should only be done once. If it is done more than once
                then we will send out multiple emails to every user in the
                system. ⚠️
              </div>
              <Button
                disabled={!confirmSend || sending}
                variant="success"
                onClick={handleSendEmailClicked}
              >
                {sending ? 'Sending Emails...' : 'Start Email Send Job'}
              </Button>
              <Form.Check
                type="checkbox"
                checked={confirmSend}
                onChange={(e) => setConfirmSend(e.target.checked)}
                className="mb-2 mt-2"
                id="default-checkbox"
                label="Confirm send to all users?"
              />
              {confirmSend ? (
                <Alert variant="warning" className="mt-2">
                  This will send out the analytics email to all users. Be sure
                  you are ready before you click it. There is no way to undo
                  this action.
                </Alert>
              ) : (
                <Alert variant="danger" className="mt-2">
                  You must check the confirm send checkbox before you are able
                  to send.
                </Alert>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default WeeklyAnalyticsEmailPage
