import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationHubspotNotesKeys } from 'utils/query-keys'

const fetchOrganizationHubspotNotes = async (organizationId?: string) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v2/organizations/${organizationId}/organization_hubspot_notes`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: {
      notes: [
        {
          hs_object_id: string
          hs_timestamp: string
          hs_note_body: string
          hubspot_owner: {
            email: string
            first_name: string
            last_name: string
          }
        }
      ]
    } = await response.json()
    return json.notes
  } catch (error) {
    console.error('error', error)
  }
}

const useOrganizationHubspotNotes = (organizationId?: string) => {
  return useQuery(
    organizationHubspotNotesKeys.detail(organizationId || ''),
    () => fetchOrganizationHubspotNotes(organizationId),
    {
      enabled: !!organizationId,
    }
  )
}

export default useOrganizationHubspotNotes
