export const ORDER_STATUSES = {
  NON_ARCHIVED_ORDERS: {
    value: 'non_archived_orders',
    display: 'Open Orders',
  },
  ARCHIVED_ORDERS: {
    value: 'archived_orders',
    display: 'Printed Orders',
  },
  ALL_ORDERS: {
    value: 'all_oders',
    display: 'All Orders',
  },
}
