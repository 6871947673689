import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Card,
  Accordion,
  Button,
  Table,
} from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'
import useOrganization from 'hooks/use-organization'
import TextInformationLine from 'components/text-information-line'
import useTeam from 'hooks/use-team'
import useUserTeams from 'hooks/use-user-teams'
import AddUserToTeamOrOrgModal from 'components/add-user-to-team-or-org-modal'
import { Organization } from 'interfaces/organization'
import { UserOrganization } from 'interfaces/user-organization'

const TeamDetailPage = () => {
  const params = useParams<any>()
  const history = useHistory()
  const { orgId, teamId } = params

  const { data: team = {}, isLoading: isTeamLoading } = useTeam(teamId)

  const { data: userTeams = [], isLoading: isUserTeamsLoading } =
    useUserTeams(teamId)

  const { data: organization } = useOrganization(orgId)

  const [showAddUserToTeamModal, setShowAddUserToTeamModal] = useState(false)

  const handleUserClicked = (userOrg: UserOrganization) => {
    history.push(`/user/${userOrg.user.uuid}`)
  }

  const handleAddUserToTeamClicked = () => {
    setShowAddUserToTeamModal(true)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
        <Breadcrumb.Item href={`/organizations/${orgId}`}>
          {organization?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{team.name || 'Team Details'}</Breadcrumb.Item>
      </Breadcrumb>
      {isTeamLoading ? (
        <div className="m-2">Loading...</div>
      ) : (
        <>
          <Row className="mb-4">
            <Col lg="6">
              <Card className="mt-4">
                <Card.Header>
                  <h4>Team Details</h4>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-2">
                    <Col>
                      <img
                        style={{ maxWidth: '200px' }}
                        src={team.logo_url}
                        alt="team logo"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInformationLine label="ID" data={team.id} />
                      <TextInformationLine label="Name" data={team.name} />
                      <TextInformationLine
                        label="Created"
                        data={team.created_at}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      View All Team Data
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <code>
                        <JSONPretty data={team} />
                      </code>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            {isUserTeamsLoading ? (
              <div className="m-2">Loading...</div>
            ) : (
              <Col lg="12">
                <Card className="mt-4">
                  <Card.Header>
                    <h4>Team Members</h4>
                  </Card.Header>
                  <Card.Body>
                    <Button
                      onClick={handleAddUserToTeamClicked}
                      variant="success"
                      className=" mb-3"
                    >
                      Add User to Team
                    </Button>
                    <Table striped bordered hover responsive variant="dark">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userTeams.map((userTeam) => (
                          <tr
                            key={userTeam.id}
                            className="pointer"
                            onClick={() => handleUserClicked(userTeam)}
                          >
                            <td>{userTeam.user.name}</td>
                            <td>{userTeam.user.email}</td>
                            <td>{userTeam.user.phone_number}</td>
                            <td>{userTeam.is_admin ? 'Admin' : 'Member'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </>
      )}
      <AddUserToTeamOrOrgModal
        isOrg={false}
        teamOrOrg={team}
        show={showAddUserToTeamModal}
        handleClose={() => setShowAddUserToTeamModal(false)}
      />
    </Container>
  )
}

export default TeamDetailPage
