import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useGetAllLinqProducts } from 'hooks/use-get-linq-products'
import { LinqProduct } from 'interfaces/linq-product'

const ProductsPage = () => {
  const history = useHistory()
  const [linqProducts, setLinqProducts] = useState<LinqProduct[]>([])
  const { data, isFetching } = useGetAllLinqProducts()

  useEffect(() => {
    if (data) {
      setLinqProducts(data.linq_products)
    }
  }, [data])

  const handleProductClicked = (linqProduct: LinqProduct) => {
    history.push(`/products/${linqProduct.id}`)
  }

  const handleNewProductClick = () => {
    history.push(`/create-linq-product`)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/products">
          Linq Products
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Linq Products</h3>
      <Button
        className="mb-3"
        variant="success"
        onClick={handleNewProductClick}
      >
        Create New Product
      </Button>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Name</th>
            <th>Prefix</th>
            <th>Event Badge?</th>
            <th>Updated At</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            linqProducts?.map((linqProduct) => (
              <tr
                className="pointer"
                key={linqProduct.id}
                onClick={() => handleProductClicked(linqProduct)}
              >
                <td>{linqProduct.id}</td>
                <td className="">
                  <img
                    alt="product"
                    className="bg-white img-fluid img-thumbnail"
                    style={{ maxWidth: '90px' }}
                    src={linqProduct.image_path}
                  />
                </td>
                <td>{linqProduct.name}</td>
                <td>
                  <code>{linqProduct.prefix}</code>
                </td>
                <td>
                  {linqProduct.is_event_product ? (
                    <ion-icon name="checkbox" />
                  ) : (
                    <ion-icon name="square-outline" />
                  )}
                </td>
                <td>{moment(linqProduct.updated_at).format('lll')}</td>
                <td>{moment(linqProduct.updated_at).format('lll')}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default ProductsPage
