import React, { useEffect } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LOGOUT_USER } from 'constants/action-types'
import logo from 'images/logo-refresh-small.png'
import './navigation-bar.css'
import { RootState } from 'index'

const NavigationBar = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state: RootState) => state.user.currentUser)

  const handleLogoutClick = () => {
    const confirmed = window.confirm('Are you sure you want to log out?')
    if (confirmed) {
      dispatch({
        type: LOGOUT_USER,
      })
    }
  }

  return (
    <Navbar
      className="pr-3"
      fixed="top"
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
    >
      <Navbar.Brand as={Link} to="/home">
        <img src={logo} className="app-logo" alt="logo" />
      </Navbar.Brand>
      {currentUser ? (
        <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-start mr-auto">
              <Nav.Link eventKey="home" as={Link} to="/home">
                Home
              </Nav.Link>
              <Nav.Link eventKey="users" as={Link} to="/users">
                Users
              </Nav.Link>
              <Nav.Link eventKey="products" as={Link} to="/products">
                Products
              </Nav.Link>
              <Nav.Link eventKey="insights" as={Link} to="/insights">
                Insights
              </Nav.Link>
              <NavDropdown title="Zakeke" id="collasible-nav-dropdown">
                <Nav.Link
                  eventKey="zakeke_orders"
                  as={Link}
                  to="/zakeke_orders"
                >
                  Orders
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link eventKey="print_files" as={Link} to="/print_files">
                  Print Files
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link
                  eventKey="zakeke_products"
                  as={Link}
                  to="/zakeke_products"
                >
                  Zakeke Products
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link
                  eventKey="product-jig-templates"
                  as={Link}
                  to="/product-jig-templates"
                >
                  Product Jig Templates
                </Nav.Link>
              </NavDropdown>
              <NavDropdown title="Organizations" id="collasible-nav-dropdown">
                <Nav.Link
                  eventKey="teams-purchase-link"
                  as={Link}
                  to="/teams-purchase-link"
                >
                  Purchase Link Builder
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link
                  eventKey="organizations"
                  as={Link}
                  to="/organizations"
                >
                  Organizations
                </Nav.Link>
              </NavDropdown>
              <NavDropdown title="Tools" id="collasible-nav-dropdown">
                <Nav.Link
                  eventKey="emails-weekly-analytics-email"
                  as={Link}
                  to="/emails/weekly-analytics-email"
                >
                  Weekly Analytics Email
                </Nav.Link>
                <Nav.Link eventKey="bug-reporter" as={Link} to="/bug-reporter">
                  Bug Reporter
                </Nav.Link>
                <Nav.Link
                  eventKey="entitlement-groupings"
                  as={Link}
                  to="/entitlement-groupings"
                >
                  Entitlement Groupings
                </Nav.Link>
              </NavDropdown>
            </Nav>
            <Nav className="justify-content-end">
              <NavDropdown
                title={currentUser.name}
                id="collasible-nav-dropdown"
              >
                <Nav.Link eventKey="account" as={Link} to="/account">
                  Account
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link
                  eventKey="logout"
                  as={Link}
                  to={{}}
                  onClick={handleLogoutClick}
                >
                  Logout
                </Nav.Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </>
      ) : (
        <></>
      )}
    </Navbar>
  )
}

export default NavigationBar
