import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useUserDetail from 'hooks/use-user-detail'
import { Breadcrumb, Container, Button, Row, Col } from 'react-bootstrap'
import useResetLinqPhone from 'hooks/use-reset-linq-phone'

const UserScriptsPage = () => {
  const { uuid } = useParams<any>()
  const [user, setUser] = useState<any>()
  const userDetailQuery = useUserDetail(uuid)
  const { data, isFetching } = userDetailQuery

  useEffect(() => {
    if (data?.user) {
      setUser(data.user)
    }
  }, [data])

  const { mutate: resetLinqPhone } = useResetLinqPhone()
  const handleResetLinqPhone = () => {
    if (
      window.confirm('Are you sure you want to reset linq phone for this user?')
    ) {
      resetLinqPhone(user?.uuid)
    }
  }

  return (
    <Container className="mb-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
        <Breadcrumb.Item active>{user?.name || `User Detail`}</Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <p>Loading...</p>
      ) : (
        <>
          <Row>
            <Col lg="3">
              <Button variant="success" onClick={handleResetLinqPhone}>
                Reset Linq Phone
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default UserScriptsPage
