import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Container,
  FormControl,
  InputGroup,
  Table,
} from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import useAdminUsersSearch from 'hooks/use-admin-users-search'
import { User } from 'interfaces/user'

const UsersPage = () => {
  const location = useLocation()
  const params = qs.parse(location.search)
  const [query, setQuery] = useState(params.query || '')
  const history = useHistory()

  // Search Query
  const { data, isFetching, refetch } = useAdminUsersSearch(params.query)
  const users = data?.users

  useEffect(() => {
    setQuery(params.query || '')
    refetch(params.query)
  }, [params.query, refetch])

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuery(event.target.value)
  }

  const handleSelectUserClick = (user: User) => {
    history.push(`/user/${user.uuid}`)
  }

  const handleSubmit = () => {
    history.push(`/users?query=${encodeURIComponent(query)}`)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/users">
          Users
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Users</h3>
      <InputGroup className="mb-4 mt-4">
        <FormControl
          autoComplete="off"
          onChange={handleSearchInputChange}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
              handleSubmit()
            }
          }}
          value={query}
          type="phone"
          placeholder="Search"
          aria-label="Search Users"
          autoFocus
        />
        <Button variant="success" onClick={handleSubmit}>
          <ion-icon name="search" />
        </Button>
      </InputGroup>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Username</th>
            <th>Phone</th>
            <th>User Contact Count</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr className="m-2">
              <td>Searching..</td>
            </tr>
          ) : (
            <>
              {users?.map((user) => (
                <tr
                  key={user.id}
                  className="pointer"
                  onClick={() => handleSelectUserClick(user)}
                >
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.username}</td>
                  <td>{user.phone_number}</td>
                  <td>{user.number_of_contacts}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default UsersPage
