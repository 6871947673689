import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { usersQueryKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'
import { UserMetadata } from 'interfaces/user-metadata'

const updateUserMetaData = async (
  userId: string,
  userMetadata: UserMetadata
) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v3/users/${userId}/user_metadata`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(userMetadata),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUpdateUserMetaData = (uuid: string, userId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (userMetadata: UserMetadata) => {
      return updateUserMetaData(userId, userMetadata)
    },
    {
      onError: () => {
        toast(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(usersQueryKeys.detail(uuid))
      },
    }
  )
}

export default useUpdateUserMetaData
