import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap'
import CloudinaryUploadInput from './cloudinary-upload-input'
import { LinqProduct, LinqProductData } from 'interfaces/linq-product'
import { ZakekeData } from 'interfaces/zakeke-product'
import useProductJigTemplates from 'hooks/use-product-jig-templates'
import { ProductJigTemplate } from 'interfaces/product-jig-template'
import { Link } from 'react-router-dom'

const ZakekeProductForm = ({
  zakekeProduct,
  setZakekeProduct,
  handleSaveClick,
}: {
  zakekeProduct: ZakekeData
  setZakekeProduct: React.Dispatch<React.SetStateAction<ZakekeData>>
  handleSaveClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) => {
  const handleInputChange = (
    newValue: string | number | undefined,
    key: string
  ) => {
    setZakekeProduct((zakekeProduct) => ({
      ...zakekeProduct,
      [key]: newValue,
    }))
  }
  const [productJigTemplates, setProductJigTemplates] = useState<
    ProductJigTemplate[]
  >([])
  const { data, isFetching } = useProductJigTemplates()

  useEffect(() => {
    if (!isFetching) {
      setProductJigTemplates(data?.product_jig_templates || [])
    }
  }, [data])

  return (
    <>
      <Form>
        <FormGroup className="mb-4 mt-4">
          <FormLabel>Product Name</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'product_name')
            }
            value={zakekeProduct?.product_name}
            type="text"
            placeholder="Product Name"
            aria-label="Product Name"
          />
        </FormGroup>
        <FormGroup className="mb-4 mt-4">
          <FormLabel>Variant</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'variant')
            }
            value={zakekeProduct?.variant}
            type="text"
            placeholder="Variant"
            aria-label="Variant Name"
          />
        </FormGroup>
        <FormGroup className="mb-4 mt-4">
          <FormLabel>SKU</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) => handleInputChange(event.target.value, 'sku')}
            value={zakekeProduct?.sku}
            type="text"
            placeholder="SKU"
            aria-label="SKU"
          />
        </FormGroup>
        <FormGroup className="mb-4 mt-4">
          <FormLabel>Jig Template</FormLabel>{' '}
          <Link to="/product-jig-templates">Manage</Link>
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) =>
              handleInputChange(
                parseInt(e.target.value),
                'product_jig_template_id'
              )
            }
          >
            <option>Open this select menu</option>
            {productJigTemplates.map((productJigTemplate) => (
              <option
                value={productJigTemplate.id}
                key={productJigTemplate.id}
                selected={
                  productJigTemplate.id ===
                  zakekeProduct.product_jig_template_id
                }
              >
                {productJigTemplate?.jig_name}
              </option>
            ))}
          </select>
        </FormGroup>
        <Button
          className="mt-4 mb-4"
          size="lg"
          variant="success"
          onClick={(e) => handleSaveClick(e)}
          type="submit"
        >
          Save
        </Button>
      </Form>
    </>
  )
}

export default ZakekeProductForm
