import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationSettingsKeys } from 'utils/query-keys'
import { OrganizationSettings } from 'interfaces/organization-settings'

const useOrganizationSettings = ({ orgId }: { orgId: string }) => {
  return useQuery(
    organizationSettingsKeys.detail(orgId),
    () => fetchOrganizationSettings(orgId),
    { enabled: !!orgId }
  )
}

const fetchOrganizationSettings = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/organization_settings`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.organization_settings as OrganizationSettings
}

export default useOrganizationSettings
