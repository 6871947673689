//

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useGetAllLinqProducts } from 'hooks/use-get-linq-products'
import { LinqProduct } from 'interfaces/linq-product'
import { useAdminStripeAvailableProducts } from 'hooks/use-admin-stripe'
import { StripeProduct } from 'interfaces/stripe-product'

const StripeProductsListPage = () => {
  const history = useHistory()
  const { data, isFetching } = useAdminStripeAvailableProducts()

  const getProductClickUrl = (linqProduct: StripeProduct) => {
    // open stripe dashboard dashboard.stripe.com in a new tab but if livemode is false then add test in the url path
    const url = linqProduct.livemode
      ? `https://dashboard.stripe.com/products/${linqProduct.id}`
      : `https://dashboard.stripe.com/test/products/${linqProduct.id}`
    return url
  }

  const handleViewProductsClick = () => {
    // open stripe dashboard dashboard.stripe.com in a new tab
    if (!data) return
    const url = data[0]?.livemode
      ? `https://dashboard.stripe.com/products/`
      : `https://dashboard.stripe.com/test/products`
    window.open(url, '_blank')
  }

  const getMetadatas = (metadata: any) => {
    if (!metadata) return []
    // loop through metadata keys and values an array of strings that are formatted like "key: value"
    const metadataArray = Object.keys(metadata).map(
      (key) => `${key}: ${metadata[key]}`
    )
    // join the array of strings with a new line character
    return metadataArray.join('\n\n \n')
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/stripe-products-list">
          Stripe Products List
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Stripe Products</h3>
      <Button
        className="mb-3"
        variant="success"
        onClick={handleViewProductsClick}
      >
        View all Products on Stripe
      </Button>
      <Table size="large" striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>Image</th>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Changes with Org Seat Count</th>
            <th>Internal Description</th>
            <th>Active</th>
            <th>Statement Descriptor</th>
            <th>Updated At</th>
            <th>Created At</th>
            <th>Metadata</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            data?.map((stripeProduct) => (
              <tr key={stripeProduct.id}>
                <td>
                  {stripeProduct.images[0] && (
                    <img
                      src={stripeProduct.images[0]}
                      alt={stripeProduct.name}
                      style={{ width: '100px' }}
                    />
                  )}
                </td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={getProductClickUrl(stripeProduct)}
                  >
                    {stripeProduct.id}
                  </a>
                </td>
                <td>{stripeProduct.name}</td>
                <td>{stripeProduct.description}</td>
                <td>{stripeProduct.metadata.change_with_org_seat_count}</td>
                <td>{stripeProduct.metadata.internal_description}</td>
                <td>{stripeProduct.active ? 'Yes' : 'No'}</td>
                <td>{stripeProduct.statement_descriptor}</td>
                <td>{moment(stripeProduct.updated).format('lll')}</td>
                <td>{moment(stripeProduct.updated).format('lll')}</td>
                <td>
                  <pre>{JSON.stringify(stripeProduct.metadata, null, 2)}</pre>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default StripeProductsListPage
