import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationSettingsKeys } from 'utils/query-keys'
import { toast } from 'react-hot-toast'
import { OrganizationSettings } from 'interfaces/organization-settings'

type OrgSettingsData = Partial<OrganizationSettings>

const updateOrganizationSettings = async (
  orgId: string,
  orgSettings: OrgSettingsData
) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/organization_settings`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(orgSettings),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.organization_settings
}

const useUpdateOrganizationSettings = ({ orgId }: { orgId: string }) => {
  const queryClient = useQueryClient()
  return useMutation(
    (orgSettings: OrgSettingsData) =>
      updateOrganizationSettings(orgId, orgSettings),
    {
      onError: (e: Error) => {
        toast('Error updated organization settings')
      },
      onSuccess: (data) => {
        toast('Successfully updated organization settings!')
      },
      onSettled: () => {
        queryClient.invalidateQueries(organizationSettingsKeys.detail(orgId))
      },
    }
  )
}

export default useUpdateOrganizationSettings
