import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import toast from 'react-hot-toast'
import { userLinqProductsQueryKeys } from 'utils/query-keys'

interface Params {
  productUrl: string
  cardCode: string | undefined
  userUuid: string | undefined
  nickname: string | undefined
}

const handleActivateProducts = async ({
  productUrl,
  cardCode,
  userUuid,
  nickname,
}: Params) => {
  if (!productUrl || !cardCode || !userUuid) {
    throw new Error(
      'Missing required fields: productUrl, cardCode, or user_uuid'
    )
  }

  const parts = productUrl.split('/')
  const aliasCode = parts[parts.length - 1]

  const response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${userUuid}/user_linq_products`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({
        product_url: productUrl,
        alias_code: aliasCode,
        card_code: cardCode,
        nick_name: nickname,
      }),
    }
  )

  if (!response.ok) {
    throw new Error(`Failed to activate product. Status: ${response.status}`)
  }

  const responseData = await response.json()
  return responseData
}

// Mutation hook
export const useActivateProducts = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ productUrl, cardCode, userUuid, nickname }: Params) => {
      return handleActivateProducts({
        productUrl,
        cardCode,
        userUuid,
        nickname,
      })
    },
    {
      onMutate: () => {
        toast('Product is being activated', { icon: '🕓' })
      },
      onSuccess: (data, { userUuid }) => {
        toast.success('Product activated successfully!')

        if (userUuid) {
          queryClient.invalidateQueries(
            userLinqProductsQueryKeys.index(userUuid)
          )
        }
      },
      onError: (error, { userUuid }) => {
        console.error('Activation error:', error)
        toast.error(`Failed to activate product: {error.message}`)

        if (userUuid) {
          queryClient.invalidateQueries(
            userLinqProductsQueryKeys.index(userUuid)
          )
        }
      },
    }
  )
}
