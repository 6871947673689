import { useQuery } from 'react-query'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { BASE_API_URL } from 'constants/env'
import { AdminInsight } from 'interfaces/admin-insight'

type ApiParams = {
  card_count: number
  start_date: Date
  end_date: Date | null
  event_type: string
  in_organization: boolean
}

type ResponseData = {
  data: {
    insights: AdminInsight[]
  }
}

const fetchAdminAnalyticsInsights = async (requestBody: ApiParams) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v1/analytics_insights`,
      {
        method: API_METHODS.POST,
        headers: apiHeaders(store.getState()),
        body: JSON.stringify(requestBody),
      }
    )
    response = handleErrors(response)
    const json: ResponseData = await response.json()
    return json.data.insights
  } catch (error) {
    console.error('error', error)
    throw Error(error as string)
  }
}

const useAdminAnalyticsInsights = (
  requestBody: ApiParams,
  sendQuery: boolean
) => {
  return useQuery(
    [`admin/api/v1/analytics_insights`, { requestBody }],
    () => fetchAdminAnalyticsInsights(requestBody),
    {
      enabled: sendQuery,
    }
  )
}

export default useAdminAnalyticsInsights
