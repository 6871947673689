import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'

interface RequestData {
  organization_id: string | number
  domains: string[]
}

const postCreateWorkosOrganization = async (data: RequestData) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/workos_organizations/`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(data),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json = await response.json()
  return json
}

const useMutationCreateWorkosOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (data: RequestData) => {
      return postCreateWorkosOrganization(data)
    },
    {
      onSuccess: async (data, variables, context) => {
        toast('Organization Added to Workos', { icon: `👍` })
        await Promise.all([
          queryClient.invalidateQueries(organizationsKeys.all),
        ])
      },
      onError: (e: Error, variables, context) => {
        console.error(e)
        alert(
          e.message ??
            'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useMutationCreateWorkosOrganization
