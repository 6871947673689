import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { userPushTokensKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'

export interface PushTokenTestData {
  title: string
  body: string
  image_url: string
  data_link_route: string
  push_token_id?: string
}

const sendPushTokenTest = async (uuid: string, data: PushTokenTestData) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${uuid}/push_tokens/${data.push_token_id}/send_test `,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(data),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useSendPushTokenTest = (uuid: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (data: PushTokenTestData) => {
      return sendPushTokenTest(uuid, data)
    },
    {
      onError: () => {
        toast(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      onSettled: () => {
        userPushTokensKeys.detail(uuid)
      },
    }
  )
}

export default useSendPushTokenTest
