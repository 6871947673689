import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { store } from '..'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'
import { zakekeOrderLineQueryKeys } from 'utils/query-keys'

const sendUpdateZekekeOrder = async (orderId: string, quantity: number) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/zakeke_line_items/${orderId}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({ quantity }),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useMutationUpdateZekekeOrder = (orderId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ quantity }: { quantity: number }) => {
      return sendUpdateZekekeOrder(orderId, quantity)
    },
    {
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      onSuccess: (data, variables, context) => {
        toast('Zekeke Order is Updated', { icon: `👍` })
        queryClient.invalidateQueries(zakekeOrderLineQueryKeys.detail(orderId))
      },
    }
  )
}
export default useMutationUpdateZekekeOrder
