import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import { organizationsKeys, workosOrganizationsKeys } from 'utils/query-keys'

const deleteWorkosOrganization = async (
  workosOrganizationId: string | number
) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v2/workos_organizations/${workosOrganizationId}`,
    {
      method: API_METHODS.DELETE,
      headers: apiHeaders(store.getState()),
    }
  )
  return response
}

const useMutationDeleteWorkosOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ workosOrganizationId }: { workosOrganizationId: string | number }) => {
      return deleteWorkosOrganization(workosOrganizationId)
    },
    {
      onSuccess: async (data, variables, context) => {
        toast('Workos Organization Deleted', { icon: `👍` })

        await Promise.all([
          queryClient.invalidateQueries(workosOrganizationsKeys.all),
          queryClient.invalidateQueries(organizationsKeys.all),
        ])
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useMutationDeleteWorkosOrganization
