import { QueryFunction, QueryKey, useQuery } from 'react-query'

// This is helpful to access the queryKey from the query hook since we are generally
// separating queries into their own files.
export default function useQueryWithKey<TOptions = unknown, TReturn = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TReturn>,
  options: TOptions
) {
  return {
    ...useQuery(queryKey, queryFn, options),
    queryKey,
  }
}
