import React from 'react'
import { Button } from 'react-bootstrap'

const TextInformationLine = ({
  label,
  data,
  dataIsLink,
  dataLinkSettings,
  onUpdateClick = null,
  children = [],
  buttonLabel = 'Update',
}: any) => {
  return (
    <>
      <strong>{label}: </strong>
      {dataIsLink ? (
        <span>
          <a href={dataLinkSettings.href} target={dataLinkSettings.target}>
            {data}
          </a>
        </span>
      ) : (
        <span>{data}</span>
      )}
      {onUpdateClick && (
        <span>
          <Button
            className="py-0 px-2"
            style={{ marginTop: '-8px' }}
            variant="link"
            onClick={onUpdateClick}
          >
            {buttonLabel}
          </Button>
        </span>
      )}
      {children}
      <br />
    </>
  )
}

export default TextInformationLine
