import React, { useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import useUpdateOrganizationSubscription from 'hooks/use-update-organization-subscription'
import {
  OrganizationSubscription,
  OrganizationSubscriptionStatus,
} from 'interfaces/organization-subscription'

const organizationSubscriptionStatuses = [
  { value: OrganizationSubscriptionStatus.Active },
  { value: OrganizationSubscriptionStatus.Trialing },
  { value: OrganizationSubscriptionStatus.PastDue },
  { value: OrganizationSubscriptionStatus.Canceled },
  { value: OrganizationSubscriptionStatus.Unpaid },
  { value: OrganizationSubscriptionStatus.TrialExpired },
]

export const OverrideSubscriptionStatusModal = ({
  show,
  handleClose,
  orgId,
  organizationSubscription,
}: {
  show: boolean
  handleClose: () => void
  orgId: string
  organizationSubscription?: OrganizationSubscription
}) => {
  const [subscriptionStatusOverride, setSubscriptionStatusOverride] =
    useState('none')

  const { mutate: updateOrganizationSubscription, isLoading } =
    useUpdateOrganizationSubscription(orgId)

  const onClose = () => {
    setSubscriptionStatusOverride('none')
    handleClose()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSubscriptionStatusOverride(e.target.value)
  }

  const handleUpdateOrganizationSubscriptionStatusOverride = () => {
    updateOrganizationSubscription({
      subscription_status_override: subscriptionStatusOverride,
    })
  }
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>Organization Subscription Status Override</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mt-4 mb-4">
          <select
            id="orgStatusSelect"
            value={
              organizationSubscription?.subscription_status_override || 'none'
            }
            className="form-select"
            onChange={handleOnChange}
          >
            <option value="none">Select subscription status</option>
            {organizationSubscriptionStatuses?.map(
              (status: { value: OrganizationSubscriptionStatus }) => (
                <option key={status.value} value={status.value}>
                  {status.value}
                </option>
              )
            )}
          </select>
          <label htmlFor="orgStatusSelect">
            Organization Subscription Status Override
          </label>
        </div>
        {isLoading && (
          <Card.Text>
            Updating organization subscription status override
          </Card.Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpdateOrganizationSubscriptionStatusOverride}>
          Save
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
