import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { userPushTokensKeys } from 'utils/query-keys'
import { PushToken } from 'interfaces/push-token'

const useUserPushTokens = (uuid: string) => {
  return useQuery(
    userPushTokensKeys.detail(uuid),
    () => fetchUserPushTokens(uuid),
    { enabled: !!uuid }
  )
}

interface ResponseData {
  data: { push_tokens: PushToken[] }
}

const fetchUserPushTokens = async (uuid: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${uuid}/push_tokens`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: ResponseData = await response.json()
  return json.data.push_tokens
}

export default useUserPushTokens
