import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'

interface Params {}

const sendUpdateOrganization = async (orgId: string, apiParams: Params) => {
  const response = await fetch(
    `${BASE_API_URL}/api/v2/organizations/${orgId}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(apiParams),
    }
  )
  handleErrors(response)
}

const useUpdateAccountManagerOrSaleRepToOrg = ({
  orgId,
}: {
  orgId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      account_manager_user_id,
      sale_rep_id,
    }: {
      account_manager_user_id?: string | number
      sale_rep_id?: string | number
    }) => {
      const apiParams = {
        ...(account_manager_user_id && { account_manager_user_id }),
        ...(sale_rep_id && { sale_rep_id }),
      }
      return sendUpdateOrganization(orgId, apiParams)
    },
    {
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(organizationsKeys.detail(orgId))
        queryClient.invalidateQueries(organizationsKeys.all)
      },
    }
  )
}

export default useUpdateAccountManagerOrSaleRepToOrg
