import useBulkUpload from 'hooks/use-bulk-upload'
import { Modal, Accordion, Card, Button } from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'

export const BulkUploadModal = ({
  showDetailModal,
  handleDetailDataModalClosed,
  bulkUploadId,
}: {
  showDetailModal: boolean
  handleDetailDataModalClosed: () => void
  bulkUploadId: string
}) => {
  const { data: selectedBulkUpload, isFetching: selectedBulkUploadIsFetching } =
    useBulkUpload({ bulkUploadId })
  return (
    <Modal
      size="lg"
      show={showDetailModal}
      onHide={handleDetailDataModalClosed}
    >
      <Modal.Header>
        <Modal.Title>Bulk Upload Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedBulkUploadIsFetching ? (
          <h4>Loading...</h4>
        ) : (
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Valid Data
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <code>
                    <JSONPretty data={selectedBulkUpload?.valid_data} />
                  </code>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Invalid Data
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <code>
                    <JSONPretty data={selectedBulkUpload?.invalid_data} />
                  </code>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDetailDataModalClosed}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
