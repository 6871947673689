import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useInfiniteQuery } from 'react-query'
import { integrationsKeys } from 'utils/query-keys'
import useConstructUrl from './use-construct-url'
import { Integration } from 'interfaces/integration'

interface Props {
  isCustom: boolean
  query?: string
  resultsPerPage?: number
}

const useIntegrations = ({ isCustom, query, resultsPerPage = 24 }: Props) => {
  const { constructUrl } = useConstructUrl()
  const apiParams = {
    ...((isCustom === false || isCustom === true) && { is_custom: isCustom }),
    ...(query && { q: query }),
    ...(resultsPerPage && { results_per_page: resultsPerPage }),
  }

  return useInfiniteQuery(
    [...integrationsKeys.indexes, apiParams],
    ({ pageParam = 1 }) => {
      const url = constructUrl(`${BASE_API_URL}/api/v2/integrations`, {
        ...apiParams,
        page: pageParam,
      })
      return fetchIntegrations(url)
    },
    {
      getNextPageParam: (response) => {
        const { page: currentPage, number_of_pages: numberOfPages } = response
        const nextPage = currentPage + 1
        return currentPage < numberOfPages ? nextPage : undefined
      },
    }
  )
}

interface IntegrationData {
  page: number
  number_of_pages: number
  data: {
    integrations: Integration[] //
  }
}

const fetchIntegrations = async (url: string) => {
  let response = await fetch(url, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json: IntegrationData = await response.json()
  return json
}

export default useIntegrations
