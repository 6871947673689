import React, { useState } from 'react'
import moment from 'moment'
import { Table, Button, Form, Alert } from 'react-bootstrap'
import useDirectoryMappings from 'hooks/use-directory-mappings'
import { ID } from 'utils/types'
import useCreateDirectoryMapping from 'hooks/use-create-directory-mapping'
import useUpdateDirectoryMapping from 'hooks/use-update-directory-mapping'
import useDeleteDirectoryMapping from 'hooks/use-delete-directory-mapping'
import EditableCell from '../editable-cell'

interface Props {
  orgId: ID
}
const DirectoryMappingsTable = ({ orgId }: Props) => {
  const { data: directoryMappings, refetch } = useDirectoryMappings(orgId)
  const createDirectoryMapping = useCreateDirectoryMapping(orgId).mutate
  const updateDirectoryMapping = useUpdateDirectoryMapping(orgId).mutate
  const deleteDirectoryMapping = useDeleteDirectoryMapping(orgId).mutate

  const [newMapping, setNewMapping] = useState({
    static_value: '',
    hr_field: '',
    label: '',
    custom_field_type: 'custom',
  })

  const handleCreate = async () => {
    await createDirectoryMapping({ directoryMapping: newMapping })
    setNewMapping({
      static_value: '',
      hr_field: '',
      label: '',
      custom_field_type: 'custom',
    })
    refetch()
  }

  const handleUpdate = async (id: ID, key: string, value: string) => {
    const directoryMapping = { id, [key]: value }
    await updateDirectoryMapping({ directoryMapping })
    refetch()
  }

  const handleDelete = async (directoryMappingId: ID) => {
    if (window.confirm('Are you sure you want to delete this mapping?')) {
      await deleteDirectoryMapping({ directoryMappingId })
      refetch()
    }
  }

  const fieldTypeOptions = [
    'link',
    'address',
    'phone_number',
    'notes',
    'email',
    'custom',
    'name',
    'phone_or_email',
    'text',
    'company',
    'title',
    'select',
    'location',
  ]

  return (
    <div>
      <h5>
        Directory Mappings - Override all contact data for an org with these
        settings
      </h5>
      <div className="mb-2">
        Use one of these default labels, or add a custom one:
      </div>

      <div className="mb-2 text-bold">
        first_name, last_name, phone_number, location, email, company, title,
        image_url
      </div>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>Id</th>
            <th>Static Value</th>
            <th>HR Field</th>
            <th>Label</th>
            <th>Field Type</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {!directoryMappings && (
            <tr>
              <td colSpan={7}>Loading...</td>
            </tr>
          )}
          {directoryMappings?.map(
            ({
              id,
              static_value,
              hr_field,
              label,
              custom_field_type,
              created_at,
            }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>
                  <EditableCell
                    type="text"
                    value={static_value}
                    onValueChange={(val: string) =>
                      handleUpdate(id, 'static_value', val)
                    }
                  />
                </td>
                <td>
                  <EditableCell
                    type="text"
                    value={hr_field}
                    onValueChange={(val: string) =>
                      handleUpdate(id, 'hr_field', val)
                    }
                  />
                </td>
                <td>
                  <EditableCell
                    type="text"
                    value={label}
                    onValueChange={(val: string) =>
                      handleUpdate(id, 'label', val)
                    }
                  />
                </td>
                <td>
                  <EditableCell
                    value={custom_field_type}
                    onValueChange={(val: string) =>
                      handleUpdate(id, 'custom_field_type', val)
                    }
                    type="dropdown"
                    options={fieldTypeOptions}
                  />
                </td>
                <td>{moment(created_at).format('lll')}</td>
                <td>
                  <Button variant="danger" onClick={() => handleDelete(id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            )
          )}
          <tr>
            {/* Fields for creating a new mapping */}
            <td>New</td>
            <td>
              <Form.Control
                type="text"
                value={newMapping.static_value}
                onChange={(e) =>
                  setNewMapping({ ...newMapping, static_value: e.target.value })
                }
              />
            </td>
            <td>
              <Form.Control
                type="text"
                value={newMapping.hr_field}
                onChange={(e) =>
                  setNewMapping({ ...newMapping, hr_field: e.target.value })
                }
              />
            </td>
            <td>
              <Form.Control
                type="text"
                value={newMapping.label}
                onChange={(e) =>
                  setNewMapping({ ...newMapping, label: e.target.value })
                }
              />
            </td>
            <td>
              <Form.Control
                as="select"
                value={newMapping.custom_field_type}
                onChange={(e) =>
                  setNewMapping({
                    ...newMapping,
                    custom_field_type: e.target.value,
                  })
                }
              >
                {fieldTypeOptions.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </Form.Control>
            </td>
            <td />
            <td>
              <Button onClick={handleCreate}>Create</Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default DirectoryMappingsTable
