import { ORIGIN_ANYONE, SORT_BY_DATE, SORT_DESC } from 'constants/filters'
import { UserContactSearchFilters } from 'interfaces/user-contact-search-filters'

export function getAllFilter(): string {
  return 'all'
}

export function defaultFilters(): UserContactSearchFilters {
  return {
    visibility_scope: getAllFilter(),
    tags: [],
    contact_origin: ORIGIN_ANYONE,
    sort_by: SORT_BY_DATE,
    sort_direction: SORT_DESC,
  }
}
