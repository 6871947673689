import useGetShopifyProducts from 'hooks/use-get-shopify-products'
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

export const AddOrgProductCreditModal = ({
  show,
  handleAddClick,
  handleClose,
}: {
  show: boolean
  handleAddClick: (
    shopifyProductId: string,
    productCreditQuantity: number
  ) => void
  handleClose: () => void
}) => {
  const [productCreditQuantity, setProductCreditQuantity] = useState<number>()
  const [shopifyProductId, setShopifyProductId] = useState<string>()
  const { shopifyProducts } = useGetShopifyProducts()

  useEffect(() => {
    setProductCreditQuantity(undefined)
    setShopifyProductId(undefined)
  }, [show])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Product Credit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="addProductCreditQuantity">
            <div className="form-floating mt-4 mb-4">
              <Form.Control
                as="select"
                className="form-select"
                onChange={(event) => setShopifyProductId(event.target.value)}
              >
                <option value="none">Select a Shopify Product</option>

                {shopifyProducts.map((shopifyProduct) => (
                  <option
                    key={shopifyProduct.id}
                    value={shopifyProduct.external_id_str}
                  >
                    {shopifyProduct.title}
                  </option>
                ))}
              </Form.Control>
              <Form.Label>Shopify Product</Form.Label>
            </div>
            <Form.Control
              type="number"
              min={0}
              value={productCreditQuantity}
              onChange={(event) =>
                setProductCreditQuantity(Number(event.target.value))
              }
              placeholder="Quantity"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Dismiss
        </Button>
        <Button
          variant="success"
          onClick={() => {
            if (shopifyProductId && productCreditQuantity) {
              handleAddClick(shopifyProductId, productCreditQuantity)
            } else {
              alert('Please select a Shopify Product and a quantity')
            }
          }}
        >
          Save Credits
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
