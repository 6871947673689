import { useMutation, useQueryClient } from 'react-query'
import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { ID } from 'utils/types'
import { store } from 'index'
import { apiHeaders } from 'utils/api-headers'
import { UserOrganization } from 'interfaces/user-organization'
import { handleErrors } from 'utils/handle-errors'

const useDeleteUserOrganization = (orgId: ID) => {
  const queryClient = useQueryClient()

  const deleteUserOrganization = async (userOrg?: UserOrganization) => {
    const response = await fetch(
      `${BASE_API_URL}/api/v3/organizations/${orgId}/user_organizations/${userOrg?.id}`,
      {
        method: API_METHODS.DELETE,
        headers: apiHeaders(store.getState()),
      }
    )
    handleErrors(response)
  }

  return useMutation(
    ({ userOrg }: { userOrg?: UserOrganization }) =>
      deleteUserOrganization(userOrg),
    {
      onSettled: () => {
        queryClient
          .invalidateQueries([
            `api/v1/organizations/${orgId}/user_organizations`,
          ])
          .catch(console.error)
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          `Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.`
        )
      },
    }
  )
}

export default useDeleteUserOrganization
