import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import {
  stripeCouponsKeys,
  stripeProductPrices,
  stripeProducts,
} from 'utils/query-keys'
import { LinqProduct } from 'interfaces/linq-product'
import { Price } from 'interfaces/price'
import { LineItemData } from 'interfaces/line-item'
import { StripeProduct } from 'interfaces/stripe-product'

type AvailableProductsData = {
  available_products: {
    data: StripeProduct[]
  }
}

const fetchAvailableProducts = async () => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/stripe/available_products`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: AvailableProductsData = await response.json()
  return json.available_products.data
}

export const useAdminStripeAvailableProducts = () => {
  return useQuery(stripeProducts.all, () => fetchAvailableProducts())
}

type AvailablePricesData = {
  available_prices: {
    data: Price[] //
  }
}

const fetchAvailablePrices = async (product_id: string | number) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/stripe/available_prices?product_id=${product_id}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: AvailablePricesData = await response.json()
  return json.available_prices.data
}

export const useAdminStripeAvailablePrices = (product_id: string | number) => {
  return useQuery(
    stripeProductPrices.index(product_id),
    () => fetchAvailablePrices(product_id),
    {
      enabled: !!product_id,
    }
  )
}

type AvailableCouponsData = {
  available_coupons: {
    data: LineItemData[]
  }
}

const fetchAvailableCoupons = async () => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/stripe/available_coupons`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: AvailableCouponsData = await response.json()
  return json.available_coupons.data
}

export const useAdminStripeAvailableCoupons = () => {
  return useQuery(stripeCouponsKeys.all, () => fetchAvailableCoupons(), {
    enabled: true,
  })
}
