import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'index'

const HomePage = () => {
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <Card className="m-4">
            <div className="card-body">
              <h4 className="card-title">
                Hello, {currentUser?.first_name} 👋
              </h4>
              <div className="card-subtitle m-b-2 text-muted">
                Here are some links to some things to get you started with today 👇
              </div>
              <Link to="/users" className="card-link">
                Find Users
              </Link>
              <br />
              <Link to="/products" className="card-link">
                Manage Products
              </Link>
              <br />
              <Link to="/organizations" className="card-link">
                Manage Organizations
              </Link>
              <br />
              <Link to="/teams-purchase-link" className="card-link">
                Teams Purchase Link Builder
              </Link>
              <br />
              <Link to="/stripe-products-list" className="card-link">
                Stripe Products List
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default HomePage
