import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { entitlementGroupingsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'

interface Params {}

const updateEntitlementGrouping = async (id: string, apiParams: Params) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/entitlement_groupings/${id}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(apiParams),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUpdateEntitlementGrouping = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ entitlement_ids }: { entitlement_ids: number[] }) => {
      const apiParams = {
        entitlement_ids,
      }
      return updateEntitlementGrouping(id, apiParams)
    },
    {
      onError: () => {
        toast("Uh oh! this shouldn't happen. Shout in slack or report a bug.")
      },
      onSettled: () => {
        queryClient.invalidateQueries(entitlementGroupingsKeys.detail(id))
      },
    }
  )
}

export default useUpdateEntitlementGrouping
