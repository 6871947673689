import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { usersQueryKeys } from 'utils/query-keys'
import { AppClipExperience } from 'interfaces/app-clip-experience'

const useUserAppClipExperiences = (userUuid: string) => {
  const fetchUser = async () => {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v2/users/${userUuid}/app_clip_experiences`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json = await response.json()
    return json.data.app_clip_experiences as AppClipExperience[]
  }

  return useQuery(
    usersQueryKeys.appClipExperiences.index(userUuid),
    fetchUser,
    {
      enabled: !!userUuid,
    }
  )
}

export default useUserAppClipExperiences
