import React from 'react'
import { Table, Card } from 'react-bootstrap'
import { MobileDeviceInfo } from 'interfaces/mobile-device-info'

const UserDeviceInfos = ({
  mobileDeviceInfos = [],
  isLoading,
}: {
  mobileDeviceInfos?: MobileDeviceInfo[]
  isLoading?: boolean
}) => {
  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Recent Device Info</h4>
      </Card.Header>
      <Card.Body>
        {isLoading && <Card.Text>Loading....</Card.Text>}
        {mobileDeviceInfos?.length > 0 && (
          <DeviceTable mobileDeviceInfos={mobileDeviceInfos} />
        )}
      </Card.Body>
    </Card>
  )
}
export default UserDeviceInfos

const DeviceTable = ({
  mobileDeviceInfos = [],
}: {
  mobileDeviceInfos: MobileDeviceInfo[]
}) => {
  return (
    <Table striped bordered hover size="sm" variant="dark">
      <thead>
        <tr>
          <td>Model</td>
          <td>Platform</td>
          <td>App Version</td>
          <td>Operating System</td>
          <td>Browser</td>
          <td>Browser Version</td>
        </tr>
      </thead>

      <tbody>
        {mobileDeviceInfos?.map((device) => (
          <tr key={device.id}>
            <td>{device.model}</td>
            <td>{device.platform}</td>
            <td>{device.platform !== 'web' ? device.app_version : 'n/a'} </td>
            <td>{device.operating_system}</td>
            <td>{device.browser ? device.browser : 'n/a'}</td>
            <td>{device.browser_version ? device.browser_version : 'n/a'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
