import React from 'react'
import { Container } from 'react-bootstrap'

const AdminAccountPage = () => {
  return (
    <Container>
      <h3 className="mt-4 mb-4">Admin Account</h3>
    </Container>
  )
}

export default AdminAccountPage
