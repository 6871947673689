import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, Modal, InputGroup, FormControl, Table } from 'react-bootstrap'
import Fuse from 'fuse.js'
import { LineItem, LineItemData } from 'interfaces/line-item'

export const FuzzySearchModal = ({
  show,
  onClose = () => {},
  initialQuery = '',
  itemName = (item: LineItemData) => item.name,
  handleSelectClick = () => {},
  selectButtonText = 'Select',
  data = [],
  modalTitle = 'Search',
}: {
  show: boolean
  onClose: () => void
  initialQuery?: string
  itemName: (item: LineItemData) => string | undefined
  handleSelectClick: (item: LineItemData) => void
  selectButtonText: string
  data?: LineItemData[]
  modalTitle: string
}) => {
  const searchFieldRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState(initialQuery || '')

  const searchOptions = {
    keys: ['id', { name: 'name', weight: 2 }],
    threshold: 0.3,
    fieldNormWeight: 1,
  }
  const fuse = new Fuse(data, searchOptions)
  const results = fuse.search<LineItem>(query)

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  useEffect(() => {
    if (show) {
      setQuery(initialQuery || '')
      searchFieldRef?.current?.focus()
    }
  }, [show, initialQuery])

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={handleSearchInputChange}
            value={query}
            type="phone"
            placeholder="Search"
            aria-label="Search Users"
            ref={searchFieldRef}
          />
        </InputGroup>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>{selectButtonText}</th>
            </tr>
          </thead>
          <tbody>
            {query.length > 0 ? (
              <Fragment key="search">
                {results?.map((result) => {
                  const { item } = result
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{itemName(item)}</td>
                      <td>
                        <Button
                          variant="success"
                          onClick={() => handleSelectClick(item)}
                        >
                          {selectButtonText}
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </Fragment>
            ) : (
              <Fragment key="no-search">
                {data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{itemName(item)}</td>
                    <td>
                      <Button
                        variant="success"
                        onClick={() => handleSelectClick(item)}
                      >
                        {selectButtonText}
                      </Button>
                    </td>
                  </tr>
                ))}
              </Fragment>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
