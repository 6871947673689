import useCustomerioActivity from 'hooks/use-organization-customerio-activity'
import moment from 'moment'
import { Fragment, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'

export const OrganizationRecentCustomerIoEmail = ({
  orgId,
}: {
  orgId?: string
}) => {
  const [show, setShow] = useState(false)
  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Recent Customer.io Email Sends</h4>
      </Card.Header>
      <Card.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Button style={{ marginBottom: '16px' }} onClick={() => setShow(!show)}>
          Show Customer.io activities
        </Button>
        {show && <CustomerIoActivity orgId={orgId} />}
      </Card.Body>
    </Card>
  )
}

const CustomerIoActivity = ({ orgId }: { orgId?: string }) => {
  const { data: customerioActivity, isLoading } = useCustomerioActivity(orgId)
  return (
    <>
      {isLoading ? (
        <p>Loading customer.io activities</p>
      ) : (
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th style={{ minWidth: '204px' }}>Sent Date</th>
              <th>Sent to Email</th>
              <th>CIO Delivery ID</th>
            </tr>
          </thead>
          <tbody>
            {customerioActivity &&
              customerioActivity.map((activity, idx) => (
                <Fragment key={idx}>
                  {activity.activities &&
                    activity.activities.map((act) => (
                      <tr key={act.id}>
                        <td>{moment.unix(act.timestamp).format('lll')}</td>
                        <td>{act.customer_identifiers.email}</td>
                        <td>
                          <a
                            href={`https://fly.customer.io/workspaces/124802/journeys/outbox/deliveries/${act.delivery_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {act.delivery_id}
                          </a>
                        </td>
                      </tr>
                    ))}
                </Fragment>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}
