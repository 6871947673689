import React, { useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import useUpdateAccountManagerOrSaleRepToOrg from 'hooks/use-update-account-manager-or-sale-rep-to-org'
import { User } from 'interfaces/user'

export const UpdateAccountManagerOrSaleRepToOrgModal = ({
  show,
  handleClose,
  orgId,
  internalAdmins = [],
  isInternalAdminsLoading,
  modalName,
}: {
  show: boolean
  handleClose: () => void
  orgId: string
  internalAdmins?: User[]
  isInternalAdminsLoading: boolean
  modalName: string
}) => {
  const [accountManagerId, setAccountManagerId] = useState('none')
  const [saleRepId, setSaleRepId] = useState('none')

  const updateOrganizationMutation = useUpdateAccountManagerOrSaleRepToOrg({
    orgId,
  })

  const onClose = () => {
    setAccountManagerId('none')
    handleClose()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (modalName === 'accountManager') {
      setAccountManagerId(e.target.value)
    } else if (modalName === 'saleRep') {
      setSaleRepId(e.target.value)
    }
  }

  const handleAddAccountManagerOrSaleRepToOrg = () => {
    if (modalName === 'accountManager') {
      updateOrganizationMutation.mutate({
        account_manager_user_id: accountManagerId,
      })
    } else if (modalName === 'saleRep') {
      updateOrganizationMutation.mutate({
        sale_rep_id: saleRepId,
      })
    }
  }
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>
          Add {modalName === 'accountManager' ? 'Account Manager' : 'Sale Rep'}{' '}
          To Org
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mt-4 mb-4">
          <select
            id="internalAdminsSelect"
            value={
              modalName === 'accountManager' ? accountManagerId : saleRepId
            }
            className="form-select"
            onChange={handleOnChange}
          >
            <option value="none">
              Select{' '}
              {modalName === 'accountManager'
                ? 'Account manager'
                : 'Sale Representative'}
              ...
            </option>
            {internalAdmins?.map((admin: User) => (
              <option key={`${admin.uuid}-${admin.id}`} value={admin.id}>
                {admin.name}
              </option>
            ))}
          </select>
          <label htmlFor="internalAdminsSelect">
            {modalName === 'accountManager'
              ? 'Account manager'
              : 'Sale Representative'}
          </label>
        </div>
        {isInternalAdminsLoading && (
          <Card.Text>
            {modalName === 'accountManager'
              ? 'Account manager'
              : 'Sale Representative'}{' '}
            is loading
          </Card.Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleAddAccountManagerOrSaleRepToOrg}>
          Save{' '}
          {modalName === 'accountManager'
            ? 'Account manager'
            : 'Sale Representative'}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
