import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { zakekeProductsKeys } from 'utils/query-keys'
import { ZakekeProduct } from 'interfaces/zakeke-product'
import { PaginatedData } from 'interfaces/paginated-data'

const fetchZakekeProducts = async () => {
  let response = await fetch(`${BASE_API_URL}/admin/api/v1/zakeke_products`, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json: { zakeke_products: ZakekeProduct[] } & PaginatedData =
    await response.json()
  return json
}

const useZakekeProducts = () => {
  return useQuery(zakekeProductsKeys.all, () => fetchZakekeProducts(), {
    enabled: true,
  })
}

export default useZakekeProducts
