import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

const fetchDeleteCard = async (
  userUuid: string | undefined,
  cardId: string,
  force: boolean
) => {
  const response = await fetch(
    `${BASE_API_URL}/api/v1/users/${userUuid}/cards/${cardId}/delete`,
    {
      method: API_METHODS.DELETE,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({ force }),
    }
  )
  handleErrors(response)
}

export interface DeleteCardMutationOptions {
  userUuid?: string
  cardId: string
  force: boolean
}

const useMutationDeleteCard = ({
  cardsForUserQueryKey,
}: {
  cardsForUserQueryKey: QueryKey
}) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userUuid, cardId, force }: DeleteCardMutationOptions) => {
      return fetchDeleteCard(userUuid, cardId, force)
    },
    {
      // When mutate is called:
      onMutate: () => {},
      onSuccess: (data, variables, context) => {
        toast('Page successfully deleted', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(cardsForUserQueryKey)
      },
    }
  )
}

export default useMutationDeleteCard
