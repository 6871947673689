import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { userLinqProductsQueryKeys } from 'utils/query-keys'

const fetchUserLinqProducts = async (uuid: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${uuid}/user_linq_products`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUserLinqProducts = (uuid: string) => {
  return useQuery(
    userLinqProductsQueryKeys.index(uuid),
    () => fetchUserLinqProducts(uuid),
    {
      enabled: true,
    }
  )
}

export default useUserLinqProducts
