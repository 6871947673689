import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { productJigTemplatesKeys } from 'utils/query-keys'
import { ProductJigTemplateData } from 'interfaces/product-jig-template'

const fetchCreateProductJigTemplate = async (
  newProductJigTemplate: ProductJigTemplateData
) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/product_jig_templates`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(newProductJigTemplate),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.product_jig_template
}

const useCreateProductJigTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      newProductJigTemplate,
    }: {
      newProductJigTemplate: ProductJigTemplateData
    }) => fetchCreateProductJigTemplate(newProductJigTemplate),
    {
      onError: (e) => {
        console.error(e)
        // alert(
        //   'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        // )
      },
      onSuccess: (data) => {},
      onSettled: () => {
        queryClient.invalidateQueries(productJigTemplatesKeys.all)
      },
    }
  )
}

export default useCreateProductJigTemplate
