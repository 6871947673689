import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { UserContactSearch } from 'interfaces/user-contact-search'

const TableHeader = ({ columnNames }: { columnNames: string[] }) => {
  return (
    <>
      {columnNames?.map((name) => (
        <th key={name}>{name}</th>
      ))}
    </>
  )
}

export const userContactName = (userContact: Partial<UserContactSearch>) => {
  if (userContact?.contact_first_name || userContact?.contact_last_name) {
    return `${userContact?.contact_first_name} ${
      userContact?.contact_last_name ? userContact?.contact_last_name : ''
    }`.trim()
  }

  return ''
}

const TableBody = ({ userContacts }: { userContacts: UserContactSearch[] }) => {
  return (
    <>
      {userContacts.map((userContact) => {
        const fullName = userContactName(userContact)
        return (
          <tr key={`${userContact.contact_id}-${userContact.user_contact_id}`}>
            <td>{userContact.contact_id}</td>
            <td>
              <img
                style={{ maxWidth: '90px' }}
                src={userContact.contact_image_url}
                alt={fullName}
              />
            </td>
            <td>{fullName}</td>
            <td style={{ maxWidth: '90px' }}>
              <Link to={`/contacts/${userContact.contact_id}`}>
                {`${window.location.origin}/contacts/${userContact.contact_id}`}
              </Link>
            </td>
          </tr>
        )
      })}
    </>
  )
}

const UserContactsTable = ({
  userContacts = [],
  isFetching,
}: {
  userContacts?: UserContactSearch[]
  isFetching?: boolean
}) => {
  return (
    <Table striped bordered hover size="sm" variant="dark">
      <thead>
        <tr>
          <TableHeader
            columnNames={['ID', 'Image', 'Full Name', 'Contact Page']}
          />
        </tr>
      </thead>
      <tbody>
        {isFetching ? (
          <tr>
            <td>Searching</td>
          </tr>
        ) : (
          <TableBody userContacts={userContacts} />
        )}
      </tbody>
    </Table>
  )
}

export default UserContactsTable
