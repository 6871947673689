import useOrganizationTeams from 'hooks/use-organization-teams'
import { Organization } from 'interfaces/organization'
import { Card, Table, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export const OrganizationTeamsCard = ({
  orgId,
  org,
}: {
  orgId: string
  org?: Organization
}) => {
  const history = useHistory()
  const {
    data: teams = [],
    isLoading: isTeamsLoading,
    isError: isTeamsError,
    error: teamsError,
  } = useOrganizationTeams(orgId)

  const handleTeamClick = (teamId: string) => {
    history.push(`/organizations/${orgId}/teams/${teamId}`)
  }
  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Teams</h4>
      </Card.Header>
      <Card.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
        {isTeamsLoading ? (
          <div className="m-2">Loading...</div>
        ) : teamsError instanceof Error ? (
          <div>Unable to load teams: {teamsError.message}</div>
        ) : (
          <Table striped bordered hover responsive variant="dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>Logo</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team) => (
                <tr
                  key={team.id}
                  className="pointer"
                  onClick={() => handleTeamClick(team.id)}
                >
                  <td style={{ width: '100px' }}>{team.id}</td>
                  <td className="text-center" style={{ width: '100px' }}>
                    <Image
                      src={team.logo_url || org?.badge_logo_url}
                      roundedCircle
                      style={{ width: '50px' }}
                    />
                  </td>
                  <td>{team.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  )
}
