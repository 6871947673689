import React from 'react'
import { Dropdown, Row, Col } from 'react-bootstrap'

const UserSettingDropDown = ({
  handleUpdate,
  value,
  setTo,
  remove,
  label,
  isDisabled = false,
}: {
  handleUpdate: (options: { updatedField: boolean }) => void
  value: string | boolean
  setTo: string
  remove: string
  label: string
  isDisabled?: boolean
}) => {
  return (
    <Row>
      <Col
        md="auto"
        className="d-flex align-items-center"
        style={{ paddingRight: '0px' }}
      >
        {label}
      </Col>
      <Col style={{ paddingLeft: '0px' }}>
        <Dropdown className="my-2">
          <Dropdown.Toggle
            variant="info"
            className={`p-1 ${isDisabled ? ' disabled' : ' '}`}
          >
            {value ? `Yes` : `No`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleUpdate({ updatedField: true })}>
              {setTo}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleUpdate({ updatedField: false })}
            >
              {remove}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default UserSettingDropDown
