import useUpdateOrgInternalMetadatum from 'hooks/use-update-org-internal-metadatum'
import { Organization } from 'interfaces/organization'
import { Card, Row, Col, Dropdown, Button } from 'react-bootstrap'

const USE_CASES = [
  {
    title: 'Lead Capture: Sales',
    value: 'lead_capture_sales',
  },
  {
    title: 'Lead Capture: Recruiting',
    value: 'lead_capture_recruiting',
  },

  {
    title: 'Marketing Content',
    value: 'marketing_content',
  },
  {
    title: 'Sustainable Business Card Replacement',
    value: 'sustainable_business_card_replacement',
  },
]

interface OrganizationPrimaryUseCaseProps {
  orgId: string
  org?: Organization
}

export const OrganizationPrimaryUseCaseCard = ({
  orgId,
  org,
}: OrganizationPrimaryUseCaseProps) => {
  const orgInternalMetadatum = org?.organization_internal_metadatum
  const { primary_use_case: orgPrimaryUseCase } = orgInternalMetadatum || {}

  const { mutate: updateInternalMetadatum } =
    useUpdateOrgInternalMetadatum(orgId)

  const updatePrimaryUseCase = (useCase: string) => {
    if (!orgInternalMetadatum) return
    updateInternalMetadatum({
      ...orgInternalMetadatum,
      primary_use_case: useCase,
    })
  }
  if (!org) return <></>

  return (
    <Card className="mt-4">
      <Card.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Primary Use Case</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="mb-2">
          <Col lg="6" className="mt-2">
            <Card>
              <Dropdown className="my-2">
                <Dropdown.Toggle variant="info" className="p-1">
                  {orgPrimaryUseCase || 'Select Primary Use Case'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {USE_CASES?.map((useCase) => (
                    <Dropdown.Item
                      key={useCase.value}
                      onClick={() => updatePrimaryUseCase(useCase.value)}
                    >
                      {useCase.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
