import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { usersQueryKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'

interface Params {}

const sendUpdateUser = async (uuid: string, apiParams: Params) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/users/${uuid}/update`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(apiParams),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUpdateUser = (uuid: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      is_pro,
      linq_internal_admin,
      can_have_unlimited_pages,
      stripe_user_id,
      entitlement_grouping_id,
      pro_plus,
    }: {
      is_pro?: boolean
      linq_internal_admin?: boolean
      can_have_unlimited_pages?: boolean
      stripe_user_id?: null
      entitlement_grouping_id?: number
      pro_plus?: boolean
    }) => {
      const apiParams = {
        is_pro,
        linq_internal_admin,
        can_have_unlimited_pages,
        stripe_user_id,
        entitlement_grouping_id,
        pro_plus,
      }
      return sendUpdateUser(uuid, apiParams)
    },
    {
      onError: () => {
        toast(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(usersQueryKeys.detail(uuid))
      },
    }
  )
}

export default useUpdateUser
