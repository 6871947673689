import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import useMutationUpdateLinqProduct from 'hooks/use-mutation-update-linq-product'
import ProductJigTemplateForm from 'components/product-jig-template-form'
import useProductJigTemplates from 'hooks/use-product-jig-templates'
import useUpdateProductJigTemplate from 'hooks/use-update-product-jig-template'

const ProductJigTemplateEditPage = () => {
  const { productJigTemplateId } = useParams<any>()
  const history = useHistory()
  const [productJigTemplate, setProductJigTemplate] = useState<any>({})
  const { data, isFetching } = useProductJigTemplates()

  const updateProductJigTemplate = useUpdateProductJigTemplate()

  useEffect(() => {
    if (data) {
      const productJigTemplate = data.product_jig_templates.find(
        (productJigTemplate) =>
          productJigTemplate.id === parseInt(productJigTemplateId)
      )
      setProductJigTemplate(productJigTemplate)
    }
    // eslint-disable-next-line
  }, [data])

  const handleSaveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // e.preventDefault()
    updateProductJigTemplate.mutate(
      { newProductJigTemplate: productJigTemplate },
      {
        onSuccess: (data) => {
          // history.push(`/product-jig-templates/${productJigTemplate.id}/edit`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/product-jig-templates">
          Product Jig Templates
        </Breadcrumb.Item>
        <Breadcrumb.Item
          active
          href={`/product-jig-templates/${productJigTemplate?.id}/edit`}
        >
          Edit {productJigTemplate?.jig_name || `Product Jig Template`}
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <h3>Loading...</h3>
      ) : (
        <>
          <h3 className="mt-4 mb-4">Edit Product Template Jig</h3>
          <ProductJigTemplateForm
            productJigTemplate={productJigTemplate}
            setProductJigTemplate={setProductJigTemplate}
            handleSaveClick={handleSaveClick}
          />
        </>
      )}
    </Container>
  )
}

export default ProductJigTemplateEditPage
