import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { EntitlementGrouping } from 'interfaces/entitlement-grouping'
import { useEntitlementGroupings } from 'hooks/use-entitlement-groupings'

const EntitlementGroupingsPage = () => {
  const history = useHistory()
  const { data: entitlementGroupings, isFetching } = useEntitlementGroupings()

  const handleRowClicked = (entitlementGrouping: EntitlementGrouping) => {
    history.push(`/entitlement-groupings/${entitlementGrouping.id}`)
  }

  const handleNewClick = () => {
    history.push(`/create-entitlement-grouping`)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/entitlement-groupings">
          Entitlement Groupings
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Entitlement Groupings</h3>
      <Button className="mb-3" variant="success" onClick={handleNewClick}>
        Create New Entitlement Grouping
      </Button>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Updated At</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            entitlementGroupings?.map((entitlementGrouping) => (
              <tr
                className="pointer"
                key={entitlementGrouping.id}
                onClick={() => handleRowClicked(entitlementGrouping)}
              >
                <td>{entitlementGrouping.id}</td>
                <td>{entitlementGrouping.title}</td>
                <td>{moment(entitlementGrouping.updated_at).format('lll')}</td>
                <td>{moment(entitlementGrouping.updated_at).format('lll')}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default EntitlementGroupingsPage
