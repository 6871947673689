import useOrganizationProductCredits from 'hooks/use-organization-product-credits'
import { useState } from 'react'
import { Card, Button, Table } from 'react-bootstrap'
import { EmailOrgAdminsOnProductCreditsModal } from './email-org-admins-on-product-credits-modal'
import useOrganizationCreateProductCredit from 'hooks/use-organization-create-product-credit'
import { ID } from 'utils/types'
import useOrganizationDeleteProductCredit from 'hooks/use-organization-delete-product-credit'
import useOrganizationUpdateProductCreditQuantity from 'hooks/use-organization-update-product-credit-quantity'
import toast from 'react-hot-toast'
import { EditOrgProductCreditQuantityModal } from './edit-org-product-credit-quantity-modal'
import { AddOrgProductCreditModal } from './add-org-product-credit-modal'

export const OrganizationProductCredits = ({ orgId }: { orgId: string }) => {
  const [showOrgAdminsModal, setShowOrgAdminsModal] = useState(false)
  const [
    showEditProductCreditQuantityModal,
    setShowEditProductCreditQuantityModal,
  ] = useState(false)
  const [showAddProductCreditModal, setShowAddProductCreditModal] =
    useState(false)
  const [productCreditId, setProductCreditId] = useState<ID>(0)
  const [productCreditQuantity, setProductCreditQuantity] = useState<number>(0)
  const { data: productCredits } = useOrganizationProductCredits(orgId)
  const { mutate: addNewProductCredit } =
    useOrganizationCreateProductCredit(orgId)

  const { mutate: deleteProductCredit } =
    useOrganizationDeleteProductCredit(orgId)

  const { mutate: updateProductCreditQuantity } =
    useOrganizationUpdateProductCreditQuantity(orgId)

  const handleAddProductCreditsClicked = () => {
    setShowAddProductCreditModal(true)
  }

  const handleSaveNewProductCredit = (
    shopifyProductId: string,
    productCreditQuantity: number
  ) => {
    addNewProductCredit(
      {
        shopifyProductId: shopifyProductId,
        quantity: productCreditQuantity,
      },
      {
        onSuccess: () => {
          setShowAddProductCreditModal(false)
          toast(`Product credit added`)
        },
        onError: (error) => {
          toast.error(`Error adding product credit: ${error}`)
        },
      }
    )
  }

  const handleRemoveProductCreditClicked = (
    productCreditId: ID,
    productName: string
  ) => {
    if (
      window.confirm(
        `Are you sure you want to remove the product credit for '${productName}'?`
      ) === true
    ) {
      deleteProductCredit(productCreditId.toString(), {
        onSuccess: () => {
          toast(
            `Product credit for '${productName}' removed from the organization`
          )
        },
        onError: (error) => {
          toast.error(
            `Error removing product credit for '${productName}': ${error}`
          )
        },
      })
    }
  }

  const handleEditProductCreditClicked = (
    productCreditId: ID,
    productCreditQuantity: number
  ) => {
    setProductCreditId(productCreditId)
    setProductCreditQuantity(productCreditQuantity)
    setShowEditProductCreditQuantityModal(true)
  }

  const updateQuantity = (
    productCreditId: ID,
    productCreditQuantity: number
  ) => {
    updateProductCreditQuantity(
      {
        productId: productCreditId,
        quantity: productCreditQuantity,
      },
      {
        onSuccess: () => {
          setShowEditProductCreditQuantityModal(false)
          setProductCreditId(0)
          setProductCreditQuantity(0)
          toast(`Product credit updated`)
        },
        onError: (error) => {
          toast.error(`Error updating product credit: ${error}`)
        },
      }
    )
  }

  return (
    <>
      <Card className="mt-4">
        <Card.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={{
              fontSize: '24px',
            }}
          >
            Product Credits
          </p>
          <Button variant="success" onClick={handleAddProductCreditsClicked}>
            + Add Credits
          </Button>
          {productCredits && productCredits?.length > 0 && (
            <Button onClick={() => setShowOrgAdminsModal(true)}>
              Remind Admins
            </Button>
          )}
        </Card.Header>
        <Card.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <Table striped bordered hover responsive variant="dark">
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {productCredits &&
                productCredits.length > 0 &&
                productCredits.map((product) => (
                  <tr key={product.id}>
                    <td>{product.shopify_product.title}</td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {product.quantity}
                        <Button
                          onClick={() =>
                            handleEditProductCreditClicked(
                              product.id,
                              product.quantity
                            )
                          }
                          variant="link"
                        >
                          Edit
                        </Button>
                      </div>
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          handleRemoveProductCreditClicked(
                            product.id,
                            product.shopify_product.title
                          )
                        }
                        variant="link"
                        style={{ color: 'red' }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <EmailOrgAdminsOnProductCreditsModal
        orgId={orgId}
        show={showOrgAdminsModal}
        handleClose={() => setShowOrgAdminsModal(false)}
      />
      <EditOrgProductCreditQuantityModal
        show={showEditProductCreditQuantityModal}
        productId={productCreditId}
        initialQuantity={productCreditQuantity}
        handleClose={() => {
          setShowEditProductCreditQuantityModal(false)
          setProductCreditId(0)
          setProductCreditQuantity(0)
        }}
        handleUpdateClick={updateQuantity}
      />
      <AddOrgProductCreditModal
        show={showAddProductCreditModal}
        handleClose={() => {
          setShowAddProductCreditModal(false)
        }}
        handleAddClick={handleSaveNewProductCredit}
      />
    </>
  )
}
