import React, { MouseEvent, useState } from 'react'

import {
  Breadcrumb,
  Container,
  Button,
  Card,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Table,
} from 'react-bootstrap'
import useStripeSubscription from 'hooks/use-stripe-subscription'
import UserSearchAndSelectModal from 'components/user-search-and-select-modal'
import useAdminCreateOrganization from 'hooks/use-admin-create-organization'
import { useHistory } from 'react-router-dom'
import EditStripeSubscriptionIdModal from 'components/edit-stripe-subscription-id-modal'
import toast from 'react-hot-toast'
import { Organization } from 'interfaces/organization'
import { User } from 'interfaces/user'

const OrganizationCreatePage = () => {
  const history = useHistory()
  const createOrganization = useAdminCreateOrganization()

  const [organization, setOrganization] = useState<Partial<Organization>>({
    name: '',
  })
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState<any>()
  const [searchStripeSubscription, setSearchStripeSubscription] =
    useState(false)
  const [newAdminUser, setNewAdminUser] = useState<User>()
  const [
    showEditStripeSubscriptionIdModal,
    setShowEditStripeSubscriptionIdModal,
  ] = useState(false)
  const [showUserSearchAndSelectModal, setShowUserSearchAndSelectModal] =
    useState(false)

  const {
    data: stripeSubscription = {},
    isFetching: stripeSubscriptionIsFetching,
    isError: stripeSubscriptionIsError,
    error: stripeSubscriptionError,
  } = useStripeSubscription({
    stripeSubscriptionId,
    enabled: searchStripeSubscription,
  }) as any

  const handleInputChange = (data: Partial<Organization>) => {
    setOrganization((_organization) => ({
      ..._organization,
      ...data,
    }))
  }

  const handleSearchStripeSubscriptionClick = () => {
    setShowEditStripeSubscriptionIdModal(true)
  }

  const handleUserButtonClick = (_user: User) => {
    setNewAdminUser(_user)
    setShowUserSearchAndSelectModal(false)
  }

  const handleSaveStripeSubscriptionIdClick = (
    newStripeSubscriptionId: string
  ) => {
    setStripeSubscriptionId(newStripeSubscriptionId)
    setShowEditStripeSubscriptionIdModal(false)
    setSearchStripeSubscription(true)
  }

  const handleSaveClick = (e: MouseEvent) => {
    e.preventDefault()
    const organizationData: Partial<Organization> = {
      ...organization,
      user_id: newAdminUser?.id,
      subscription_id: stripeSubscription.id,
      seat_count: stripeSubscription.quantity,
      subscription_status: stripeSubscription.status,
      subscription_kind: 'stripe',
    }

    if (!organizationData.name) {
      toast('Organization name is required', { icon: '❌' })
      return
    }

    if (!organizationData.subscription_id) {
      toast('Stripe subscription is required', { icon: '❌' })
      return
    }

    createOrganization.mutate(
      { organizationData },
      {
        onSuccess: (data) => {
          history.push(`/organizations/${data.id}`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations/new" active>
          New Organization
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ maxWidth: '800px' }}>
        <h3 className="mt-4 mb-4">Create New Organization</h3>
        <Form>
          <FormGroup className="mb-4 mt-4">
            <FormLabel>Organization Name*</FormLabel>
            <FormControl
              autoComplete="off"
              onChange={(event) =>
                handleInputChange({ name: event.target.value })
              }
              value={organization?.name}
              type="text"
              placeholder="Organization Name"
              aria-label="Organization Name"
            />
          </FormGroup>
          <Card className="mt-4">
            <Card.Header>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>Stripe Subscription*</h4>
                <Button
                  variant="primary"
                  onClick={handleSearchStripeSubscriptionClick}
                >
                  {stripeSubscription?.id ? 'Change' : '+ Add'} Stripe
                  Subscription
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              {stripeSubscriptionIsFetching ? (
                <div className="m-2">Loading...</div>
              ) : stripeSubscriptionIsError ? (
                <div>
                  Unable to load stripe subscription:{' '}
                  {stripeSubscriptionError.message}
                </div>
              ) : (
                <Table striped bordered hover responsive variant="dark">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Seat Count</th>
                      <th>Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: '100px' }}>
                        {stripeSubscription.id}
                      </td>
                      <td className="text-center" style={{ width: '100px' }}>
                        {stripeSubscription.quantity}
                      </td>
                      <td>{stripeSubscription.status}</td>
                      <td>
                        {stripeSubscription.id && (
                          <Button
                            onClick={() =>
                              window.open(
                                `https://dashboard.stripe.com/subscriptions/${stripeSubscription.id}`,
                                '_blank'
                              )
                            }
                          >
                            View
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>
                  Admin User <small>(optional - can be added later)</small>
                </h4>
                <Button
                  variant="primary"
                  onClick={() => setShowUserSearchAndSelectModal(true)}
                >
                  {newAdminUser?.id ? 'Change' : '+ Add'} Org Admin
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <a
                href={`/user/${newAdminUser?.uuid}`}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                {newAdminUser?.name}{' '}
              </a>
            </Card.Body>
          </Card>
          <Button
            className="mt-4 mb-4"
            size="lg"
            variant="success"
            onClick={(e) => handleSaveClick(e)}
            type="submit"
          >
            Save
          </Button>
        </Form>
        <UserSearchAndSelectModal
          show={showUserSearchAndSelectModal}
          handleClose={() => setShowUserSearchAndSelectModal(false)}
          modalTitle="Add User"
          userButtonText="Add User"
          handleUserButtonClick={handleUserButtonClick}
        />
        <EditStripeSubscriptionIdModal
          show={showEditStripeSubscriptionIdModal}
          handleClose={() => setShowEditStripeSubscriptionIdModal(false)}
          handleSaveClick={handleSaveStripeSubscriptionIdClick}
          initialStripeSubscriptionId={stripeSubscriptionId}
        />
      </div>
    </Container>
  )
}

export default OrganizationCreatePage
