import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { userLinqProductsQueryKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'

const fetchDeleteUserLinqProduct = async (
  userLinqProductId: string | number
) => {
  const response = await fetch(
    `${BASE_API_URL}/api/v1/user_linq_products/${userLinqProductId}/delete`,
    {
      method: API_METHODS.DELETE,
      headers: apiHeaders(store.getState()),
    }
  )
  handleErrors(response)
}

const useMutationDeleteUserLinqProduct = ({
  userUUID,
}: {
  userUUID: string
}) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userLinqProductId }: { userLinqProductId: number }) => {
      return fetchDeleteUserLinqProduct(userLinqProductId)
    },
    {
      // When mutate is called:
      onMutate: () => {},
      onSuccess: (data, variables, context) => {
        toast('Product successfully deleted', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(userLinqProductsQueryKeys.index(userUUID))
      },
    }
  )
}

export default useMutationDeleteUserLinqProduct
