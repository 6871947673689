import { WorkosConnection } from 'interfaces/workos-organization'
import moment from 'moment'
import { Table } from 'react-bootstrap'

const SSOConnectionsTable = ({
  connections,
}: {
  connections?: WorkosConnection[]
}) => {
  return (
    <div>
      <h5>SSO Connections</h5>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>Id</th>
            <th>Workos ID</th>
            <th>Status</th>
            <th>Connection Type</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {!connections && (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
          {connections?.map(
            ({
              id,
              workos_id: workosId,
              connection_type: connectionType,
              active,
              created_at: createdAt,
            }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{workosId}</td>
                <td>{active ? 'Active' : 'Inactive'}</td>
                <td>{connectionType}</td>
                <td>{moment(createdAt).format('lll')}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default SSOConnectionsTable
