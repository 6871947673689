import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'

const fetchPrintFiles = async (page: string | number) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v1/print_files?page=${page}`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json = await response.json()
    return json
  } catch (error) {
    console.error('error', error)
  }
}

export const useGetAllPrintFiles = (page: string | number) => {
  return useQuery([`admin/api/v1/print_files`], () => fetchPrintFiles(page), {
    enabled: true,
  })
}
