import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import { useEffect, useState } from 'react'
import { apiHeaders } from 'utils/api-headers'

const useGetShopifyProducts = () => {
  const [shopifyProducts, setShopifyProducts] = useState<any[]>([])
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/api/v3/shopify_products`,
          {
            method: API_METHODS.GET,
            headers: apiHeaders(store.getState()),
          }
        )
        if (!response.ok) {
          throw new Error(`Network error:  + ${response.status}`)
        }
        const json = await response.json()
        setShopifyProducts(json.data.shopify_products)
      } catch (error: any) {
        setError(error)
      }
    }
    fetchProducts()
  }, [])

  return { shopifyProducts, error }
}

export default useGetShopifyProducts
