import React, { useState } from 'react'
import {
  Button,
  FormControl,
  Modal,
  Table,
  InputGroup,
  Dropdown,
  Card,
} from 'react-bootstrap'
import useAdminUsersSearch from 'hooks/use-admin-users-search'
import useCreateUserTeam from 'hooks/use-create-user-team'
import useDebounce from 'hooks/use-debounce'
import useMutationAddUserToOrganization from 'hooks/use-mutation-add-user-to-organization'
import { User } from 'interfaces/user'

const AddUserToTeamOrOrgModal = ({
  show,
  isOrg,
  teamOrOrg,
  handleClose,
  orgId,
}: any) => {
  const teamOrOrgText = isOrg ? 'Organization' : 'Team'

  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 500)
  const { data, isFetching } = useAdminUsersSearch(debouncedQuery)
  const users = data?.users
  const addUserToOrganization = useMutationAddUserToOrganization({
    orgId,
  })

  const addUserToTeam = useCreateUserTeam() as any

  const onClose = () => {
    handleClose()
  }

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuery(event.target.value)
  }

  const handleAddToTeamOrOrgClicked = ({
    user,
    isAdmin,
  }: {
    user: User
    isAdmin: boolean
  }) => {
    if (
      window.confirm(
        `Are you sure you want to add the user '${user.name}' to the '${teamOrOrg?.name}' ${teamOrOrgText}?`
      ) === true
    ) {
      if (isOrg) {
        handleConfirmAddToOrgClick({ user, isAdmin })
      } else {
        handleConfirmAddToTeamClick({ user, isAdmin })
      }
    }
  }

  const handleConfirmAddToOrgClick = ({
    user,
    isAdmin,
  }: {
    user: User
    isAdmin: boolean
  }) => {
    addUserToOrganization.mutate(
      { userId: user.id, organizationId: teamOrOrg.id, isAdmin },
      {
        onSuccess: () => {
          handleClose()
        },
      }
    )
  }

  const handleConfirmAddToTeamClick = ({
    user,
    isAdmin,
  }: {
    user: User
    isAdmin: boolean
  }) => {
    addUserToTeam.mutate(
      {
        user_id: user.id,
        team_id: teamOrOrg.id,
        is_admin: isAdmin,
      },
      {
        onSuccess: () => {
          handleClose()
        },
      }
    )
  }

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Add user to {teamOrOrg?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isOrg && (
          <Card>
            <Card.Body>
              Adding a user to a Team will add them both to the Team and its
              Organization (if not already in the Organization).
            </Card.Body>
          </Card>
        )}

        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={handleSearchInputChange}
            value={query}
            type="phone"
            placeholder="Search"
            aria-label="Search Users"
          />
        </InputGroup>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Add to {teamOrOrgText}</th>
              <th>View User</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <div className="m-2">Searching..</div>
            ) : (
              <>
                {users?.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone_number}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success">
                          Add to {teamOrOrgText}...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleAddToTeamOrOrgClicked({
                                user,
                                isAdmin: false,
                              })
                            }
                          >
                            Add as Member
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleAddToTeamOrOrgClicked({
                                user,
                                isAdmin: true,
                              })
                            }
                          >
                            Add as Admin
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          window.open(`/user/${user.uuid}`, '_blank')
                        }
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddUserToTeamOrOrgModal
