import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Button, Container, Row, Col, Table } from 'react-bootstrap'
import { ORDER_STATUSES } from 'constants/zakeke-oders'
import { useGetAllZakekeLineItems } from 'hooks/use-get-zakeke-line-items'
import useMutationGeneratePrintFile from 'hooks/use-mutation-generate-print-file'
import { PaginationComponent } from 'components/pagination-component'
import { LineItem } from 'interfaces/line-item'
import useZakekeProducts from 'hooks/use-zakeke-products'
import { ZakekeProduct } from 'interfaces/zakeke-product'
import { useHistory } from 'react-router'

interface LineItemWithChecked extends LineItem {
  checked: boolean
}

const ZakekeOrdersPage = () => {
  const history = useHistory()
  const orderOptions = [
    {
      value: ORDER_STATUSES.NON_ARCHIVED_ORDERS.value,
      display: ORDER_STATUSES.NON_ARCHIVED_ORDERS.display,
    },
    {
      value: ORDER_STATUSES.ARCHIVED_ORDERS.value,
      display: ORDER_STATUSES.ARCHIVED_ORDERS.display,
    },
    {
      value: ORDER_STATUSES.ALL_ORDERS.value,
      display: ORDER_STATUSES.ALL_ORDERS.display,
    },
  ]

  const [orders, setOrders] = useState<LineItemWithChecked[]>([])
  const [zakekeLineItems, setZakekeLineItems] = useState<LineItemWithChecked[]>(
    []
  )
  const [lineItemIdsToPrint, setLineItemIdsToPrint] = useState<string[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [productJigTemplateId, setProductJigTemplateId] = useState<number>(0)
  const [orderOption, setOrderOption] = useState(
    ORDER_STATUSES.NON_ARCHIVED_ORDERS.value
  )

  const { data: zakekeProductsData, isFetching: isZakekeProductsFetching } =
    useZakekeProducts()

  useEffect(() => {
    if (zakekeProductsData) {
      setZakekeProducts(zakekeProductsData.zakeke_products)
    }
  }, [zakekeProductsData])

  const [zakekeProducts, setZakekeProducts] = useState<ZakekeProduct[]>([])
  const [productName, setProductName] = useState('')
  const [productNameOptions, setProductNameOptions] = useState<string[]>([])
  const [productVariantOptions, setProductVariantOptions] = useState<string[]>(
    []
  )
  const [productVariant, setProductVariant] = useState('')
  const [quantityToPrint, setQuantityToPrint] = useState<number>(0)
  const [productMaxQuantity, setProductMaxQuantity] = useState<number>(0)

  const { data, refetch, isFetching } = useGetAllZakekeLineItems(
    currentPage.toString(),
    productName,
    productVariant
  )

  useEffect(() => {
    if (!isZakekeProductsFetching && zakekeProducts) {
      const options = [
        ...new Set(
          zakekeProducts.map((product: ZakekeProduct) => product.product_name)
        ),
      ]
      setProductNameOptions(options)
      setProductName(options[0])
      const variants = zakekeProducts
        .filter((product: ZakekeProduct) => product.product_name === options[0])
        .map((product: ZakekeProduct) => product.variant)
      setProductVariantOptions(variants)
      setProductVariant(variants[0])
      setProductJigTemplateId(zakekeProducts[0]?.product_jig_template_id)
      setProductMaxQuantity(zakekeProducts[0]?.max_images)
    }
  }, [isZakekeProductsFetching, zakekeProducts])

  useEffect(() => {
    if (productName && productVariant) {
      refetch()
    }
    setLineItemIdsToPrint([])
  }, [currentPage, refetch, productName, productVariant])

  useEffect(() => {
    if (data) {
      setOrders(
        data.zakeke_line_items.map((lineItem) => ({
          ...lineItem,
          checked: false,
        }))
      )
      setTotalPages(parseInt(data.number_of_pages))
      setCurrentPage(parseInt(data.page))
    }
  }, [data])

  useEffect(() => {
    if (orderOption === ORDER_STATUSES.NON_ARCHIVED_ORDERS.value) {
      const nonArchivedOrder = orders?.filter(
        (order) => order.archived === false
      )
      setZakekeLineItems(nonArchivedOrder)
    } else if (orderOption === ORDER_STATUSES.ARCHIVED_ORDERS.value) {
      const archivedOrders = orders?.filter((order) => order.archived === true)
      setZakekeLineItems(archivedOrders)
    } else if (orderOption === ORDER_STATUSES.ALL_ORDERS.value) {
      setZakekeLineItems(orders)
    }
  }, [orderOption, orders])

  const generatePrintFileMutation = useMutationGeneratePrintFile()

  const handleGeneratePrintFile = async () => {
    generatePrintFileMutation.mutate({
      lineItemIds: lineItemIdsToPrint,
      productJigTemplateId,
    })
  }

  const handleLineItemCheckboxChanged: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    if (event.target.checked) {
      if (!lineItemIdsToPrint.includes(event.target.value)) {
        setLineItemIdsToPrint([...lineItemIdsToPrint, event.target.value])
        setZakekeLineItems((zakekeLineItems) =>
          zakekeLineItems.map((zp) =>
            zp.id.toString() === event.target.value
              ? { ...zp, checked: true }
              : { ...zp }
          )
        )
      }
    } else {
      setLineItemIdsToPrint(
        lineItemIdsToPrint.filter((id) => id !== event.target.value)
      )
      setZakekeLineItems((zakekeLineItems) =>
        zakekeLineItems.map((zp) =>
          zp.id.toString() === event.target.value
            ? { ...zp, checked: false }
            : { ...zp }
        )
      )
    }
  }

  const selectLastX: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const number = parseInt(event.target.value)
    const lineItemsIdsToPrintTemp = zakekeLineItems
      .slice(0, number)
      .map((lineItem) => lineItem.id.toString())
    setLineItemIdsToPrint(lineItemsIdsToPrintTemp)
    setZakekeLineItems((zakekeLineItems) => [
      ...zakekeLineItems
        .slice(0, number)
        .map((lineItem) => ({ ...lineItem, checked: true })),
      ...zakekeLineItems
        .slice(number, zakekeLineItems.length - 1)
        .map((lineItem) => ({ ...lineItem, checked: false })),
    ])
  }

  const handleFilterProductName: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    if (productName !== e.target.value) {
      // const options = productTypes[e.target.value as ProductType]
      const options = zakekeProducts?.filter(
        (product: ZakekeProduct) => product.product_name === e.target.value
      )

      const variantOptions = options.map(
        (product: ZakekeProduct) => product.variant
      )
      setProductName(e.target.value)
      setProductVariantOptions(variantOptions)
      setProductVariant(variantOptions[0])
      setProductJigTemplateId(options[0]?.product_jig_template_id)
      setProductMaxQuantity(options[0]?.max_images)
    }
  }

  const handleFilterProductVariant: React.ChangeEventHandler<
    HTMLSelectElement
  > = (e) => {
    const zakekeProduct = zakekeProducts.find(
      (zp) => zp.product_name === productName && zp.variant === e.target.value
    )
    setProductVariant(e.target.value)

    if (!zakekeProduct) return
    setProductJigTemplateId(zakekeProduct.product_jig_template_id)
    setProductMaxQuantity(zakekeProduct.max_images)
  }

  useEffect(() => {
    if (zakekeLineItems) {
      let total = 0
      lineItemIdsToPrint.forEach((id) => {
        const lineItem = zakekeLineItems.find(
          (item) => item.id.toString() === id
        )
        const quantityToPrintTemp =
          (lineItem?.quantity || 0) - (lineItem?.quantity_printed || 0)

        total += quantityToPrintTemp
      })
      setQuantityToPrint(Math.min(total, productMaxQuantity))
    }
  }, [lineItemIdsToPrint, productMaxQuantity])

  const handleFilterOrdersStatus: React.ChangeEventHandler<
    HTMLSelectElement
  > = (e) => {
    setOrderOption(e.target.value)
  }
  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/zakeke_orders">
          Zakeke Orders
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/print_files">Print Files</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Zakeke Orders</h3>
      <Row className="rounded-top mx-0 py-2 justify-content-end">
        <Col md="auto" className="px-0">
          <Button
            variant="primary"
            onClick={() => history.push('/zakeke_products')}
            className="mx-4"
          >
            Manage Zakeke Products
          </Button>
          <Button
            variant="success"
            onClick={handleGeneratePrintFile}
            disabled={lineItemIdsToPrint.length === 0}
          >
            Generate Print File ({quantityToPrint} / {productMaxQuantity})
          </Button>
          {generatePrintFileMutation.isLoading && (
            <div>Building print file...</div>
          )}
        </Col>
      </Row>
      <Row className="bg-dark rounded-top mx-0 p-2">
        <Col>
          <div className="m-2">
            <span className="form-label h4">Product Name:</span>
            <select
              value={productName}
              onChange={handleFilterProductName}
              className="d-inline-block form-select w-50"
            >
              {productNameOptions.map((productName) => (
                <option value={productName} key={productName}>
                  {productName}
                </option>
              ))}
            </select>
          </div>
          <div className="m-2">
            <span className="form-label h4">Product Variant:</span>
            <select
              value={productVariant}
              onChange={handleFilterProductVariant}
              className="d-inline-block form-select w-50"
            >
              {productVariantOptions.map((variant) => (
                <option value={variant} key={variant}>
                  {variant}
                </option>
              ))}
            </select>
          </div>
          <div className="m-2">
            <span className="form-label h4 mr-1">Filter:</span>
            <select
              value={orderOption}
              onChange={handleFilterOrdersStatus}
              className="d-inline-block form-select w-50"
            >
              {orderOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.display}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col md="auto">
          <PaginationComponent
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </Col>
      </Row>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>
              <input
                onChange={selectLastX}
                placeholder="Select Last X Orders"
              />
            </th>
            <th>Shopify Order ID</th>
            <th>Zakeke Order ID</th>
            <th>Quantity Printed / Quantity Requested</th>
            <th>Name</th>
            <th>Contains Color</th>
            <th>Contains White</th>
            <th>Images</th>
            <th>PNG Url</th>
            <th>Preview</th>
            <th>Order Date</th>
            <th>Order Status</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr className="m-2">
              <td>Loading...</td>
            </tr>
          ) : (
            <>
              {zakekeLineItems?.map((lineItem) => (
                <tr key={`zakekeLineItem${lineItem.id}`}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={lineItem.id.toString()}
                      value={lineItem.id}
                      onChange={(event) => handleLineItemCheckboxChanged(event)}
                      checked={lineItem?.checked}
                    />
                  </td>
                  <td>{lineItem.friendly_shopify_order_id}</td>
                  <td>
                    <Link to={`/zakeke_orders/${lineItem.id}/edit`}>
                      {lineItem.order_id}
                    </Link>
                  </td>
                  <td>
                    {lineItem.quantity_printed} / {lineItem.quantity}
                  </td>
                  <td>{lineItem.name}</td>
                  <td>{lineItem.image_contains_color ? 'Yes' : 'No'}</td>
                  <td>{lineItem.image_contains_white ? 'Yes' : 'No'}</td>
                  <td>
                    <a target="_blank" rel="noreferrer" href={lineItem.zip_url}>
                      {' '}
                      Download All{' '}
                    </a>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={lineItem.design_png_url}
                    >
                      {' '}
                      Download PNG{' '}
                    </a>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={lineItem.preview_url}
                    >
                      <img
                        alt="order"
                        className="bg-white img-fluid img-thumbnail"
                        style={{ maxWidth: '90px' }}
                        src={lineItem.preview_url}
                      />
                    </a>
                  </td>
                  <td>{moment(lineItem?.ordered_at).format('lll')}</td>
                  <td>{lineItem?.archived ? 'Printed Order' : 'Open Order'}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default ZakekeOrdersPage
