import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { productJigTemplatesKeys } from 'utils/query-keys'
import { ProductJigTemplate } from 'interfaces/product-jig-template'

const fetchProductJigTemplates = async () => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/product_jig_templates`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: { product_jig_templates: ProductJigTemplate[] } =
    await response.json()
  return json
}

const useProductJigTemplates = () => {
  return useQuery(
    productJigTemplatesKeys.all,
    () => fetchProductJigTemplates(),
    {
      enabled: true,
    }
  )
}

export default useProductJigTemplates
