import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'

const updateWalletPass = async (cardId: string) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v2/cards/${cardId}/update_pass`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
    }
  )
  handleErrors(response)
}

const useUpdateWalletPass = (cardId: string) => {
  return useMutation(['card', cardId, 'pass'], () => updateWalletPass(cardId), {
    onError: (e: Error) => {
      toast.error(
        'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
      )
      console.error(e)
    },
    onSuccess: () => {
      toast.success('Wallet Pass Being Updated!')
    },
  })
}
export default useUpdateWalletPass
