import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import useUserDetail from 'hooks/use-user-detail'
import {
  Breadcrumb,
  Container,
  Button,
  Card,
  Row,
  Col,
  Spinner,
  InputGroup,
  FormControl,
} from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import useMutationAdminDeleteUser from 'hooks/use-mutation-admin-delete-user'
import useMutationAdminGenerateLogInUrl from 'hooks/use-mutation-admin-generate-log-in-url'
import CopyToClipboardButton from 'components/copy-to-clipboard-button'
import useCardsForUser from 'hooks/use-cards-for-user'
import useMutationDeleteCard from 'hooks/use-mutation-delete-card'
import UserCardCard from 'components/user-card-card'
import useUserLinqProducts from 'hooks/use-get-user-linq-products'
import { useGetAllLinqProducts } from 'hooks/use-get-linq-products'
import useGetUserAuthConfirmation from 'hooks/use_get_user_auth_confirmation'
import UserOrgTeamTable from 'components/user-details-page/user-orgs-teams-table'
import UserActivatedProductsTable from 'components/user-details-page/user-activated-products-table'
import UserProfile from 'components/user-details-page/user-profile-card'
import UserAuthenticationTable from 'components/user-details-page/user-authentication-table'
import UserContactsTable from 'components/user-details-page/user-contacts-table'
import useGetUserContacts from 'hooks/use_get_user_contacts'
import useDebounce from 'hooks/use-debounce'
import useUserUserTeams from 'hooks/use-user-user-teams'
import useUserDeviceInfos from 'hooks/use_get_user_device_info'
import UserDeviceInfos from 'components/user-details-page/user-device-infos-table'
import toast from 'react-hot-toast'
import UserSettingCard from 'components/user-details-page/user-setting-card'
import useUpdateUser from 'hooks/use-update-user'
import { PushTokensModal } from 'components/push-tokens-modal'
import useUserPushTokens from 'hooks/use-user-push-tokens'
import { PushToken } from 'interfaces/push-token'
import { PushTokenResultsModal } from 'components/push-token-results-modal'
import useSendPushTokenTest, {
  PushTokenTestData,
} from 'hooks/use-send-push-token-test'
import { EditPushTokenModal } from 'components/edit-push-token-modal'
import UserAppClipExperiencesTable from 'components/user-details-page/user-app-clip-experiences-table'
import TextInformationLine from 'components/text-information-line'
import useDeletePhoneNumber from 'hooks/use-delete-worknumber'

const UserDetailPage = () => {
  const queryClient = useQueryClient()
  const [signInLink, setSignInLink] = useState<any>()
  const history = useHistory()
  const { uuid } = useParams<any>()
  const [user, setUser] = useState<any>()
  const [userOrganizations, setUserOrganizations] = useState([])
  const [showPushTokensModal, setShowPushTokensModal] = useState(false)
  const [showPushTokenResultsModal, setShowPushTokenResultsModal] =
    useState(false)
  const [showEditPushTokenModal, setShowEditPushTokenModal] = useState(false)
  const [pushToken, setPushToken] = useState<PushToken>()
  const [pushTokenTestData, setPushTokenTestData] = useState<PushTokenTestData>(
    {
      title: '',
      body: '',
      image_url:
        'https://www.freepik.com/free-vector/cookies-logo-design-template_36295240.htm#page=2&query=funny%20logo&position=12&from_view=keyword&track=robertav1_2_sidr',
      data_link_route: 'https://linqapp.com/profile',
    }
  )

  const userDetailQuery = useUserDetail(uuid)
  const { data, isFetching } = userDetailQuery

  const { data: userActivatedLinqProducts } = useUserLinqProducts(uuid)
  const userLinqProducts = userActivatedLinqProducts?.data?.user_linq_products

  const { data: linqProducts } = useGetAllLinqProducts()
  const allLinqProducts = linqProducts?.linq_products

  const cardsForUserQuery = useCardsForUser(uuid)
  const cards = cardsForUserQuery?.data?.cards

  const { data: userTeams } = useUserUserTeams(uuid)

  const { data: authConfirmations, isLoading } =
    useGetUserAuthConfirmation(uuid)
  const userAuthConfirmations = authConfirmations?.data.auth_confirmations

  const { data: mobileDeviceInfos, isLoading: isMobileDeviceInfoLoading } =
    useUserDeviceInfos(uuid)

  const { data: pushTokens } = useUserPushTokens(uuid)

  const updateUser = useUpdateUser(user?.uuid)
  const { mutate: sendPushTokenTest } = useSendPushTokenTest(uuid)

  useEffect(() => {
    if (data?.user) {
      setUser(data.user)
      setUserOrganizations(data.user.user_organizations)
    }
  }, [data])

  // Generate Log in code
  const generateLogInCodeMutation = useMutationAdminGenerateLogInUrl({
    onSuccess: (data, variables, context) => {
      setSignInLink(data.url)
    },
  })

  // Delete User
  const deleteUserMutation = useMutationAdminDeleteUser({
    onSuccess: (data, variables, context) => {
      toast(`User successfully deleted!`, { icon: `🗑` })
      history.replace('/users')
      queryClient.invalidateQueries(`admin/api/v1/users/search`)
    },
  })

  const deletePhoneNumber = useDeletePhoneNumber()

  const handleGetLoginLinkClicked = () => {
    generateLogInCodeMutation.mutate(user?.uuid)
  }

  const handleDeleteUserClicked = () => {
    if (
      window.confirm(
        'Are you sure you want to delete this user? This cannot be undone. Make sure that you have consent from the owner of this account to delete it.'
      ) === true
    ) {
      if (
        window.confirm(
          'Ok. If you are really really sure, please just confirm once more before we delete the user forever...'
        )
      ) {
        deleteUserMutation.mutate(user?.uuid)
      }
    }
  }

  const deleteCardForUserMutation = useMutationDeleteCard({
    cardsForUserQueryKey: cardsForUserQuery.queryKey,
  })

  const handleClickCopy = async (code: string = '') => {
    try {
      await navigator.clipboard.writeText(code)
      toast(`${code} is copied`, { icon: `👍` })
    } catch (e) {
      toast(`Failed to copy`)
    }
  }

  const [contactQuery, setContactQuery] = useState('')
  const debouncedQuery = useDebounce(contactQuery, 500)
  const { data: userContacts = [], isFetching: contactsFetching } =
    useGetUserContacts({ uuid, query: debouncedQuery })

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContactQuery(event.target.value)
  }

  const handleDisconnectUserStripeAccount = () => {
    updateUser.mutate({ stripe_user_id: null })
  }

  const handleSeePushTokenResultsClicked = (pushToken: PushToken) => {
    setPushToken(pushToken)
    setShowPushTokensModal(false)
    setShowPushTokenResultsModal(true)
  }
  const handleSendPushNotificationTest = (pushToken?: PushToken) => {
    sendPushTokenTest(
      {
        ...pushTokenTestData,
        title: pushTokenTestData.title || `This is a test from ${user.name} 😄`,
        body: pushTokenTestData.body || `The body is here!`,
        ...(pushToken && { push_token_id: pushToken.id }),
      },
      {
        onSuccess: () => {
          toast(`Push notification successfully sent`)
        },
      }
    )
  }

  const handleDeletePhoneNumber = (phoneNumberId: string) => {
    if (
      window.confirm(
        'Deleting this phone number will make it unusable by the user. They will then be able to claim a new number in the app.'
      )
    ) {
      deletePhoneNumber.mutate(phoneNumberId)
    }
  }

  return (
    <Container className="mb-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
        <Breadcrumb.Item active>{user?.name || `User Detail`}</Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <p>Loading...</p>
      ) : (
        <>
          <Row>
            <Col lg="3">
              <UserProfile user={user} />
              <UserSettingCard user={user} />
            </Col>
            <Col lg="6">
              <Row>
                <Col md="12">
                  <UserAuthenticationTable
                    userAuthConfirmations={userAuthConfirmations}
                    isLoading={isLoading}
                    handleClickCopy={handleClickCopy}
                  />
                </Col>
                <Col md="12">
                  <UserDeviceInfos
                    mobileDeviceInfos={mobileDeviceInfos}
                    isLoading={isMobileDeviceInfoLoading}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="3">
              <Card className="mt-4">
                <Card.Header>
                  <h4>Login as {user?.name}</h4>
                </Card.Header>
                <Card.Body>
                  {signInLink ? (
                    <CopyToClipboardButton
                      copyText="Copy Link"
                      copiedText="Link Copied"
                      stringToCopy={signInLink}
                    />
                  ) : (
                    <Button
                      variant="success"
                      onClick={handleGetLoginLinkClicked}
                    >
                      Get Login Link
                    </Button>
                  )}
                  {signInLink && (
                    <Card bg="dark mt-2">
                      <div className="card-body">
                        <code>{signInLink}</code>
                      </div>
                    </Card>
                  )}
                </Card.Body>
              </Card>
              {user?.stripe_user_id && (
                <Card className="mt-4">
                  <Card.Header>
                    <h4>
                      Disconnect Stripe Connect Account (used for Linq Pay)
                    </h4>
                  </Card.Header>
                  <Card.Body>
                    <Button
                      variant="danger"
                      onClick={handleDisconnectUserStripeAccount}
                    >
                      Disconnect
                    </Button>
                  </Card.Body>
                </Card>
              )}
              <Card className="mt-4">
                <Card.Header>
                  <h4>Push Tokens</h4>
                </Card.Header>
                <Card.Body>
                  <Button
                    variant="success"
                    onClick={() => setShowPushTokensModal(true)}
                  >
                    Push Tokens
                  </Button>
                </Card.Body>
              </Card>

              <Card className="mt-4">
                <Card.Header>
                  <h4>Worknumber</h4>
                </Card.Header>
                <Card.Body>
                  <Card bg="dark mt-2">
                    <div className="card-body">
                      <TextInformationLine
                        label="Phone"
                        data={user?.worknumber?.number || 'N/A'}
                      />

                      {user?.worknumber && (
                        <Button
                          disabled={deletePhoneNumber.isLoading}
                          onClick={() =>
                            handleDeletePhoneNumber(user.worknumber.id)
                          }
                          variant="danger"
                        >
                          Delete Phone Number
                        </Button>
                      )}
                    </div>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <hr />
          <Card>
            <Card.Header>
              <h4>User Pages</h4>
            </Card.Header>
            <Card.Body>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {cardsForUserQuery.isFetching && (
                  <Spinner animation="border" role="status" />
                )}
                {cards &&
                  cards.map((card) => (
                    <UserCardCard
                      key={card.id}
                      card={card}
                      deleteCardForUserMutation={deleteCardForUserMutation}
                      user={user}
                      cardsForUserQueryKey={cardsForUserQuery.queryKey}
                    />
                  ))}
              </div>
            </Card.Body>
          </Card>
          <hr />
          <Card>
            <Card.Body>
              <h4 className="mb-4">User's Organizations</h4>
              {userOrganizations && (
                <UserOrgTeamTable
                  tableName="Organization"
                  tableBodyData={userOrganizations}
                  tableBodyDataKey="organization"
                  link="organization"
                />
              )}
            </Card.Body>
          </Card>
          <hr />
          <Card>
            <Card.Body>
              <h4 className="mb-4">User Teams</h4>
              {userTeams && (
                <UserOrgTeamTable
                  tableName="Team"
                  tableBodyData={userTeams}
                  tableBodyDataKey="team"
                  link="team"
                />
              )}
            </Card.Body>
          </Card>
          <hr />
          <Card>
            <Card.Body>
              <h4 className="mb-4">Activated Products</h4>
              {userLinqProducts?.length > 0 ? (
                <UserActivatedProductsTable
                  userLinqProducts={userLinqProducts}
                  allLinqProducts={allLinqProducts}
                  cards={cards}
                />
              ) : (
                <Card.Text>No Product Associates With This User</Card.Text>
              )}
            </Card.Body>
          </Card>
          <hr />
          <UserAppClipExperiencesTable userUuid={uuid} />
          <hr />
          <Card>
            <Card.Header>
              <Row className="mb-2 mt-2">
                <Col md={6}>
                  <h4 className="pt-1"> User's Contacts</h4>
                </Col>
                <Col md={6}>
                  <InputGroup>
                    <FormControl
                      autoComplete="off"
                      onChange={handleSearchInputChange}
                      value={contactQuery}
                      type="text"
                      placeholder="Search User Contacts"
                      aria-label="Search User Contact"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body
              style={{ paddingBottom: contactsFetching ? '50%' : '10px' }}
            >
              {contactsFetching && <Card.Text>Searching</Card.Text>}
              {userContacts?.length > 0 && (
                <UserContactsTable userContacts={userContacts} />
              )}
              {!contactsFetching && userContacts?.length === 0 && (
                <Card.Text>No Contacts Associate With This User</Card.Text>
              )}
            </Card.Body>
          </Card>
          <hr />
          <Row className="mt-4">
            <Col>
              <Button
                variant="outline-danger"
                onClick={handleDeleteUserClicked}
              >
                Delete User
              </Button>
            </Col>
          </Row>
        </>
      )}
      {user && pushTokens && (
        <>
          <PushTokensModal
            show={showPushTokensModal}
            onClose={() => setShowPushTokensModal(false)}
            user={user}
            pushTokens={pushTokens}
            handleSeePushTokenClicked={handleSeePushTokenResultsClicked}
            handleTestPushTokenClicked={handleSendPushNotificationTest}
            setShowEditPushTokenModal={setShowEditPushTokenModal}
          />
          <EditPushTokenModal
            user={user}
            show={showEditPushTokenModal}
            onClose={() => setShowEditPushTokenModal(false)}
            setPushTokenTestData={setPushTokenTestData}
          />
        </>
      )}
      {pushToken && (
        <PushTokenResultsModal
          show={showPushTokenResultsModal}
          onClose={() => setShowPushTokenResultsModal(false)}
          pushToken={pushToken}
        />
      )}
    </Container>
  )
}

export default UserDetailPage
