import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { Integration, IntegrationConnection } from 'interfaces/integration'

interface ResponseData {
  data: {
    integration_connections: IntegrationConnection[]
  }
}

const fetchOrganizationIntegrationConnections = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/organizations/${orgId}/integration_connections`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json: ResponseData = await response.json()
  return json.data.integration_connections
}

const useOrganizationIntegrationConnections = ({
  orgId,
}: {
  orgId: string
}) => {
  return useQuery(organizationsKeys.integrationConnections.index(orgId), () =>
    fetchOrganizationIntegrationConnections(orgId)
  )
}

export default useOrganizationIntegrationConnections
