import DeleteWorkosOrganizationButton from 'components/organization-sso-page/delete-workos-organization-button'
import SetupSSOForm from 'components/organization-sso-page/setup-sso-form'
import SSOConnectionsTable from 'components/organization-sso-page/sso-connections-table'
import useOrganization from 'hooks/use-organization'
import useWorkosOrganizationPortalLink from 'hooks/use-workos-portal-url'
import moment from 'moment'
import { Breadcrumb, Button, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import DirectoryMappingsTable from 'components/organization-sso-page/directory-mappings-table'

const OrganizationSSOPage = () => {
  const params = useParams<{ id: string }>()
  const { id: orgId } = params

  const { data: organization, isLoading: isOrganizationLoading } =
    useOrganization(orgId)

  const { workos_organization: workosOrganization } = organization || {
    workos_organization: undefined,
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
        <Breadcrumb.Item href={`/organizations/${orgId}`}>
          {organization?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>HR / SSO</Breadcrumb.Item>
      </Breadcrumb>
      {isOrganizationLoading || !organization ? (
        <div>Loading...</div>
      ) : (
        <>
          {workosOrganization && (
            <div>
              <div className="d-flex flex-column gap-4 mb-4">
                <div className="d-flex flex-column gap-2">
                  <div>Workos ID: {workosOrganization.workos_id}</div>
                  <div>
                    Created At:{' '}
                    {moment(workosOrganization.created_at).format('lll')}
                  </div>
                </div>

                <WorkosPortalLink
                  workosOrganizationId={workosOrganization.id}
                />
              </div>

              <SSOConnectionsTable
                connections={organization.workos_connections}
              />

              <hr />

              <DeleteWorkosOrganizationButton
                workosOrganizationId={workosOrganization.id}
              />
            </div>
          )}
          {!workosOrganization && (
            <div>
              <h6>
                This Organization has not been connected to Workos, HR and SSO
                integrations require a Workos connection. To add this
                organization to Workos, please provide a unique domain name (eg
                linqapp.com) for this organization. Then click the checkboxes on
                the organization details page to allow SSO/HR to be setup.
              </h6>
              <SetupSSOForm organization={organization} />
            </div>
          )}

          <div className="mt-4">
            <DirectoryMappingsTable orgId={orgId} />
          </div>
        </>
      )}
    </Container>
  )
}

export default OrganizationSSOPage

const WorkosPortalLink = ({
  workosOrganizationId,
}: {
  workosOrganizationId: string | number
}) => {
  const { isLoading, data, refetch } =
    useWorkosOrganizationPortalLink(workosOrganizationId)
  return (
    <div>
      <Button className="mb-4" onClick={() => refetch()}>
        Generate Admin Portal Link
      </Button>
      {isLoading && <div>Loading...</div>}
      {data && (
        <div>
          <div>
            {' '}
            Admin Portal Link (This link is only valid for 5 minutes):{' '}
          </div>
          <a
            style={{
              wordWrap: 'break-word',
            }}
            href={data}
          >
            {data}
          </a>
        </div>
      )}
    </div>
  )
}
