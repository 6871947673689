import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { LineItemData } from 'interfaces/line-item'

const StripeProductTable = ({
  addedProductsList,
  handleRemoveProduct = () => {},
}: {
  addedProductsList: LineItemData[]
  handleRemoveProduct?: (id: number) => void
}) => {
  return (
    <Table
      striped
      bordered
      hover
      size="sm"
      variant="dark"
      className="text-center"
    >
      <thead>
        <tr>
          <td>Name</td>
          <td>Description</td>
          <td>Quantity</td>
          <td>Qty Changes with Seat Count</td>
          <td>Is Quantity Adjustable ?</td>
          <td>Delete</td>
        </tr>
      </thead>
      <tbody>
        {addedProductsList.length > 0 &&
          addedProductsList.map((product, index) => (
            <tr key={`${product.id}-${index}`}>
              <td>{product.name}</td>
              <td>
                {product?.description
                  ? product.description
                  : `$${product.price}`}
              </td>
              <td>{product.quantity}</td>
              <td>
                {product.metadata?.change_with_org_seat_count === 'true'
                  ? 'Yes'
                  : 'No'}
              </td>
              <td>{product.isAdjustableQuantity ? 'Yes' : 'No'}</td>
              <td className="d-grid gap-2">
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleRemoveProduct(index)}
                >
                  X
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
export default StripeProductTable
