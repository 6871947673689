import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { customerioACtivityKeys } from 'utils/query-keys'

const fetchCustomerioActivity = async (organizationId?: string) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v2/organizations/${organizationId}/organization_customerio_activities`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: {
      activities: [
        {
          activities: [
            {
              id: string
              delivery_id: string
              timestamp: number
              customer_identifiers: {
                cio_id: string
                email: string
                id: string
              }
            }
          ]
          next: string
        }
      ]
    } = await response.json()
    return json.activities
  } catch (error) {
    console.error('error', error)
  }
}

const useCustomerioActivity = (organizationId?: string) => {
  return useQuery(
    customerioACtivityKeys.detail(organizationId || ''),
    () => fetchCustomerioActivity(organizationId),
    {
      enabled: !!organizationId,
    }
  )
}

export default useCustomerioActivity
