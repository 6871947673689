import { combineReducers } from 'redux'
import user from './user-reducers'
import usersSearch from './users-search-reducers'

const rootReducer = combineReducers({
  user,
  usersSearch,
})

export default rootReducer
