import React from 'react'
import { Table } from 'react-bootstrap'

const TableHeader = ({ tableName }: { tableName: string }) => {
  const headerTitles = ['id', 'name', 'logo', 'link']
  return (
    <>
      {headerTitles.map((title) => (
        <th key={title}>
          {tableName} {title}
        </th>
      ))}
    </>
  )
}

interface Data {
  id: string //
  name: string
  visible_logo_url: string
  badge_logo_url: string
  organization_id: string
}

const TableBody = ({ data, link }: { data: Data; link: string }) => {
  return (
    <tr key={data.id}>
      <td>{data.id}</td>
      <td>{data.name}</td>
      <td>
        <img
          src={data.visible_logo_url || data.badge_logo_url}
          alt={data.visible_logo_url || data.badge_logo_url}
          style={{ maxWidth: '90px' }}
        />
      </td>
      <td style={{ maxWidth: '90px' }}>
        <a
          href={`${window.location.origin}/${link}`}
          target="_blank"
          rel="noreferrer"
        >
          {`${window.location.origin}/${link}`}
        </a>
      </td>
    </tr>
  )
}

const UserOrgTeamTable = ({
  tableName,
  tableBodyData,
  tableBodyDataKey,
  link,
}: {
  tableName: string
  tableBodyData: Record<string, Data>[]
  tableBodyDataKey: number | string
  link: string
}) => {
  return (
    <Table striped bordered hover size="sm" variant="dark">
      {tableBodyData.length > 0 ? (
        <>
          <thead>
            <tr>
              <TableHeader tableName={tableName} />
            </tr>
          </thead>
          <tbody>
            {tableBodyData?.map((data, index) => (
              <TableBody
                data={data[tableBodyDataKey]}
                key={`${data[tableBodyDataKey].id}-${index}`}
                link={
                  link === 'team'
                    ? `organizations/${data?.[tableBodyDataKey]?.organization_id}/teams/${data?.[tableBodyDataKey]?.id}`
                    : `organizations/${data?.[tableBodyDataKey]?.id}`
                }
              />
            ))}
          </tbody>
        </>
      ) : (
        <thead>
          <tr>
            <td>{`No ${tableName} Associated With This User`}</td>
          </tr>
        </thead>
      )}
    </Table>
  )
}

export default UserOrgTeamTable
