import useUserAppClipExperiences from 'hooks/use-user-app-clip-experiences'
import moment from 'moment'
import { Card, Table } from 'react-bootstrap'

const UserAppClipExperiencesTable = ({ userUuid }: { userUuid: string }) => {
  const { data: appClipExperiences, isLoading } =
    useUserAppClipExperiences(userUuid)

  return (
    <Card>
      <Card.Body>
        <h4 className="mb-4">App Clip Experiences</h4>
        {isLoading ? (
          <p>Loading...</p>
        ) : !appClipExperiences?.length ? (
          <Card.Text>No App Clip Experiences With This User</Card.Text>
        ) : (
          <Table striped hover bordered size="sm" variant="dark">
            <tbody>
              {appClipExperiences?.map(
                ({
                  id,
                  card_id,
                  cloudinary_image_url,
                  remote_id,
                  is_active,
                  subtitle,
                  title,
                  url,
                  created_at,
                  updated_at,
                }) => (
                  <tr className="" key={id}>
                    <td>
                      <img
                        src={cloudinary_image_url}
                        alt={title}
                        className="bg-white img-fluid img-thumbnail my-1"
                        style={{ maxWidth: '100px' }}
                      />
                    </td>
                    <td>
                      <p className="my-1">
                        <span className="text-white-50">Title:</span> {title}
                      </p>
                      <p className="mb-1">
                        <span className="text-white-50">Subtitle:</span>{' '}
                        {subtitle}
                      </p>
                      <p className="mb-1">
                        <span className="text-white-50">Attached Card ID:</span>{' '}
                        {card_id}
                      </p>
                      <p className="mb-1">
                        <span className="text-white-50">App Clip URL:</span>{' '}
                        {url}
                      </p>
                      <p className="mb-1">
                        <span className="text-white-50">Status:</span>{' '}
                        {is_active ? 'Active' : 'Inactive'}
                      </p>
                      <p className="mb-1">
                        <span className="text-white-50">Remote ID:</span>{' '}
                        {remote_id}
                      </p>
                      <p className="my-1">
                        <span className="text-white-50">Created:</span>{' '}
                        {created_at ? moment(created_at).format('lll') : 'N/A'}
                      </p>
                      <p className="my-1">
                        <span className="text-white-50">Updated:</span>{' '}
                        {updated_at ? moment(updated_at).format('lll') : 'N/A'}
                      </p>
                    </td>
                    <td>
                      <a
                        href={`https://appstoreconnect.apple.com/appclips/ui/app-experience-review/${remote_id}`}
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                      >
                        Appstore Connect ↗️
                      </a>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  )
}
export default UserAppClipExperiencesTable
