import moment from 'moment'
import { APP_CLIP_WEB_URL, WEB_APP_URL } from 'constants/env'
import { v4 as uuidv4 } from 'uuid'
import { LinqProduct } from 'interfaces/linq-product'

export const createProductionCodeCSV = (
  product: LinqProduct,
  rowCount: number,
  eventCode: string
) => {
  const rows = []

  for (let i = 0; i < rowCount; i++) {
    let row: any = ''
    const isAppClip = product.prefix.startsWith('appclip')
    if (isAppClip) row = [`${APP_CLIP_WEB_URL}/${shortCodeFor(product.prefix)}`]
    else if (product.is_event_badge && eventCode) {
      row = [`${WEB_APP_URL}/${eventShortCodeFor(product.prefix, eventCode)}`]
    } else {
      row = [`${WEB_APP_URL}/${shortCodeFor(product.prefix)}`]
    }
    rows.push(row)
  }

  const csvContent = `data:text/csv;charset=utf-8,${rows
    .map((e) => e.join(','))
    .join('\n')}`

  const fileName = `${product.name}-${rowCount}-${moment().format('L')}.csv`
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName.replace(' ', '_'))
  document.body.appendChild(link) // Required for FF

  link.click()
}

function shortCodeFor(prefix: string) {
  let uuid = uuidv4()
  uuid = uuid.split('-').join('')
  uuid = uuid.substring(0, uuid.length - 6)
  return `${prefix}${uuid}`
}

function eventShortCodeFor(prefix: string, eventCode: string) {
  let uuid = uuidv4()
  uuid = uuid.split('-').join('')
  uuid = uuid.substring(0, uuid.length - 6)
  return `${prefix}${eventCode}-${uuid}`
}
