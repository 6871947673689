import React, { useEffect, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import useUpdateOrganizationSubscription from 'hooks/use-update-organization-subscription'
import {
  OrganizationSubscription,
  OrganizationSubscriptionStatus,
} from 'interfaces/organization-subscription'
import { useGetOrganizationUsers } from 'hooks/use-get-organization-users'
import { User } from 'interfaces/user'
import { UserOrganization } from 'interfaces/user-organization'
import useSendProductCreditsEmails from 'hooks/use-send-product-credits-emails'
import { toast } from 'react-hot-toast'

const organizationSubscriptionStatuses = [
  { value: OrganizationSubscriptionStatus.Active },
  { value: OrganizationSubscriptionStatus.Trialing },
  { value: OrganizationSubscriptionStatus.PastDue },
  { value: OrganizationSubscriptionStatus.Canceled },
  { value: OrganizationSubscriptionStatus.Unpaid },
  { value: OrganizationSubscriptionStatus.TrialExpired },
]

export const EmailOrgAdminsOnProductCreditsModal = ({
  show,
  handleClose,
  orgId,
}: {
  show: boolean
  handleClose: () => void
  orgId: string
}) => {
  const [adminIds, setAdminIds] = useState<string[]>([])

  const { data: userOrganizationsData } = useGetOrganizationUsers(orgId)
  const orgAdmins = userOrganizationsData?.data?.user_organizations

  const { mutate: sendEmails } = useSendProductCreditsEmails(orgId)

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = event.target.checked
    if (value) {
      setAdminIds([...adminIds, id])
    } else {
      setAdminIds(adminIds.filter((adminId) => adminId !== id))
    }
  }

  const handleSendEmailsToAdmins = () => {
    sendEmails(adminIds, {
      onSuccess: () => {
        toast(`Emails have been sent...`, { icon: '🤙' })
        handleClose()
      },
    })
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>Organization Subscription Status Override</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {orgAdmins?.map((admin) => (
            <div key={admin.user.id}>
              <input
                style={{
                  marginRight: '8px',
                }}
                type="checkbox"
                onChange={(event) => handleCheckboxChange(event, admin.user.id)}
                id={admin.user.id}
                checked={adminIds?.includes(admin.user.id) || false}
              />
              <label className="form-check-label" htmlFor={admin.user.id}>
                {admin.user.name}
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSendEmailsToAdmins}>Send Emails</Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
