import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

const fetchTransferCard = async (
  recipientUserUuid: string,
  cardCode: string,
  makePrimary: boolean
) => {
  const response = await fetch(
    `${BASE_API_URL}/api/v2/cards/${cardCode}/transfers`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({
        recipient_user_uuid: recipientUserUuid,
        make_primary: makePrimary,
      }),
    }
  )
  handleErrors(response)
}

const useMutationTransferCard = ({
  cardsForUserQueryKey,
}: {
  cardsForUserQueryKey: QueryKey
}) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      recipientUserUuid,
      cardCode,
      makePrimary,
    }: {
      recipientUserUuid: string
      cardCode: string
      makePrimary: boolean
    }) => {
      return fetchTransferCard(recipientUserUuid, cardCode, makePrimary)
    },
    {
      // When mutate is called:
      onMutate: () => {},
      onSuccess: (data, variables, context) => {
        toast('Page successfully transferred', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(cardsForUserQueryKey)
      },
    }
  )
}

export default useMutationTransferCard
