import 'custom.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import reportWebVitals from 'reportWebVitals'

import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'
import { Provider } from 'react-redux'
import { persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import persistStore from 'redux-persist/es/persistStore'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from 'store/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { QueryClient, QueryClientProvider } from 'react-query'
import { FlatfileProvider } from '@flatfile/react'
import { FLATFILE_PUBLISHABLE_KEY } from 'constants/env'

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
}

const loggerMiddleware = createLogger()
const persistedReducer = persistReducer(persistConfig, rootReducer)

let middleware = []
if (process.env.NODE_ENV !== 'production') {
  middleware = [thunkMiddleware, loggerMiddleware]
} else {
  middleware = [thunkMiddleware]
}

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppDispatchThunk = ThunkDispatch<RootState, any, any>

const persistor = persistStore(store)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <FlatfileProvider publishableKey={FLATFILE_PUBLISHABLE_KEY}>
            <App />
          </FlatfileProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(() => {})
