import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { entitlementGroupingsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'
import { EntitlementGrouping } from 'interfaces/entitlement-grouping'

interface Params {
  entitlement_ids: number[]
  title: string
  key: string
}

const createEntitlementGrouping = async (apiParams: Params) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/entitlement_groupings`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(apiParams),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.entitlement_grouping as EntitlementGrouping
}

const useCreateEntitlementGrouping = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      entitlement_ids,
      title,
    }: {
      entitlement_ids: number[]
      title: string
    }) => {
      const apiParams = {
        entitlement_ids,
        title,
        key: title.replace(/ /g, '_').toLowerCase(),
      }
      return createEntitlementGrouping(apiParams)
    },
    {
      onError: () => {
        toast("Uh oh! this shouldn't happen. Shout in slack or report a bug.")
      },
      onSettled: () => {
        queryClient.invalidateQueries(entitlementGroupingsKeys.details)
      },
    }
  )
}

export default useCreateEntitlementGrouping
