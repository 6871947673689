import { API_METHODS } from 'constants/api-methods'
import { BASE_API_URL } from 'constants/env'
import { store } from 'index'
import { useMutation, useQueryClient } from 'react-query'
import { apiHeaders } from 'utils/api-headers'
import {
  organizationProductCreditsKeys,
  organizationSubscriptionItemsKeys,
} from 'utils/query-keys'
import { ID } from 'utils/types'

const useOrganizationUpdateProductCreditQuantity = (orgId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ productId, quantity }: { productId: ID; quantity: number }) =>
      fetch(
        `${BASE_API_URL}/api/v3/organizations/${orgId}/product_credits/${productId.toString()}`,
        {
          method: API_METHODS.PUT,
          headers: apiHeaders(store.getState()),
          body: JSON.stringify({ quantity: quantity }),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          organizationProductCreditsKeys.detail(orgId)
        )
        queryClient.invalidateQueries(
          organizationSubscriptionItemsKeys.detail(orgId)
        )
      },
    }
  )
}

export default useOrganizationUpdateProductCreditQuantity
