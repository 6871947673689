import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { unclaimedCardsKeys } from 'utils/query-keys'
import { Card } from 'interfaces/card'

const useUnclaimedCards = (orgId: string) => {
  return useQuery(
    unclaimedCardsKeys.index(orgId),
    () => fetchOrganizationUnclaimedCards(orgId),
    {
      enabled: !!orgId,
    }
  )
}

const fetchOrganizationUnclaimedCards = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/unclaimed_cards`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: { data: { cards: Card[] } } = await response.json()
  return json.data.cards
}

export default useUnclaimedCards
