import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { stripeSubscriptionsKeys } from 'utils/query-keys'

const useStripeSubscription = ({
  stripeSubscriptionId,
  enabled,
}: {
  stripeSubscriptionId: string
  enabled: boolean
}) => {
  return useQuery(
    stripeSubscriptionsKeys.detail(stripeSubscriptionId),
    () => fetchStripeSubscription(stripeSubscriptionId),
    {
      enabled: !!stripeSubscriptionId && enabled,
    }
  )
}

const fetchStripeSubscription = async (stripeSubscriptionId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/stripe/subscriptions/${stripeSubscriptionId}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json = await response.json()
  return json.data.subscription
}

export default useStripeSubscription
