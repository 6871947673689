import React, { useState } from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  InputGroup,
  Button,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { validateAuthCode } from 'store/actions/user-actions'
import { RootState } from 'index'

const LoginConfirmationPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { authConfirmationUUID } = useParams<any>()
  const isFetching = useSelector((state: RootState) => state.user.isFetching)
  const [authInputValue, setAuthInputValue] = useState('')

  const handleValidateCodeClick = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(validateAuthCode(authConfirmationUUID, authInputValue, history))
  }

  return (
    <Container>
      <Row>
        <Col lg={4} />
        <Col lg={4}>
          <Card className="mt-4 p-4 text-white bg-dark">
            <div className="card-body">
              <h4 className="card-title">Confirmation</h4>
              <div className="card-subtitle m-b-2 text-muted">
                A code was sent to you, just type it in.
              </div>
              <form onSubmit={handleValidateCodeClick}>
                <InputGroup className="mb-4 mt-4">
                  <FormControl
                    value={authInputValue}
                    onChange={(event) => setAuthInputValue(event.target.value)}
                    type="number"
                    inputMode="numeric"
                    placeholder="Confirmation Code"
                    aria-label="Confirmation Code"
                    autoFocus
                  />
                </InputGroup>
                <Button
                  disabled={isFetching}
                  // onClick={handleValidateCodeClick}
                  variant="success"
                  type="submit"
                >
                  Confirm
                </Button>
              </form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginConfirmationPage
