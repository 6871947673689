import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { EntitlementGrouping } from 'interfaces/entitlement-grouping'
import { Entitlement } from 'interfaces/entitlement'

interface ResponseData {
  data: {
    entitlements: Entitlement[]
  }
}

const fetchEntitlements = async () => {
  try {
    let response = await fetch(`${BASE_API_URL}/admin/api/v2/entitlements`, {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    })
    response = handleErrors(response)
    const json: ResponseData = await response.json()
    return json.data.entitlements
  } catch (error) {
    console.error('error', error)
  }
}

export const useEntitlements = () => {
  return useQuery([`admin/api/v2/entitlements`], () => fetchEntitlements(), {
    enabled: true,
  })
}
