import { User } from 'interfaces/user'
import moment from 'moment'
import { Card, Col } from 'react-bootstrap'

import TextInformationLine from '../text-information-line'

const UserProfile = ({ user }: { user: User }) => {
  return (
    <Col md="12">
      <Card className="mt-4">
        <Card.Header>
          <h4>User Details</h4>
        </Card.Header>
        <Card.Body>
          <TextInformationLine label="User ID" data={user?.id} />
          <TextInformationLine label="User UUID" data={user?.uuid} />
          <TextInformationLine
            label="Created At"
            data={moment(user?.created_at).format('LLL')}
          />
          <TextInformationLine label="Name" data={user?.name} />
          <TextInformationLine
            label="Email"
            data={user?.email ? user.email : 'N/A'}
          />
          <TextInformationLine
            label="Phone number"
            data={user?.phone_number ? user.phone_number : 'N/A'}
          />
          {user?.stripe_user_id && (
            <TextInformationLine
              label="Linq Pay Account"
              dataIsLink
              dataLinkSettings={{
                href: `https://dashboard.stripe.com/connect/accounts/${user?.stripe_user_id}`,
                target: '_blank',
              }}
              data={user?.stripe_user_id}
            />
          )}
          <TextInformationLine
            label="Can have unlimited pages?"
            data={user?.can_have_unlimited_pages ? 'Yes' : 'No'}
          />

          <TextInformationLine
            label="Linq Pro Subscription Kind"
            data={user?.subscription_kind || 'N/A'}
          />
          <TextInformationLine
            label="Linq Pro Subscription Status"
            data={user?.subscription_status || 'N/A'}
          />
          <TextInformationLine
            label="Linq Pro Subscription Tier"
            data={
              user?.is_pro_plus ? 'Pro Plus ➕' : user?.is_pro ? 'Pro' : 'N/A'
            }
          />
          {user?.subscription_kind === 'stripe' ? (
            <TextInformationLine
              label="Linq Pro Subscription ID"
              dataIsLink
              dataLinkSettings={{
                href: `https://dashboard.stripe.com/subscriptions/${user?.subscription_id}`,
                target: '_blank',
              }}
              data={user?.subscription_id || 'N/A'}
            />
          ) : (
            <TextInformationLine
              label="Linq Pro Subscription ID"
              data={user?.subscription_id || 'N/A'}
            />
          )}
        </Card.Body>
      </Card>
    </Col>
  )
}
export default UserProfile
