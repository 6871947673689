import { useEffect, useState } from 'react'
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  FormControl,
  Row,
} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useGetAllLinqProducts } from 'hooks/use-get-linq-products'
import CloudinaryUploadInput from 'components/cloudinary-upload-input'
import useCreateProductQrCodes from 'hooks/use-create-product-qr-codest'
import CopyToClipboardButton from 'components/copy-to-clipboard-button'

const QR_CODE_COLORS = [
  { name: 'Black', value: '#000000' },
  { name: 'White', value: '#ffffff' },
  { name: 'Red #cc0033', value: '#cc0033' },
  { name: 'Light Blue #3399cc', value: '#3399cc' },
  { name: 'Dark Blue #333399', value: '#333399' },
  { name: 'Green #339933', value: '#339933' },
  { name: 'Purple #663399', value: '#663399' },
]

const LOGO_TYPES = [
  'https://res.cloudinary.com/linq/image/upload/v1721771508/linq-logos-for-production-qr-codes/Linq_logo_154x154-white-square_oiq0pd.png',
  'https://res.cloudinary.com/linq/image/upload/v1721771509/linq-logos-for-production-qr-codes/Linq_logo_154x154-black-square_hnp3a4.png',
  'https://res.cloudinary.com/linq/image/upload/v1721771509/linq-logos-for-production-qr-codes/Linq_logo_154x154-white-circle_zqvjpo.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831165/linq-logos-for-production-qr-codes/Linq-Black_wws9br.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831166/linq-logos-for-production-qr-codes/Linq-Yellow_pghbsj.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831166/linq-logos-for-production-qr-codes/Linq-Red_geppx0.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831166/linq-logos-for-production-qr-codes/Linq-Purple_kezbpf.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831165/linq-logos-for-production-qr-codes/Linq-Cyan_nfzktp.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831165/linq-logos-for-production-qr-codes/Linq-Navy_ydcgbl.png',
  'https://res.cloudinary.com/linq/image/upload/v1721831165/linq-logos-for-production-qr-codes/Linq-Green_n8c4ng.png',
  'https://res.cloudinary.com/linq/image/upload/v1721837485/linq-logos-for-production-qr-codes/Oval_white_xfzwrm.png',
  'https://res.cloudinary.com/linq/image/upload/v1721837469/linq-logos-for-production-qr-codes/Oval_black_il81z3.png',
  'https://res.cloudinary.com/linq/image/upload/v1721837469/linq-logos-for-production-qr-codes/Rectangle_white_vvw0gw.png',
  'https://res.cloudinary.com/linq/image/upload/v1721837469/linq-logos-for-production-qr-codes/Rectangle_black_eni63o.png',
]

const FILE_FORMATS = [
  { name: 'PNG', value: 'png' },
  { name: 'PDF', value: 'pdf' },
]

const ProductQrCodeGenerationPage = () => {
  const { productId } = useParams<any>()
  const [linqProduct, setLinqProduct] = useState<any>()
  const [numberOfCodes, setNumberOfCodes] = useState<number>(10)
  const [qrCodeColor, setQrCodeColor] = useState<string>(
    QR_CODE_COLORS[0].value
  )
  const [logoUrl, setLogoUrl] = useState<string>(LOGO_TYPES[0])
  const [customLogoUrl, setCustomLogoUrl] = useState<string | undefined>(
    undefined
  )
  const [eventCode, setEventCode] = useState<string>('')
  const [fileFormat, setFileFormat] = useState<string>(FILE_FORMATS[0].value)
  const { data, isFetching } = useGetAllLinqProducts()
  const { mutate: createQrCodes, isLoading } = useCreateProductQrCodes()

  useEffect(() => {
    if (data) {
      const product = data.linq_products.find(
        (product) => product.id === parseInt(productId)
      )
      setLinqProduct(product)
    }
    // eslint-disable-next-line
  }, [data])

  const handleGenerateCodesClicked = () => {
    if (linqProduct?.is_event_badge && !eventCode) {
      alert('Event Code is required for event badges')
    }
    if (numberOfCodes < 1) {
      alert('Number of codes must be greater than 0')
    }
    createQrCodes({
      productId,
      numberOfCodes,
      eventCode,
      qrCodeColor,
      logoUrl: customLogoUrl || logoUrl,
      fileFormat,
    })
  }
  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Linq Products</Breadcrumb.Item>
        <Breadcrumb.Item href={`/products/${productId}`}>
          {linqProduct?.name || `Linq Product`}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {linqProduct?.name || `Linq Product`} QR Code Generation
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <h3>Loading...</h3>
      ) : (
        <div className="d-flex flex-column gap-4">
          <div
            className="d-flex flex-row gap-4"
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3>QR Code Generation</h3>
            {isLoading ? (
              <code>
                QR Codes are generating....Check Slack for download url
              </code>
            ) : (
              <Button size="lg" onClick={handleGenerateCodesClicked}>
                Generate QR Codes
              </Button>
            )}
          </div>
          <Row>
            <Col>
              <div className="form-floating mt-4 mb-1">
                <FormControl
                  onChange={(event) =>
                    setNumberOfCodes(Number(event.target.value) as any)
                  }
                  value={numberOfCodes}
                  type="number"
                  placeholder="Number of Codes"
                  aria-label="Number of Codes"
                />
                <label htmlFor="floatingInput">Number of Codes</label>
              </div>
              {linqProduct?.is_event_badge && (
                <>
                  <div className="form-floating mt-4 mb-1">
                    <FormControl
                      onChange={(event) => setEventCode(event.target.value)}
                      value={eventCode}
                      type="text"
                      placeholder="Event Code for The Event"
                      aria-label="Event Code for The Event"
                    />
                    <label htmlFor="floatingInput">Event Code for Event</label>
                  </div>
                  <Alert variant="warning">
                    This event code needs to be discussed with the engineers. If
                    you have not discussed this yet with the engineering team
                    please contact them to figure out the event code.
                  </Alert>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card className="p-4 d-flex flex-column gap-2">
                <h5 style={{ marginLeft: '0px' }}>QR Code Color</h5>
                <select
                  style={{
                    height: '40px',
                    fontSize: '20px',
                    padding: '4px',
                  }}
                  value={qrCodeColor}
                  onChange={(e) => {
                    setQrCodeColor(e.target.value)
                  }}
                >
                  {QR_CODE_COLORS?.map((color) => (
                    <option key={color.value} value={color.value}>
                      {color.name}
                    </option>
                  ))}
                </select>
              </Card>
            </Col>

            <Col lg="6">
              <Card className="p-4 d-flex flex-column gap-2">
                <h5 style={{ marginLeft: '0px' }}>File Format</h5>
                <select
                  style={{
                    height: '40px',
                    fontSize: '20px',
                    padding: '4px',
                  }}
                  value={fileFormat}
                  onChange={(e) => {
                    setFileFormat(e.target.value)
                  }}
                >
                  {FILE_FORMATS?.map((file) => (
                    <option key={file.value} value={file.value}>
                      {file.name}
                    </option>
                  ))}
                </select>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="p-4 d-flex flex-column gap-2">
                <h4 style={{ marginLeft: '0px' }}>QR Code Logo</h4>
                <div
                  className="d-flex flex-row gap-4 align-items-center"
                  style={{ flexFlow: 'wrap' }}
                >
                  {' '}
                  {LOGO_TYPES?.map((logo) => (
                    <div
                      className="p-2"
                      key={logo}
                      onClick={() => setLogoUrl(logo)}
                      style={
                        logo === logoUrl ? { border: '4px solid #0d8a00' } : {}
                      }
                    >
                      <img
                        src={logo}
                        alt="logo"
                        style={{ width: '80px', height: '80px' }}
                      />
                    </div>
                  ))}
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <CloudinaryUploadInput
              inputLabel="Custom Logo Image"
              imageUrl={customLogoUrl}
              onImageUrlChange={(value) => {
                setCustomLogoUrl(value)
              }}
            />
          </Row>
          {isLoading ? (
            <code>QR Codes are generating....Check Slack for download url</code>
          ) : (
            <Button size="lg" onClick={handleGenerateCodesClicked}>
              Generate QR Codes
            </Button>
          )}
        </div>
      )}
    </Container>
  )
}

export default ProductQrCodeGenerationPage
