import {
  FAILURE,
  FETCHING,
  GET_CURRENT_USER,
  LOGOUT_USER,
  REQUEST_AUTH_CONFIRMATION,
  SUCCESS,
  VALIDATE_AUTH_CODE,
} from 'constants/action-types'
import { User } from 'interfaces/user'
import { Reducer } from 'redux'

interface UserState {
  currentUser: null | User
  apiToken: null | string
  isFetching: boolean
}

const initialState: UserState = {
  currentUser: null,
  apiToken: null,
  isFetching: false,
}

const user: Reducer<UserState, any> = (state = initialState, action: any) => {
  switch (action.type) {
    // NOTE: Add new state here to clear on log out.
    case LOGOUT_USER:
      return initialState
    case SUCCESS(GET_CURRENT_USER):
      return {
        ...state,
        currentUser: action.json.user,
      }
    case FETCHING(VALIDATE_AUTH_CODE):
    case FETCHING(REQUEST_AUTH_CONFIRMATION):
      return {
        ...state,
        isFetching: true,
      }
    case SUCCESS(REQUEST_AUTH_CONFIRMATION):
      return {
        ...state,
        isFetching: false,
      }
    case FAILURE(VALIDATE_AUTH_CODE):
    case FAILURE(REQUEST_AUTH_CONFIRMATION):
      return {
        ...state,
        isFetching: false,
      }
    case SUCCESS(VALIDATE_AUTH_CODE):
      return {
        ...state,
        isFetching: false,
        apiToken: action.json.api_token,
      }
    default:
      return state
  }
}

export default user
