import { Button, Form, Modal } from 'react-bootstrap'
import { PushTokenTestData } from 'hooks/use-send-push-token-test'
import { useState } from 'react'
import { User } from 'interfaces/user'

export const EditPushTokenModal = ({
  setPushTokenTestData,
  onClose,
  show,
  user,
}: {
  setPushTokenTestData: (data: PushTokenTestData) => void
  onClose: () => void
  show: boolean
  user: User
}) => {
  const [title, setTitle] = useState<string>(
    `This is a test from ${user.name} 😄`
  )
  const [body, setBody] = useState<string>(`The body is here!`)
  const [imageUrl, setImageUrl] = useState<string>(
    'https://www.freepik.com/free-vector/cookies-logo-design-template_36295240.htm#page=2&query=funny%20logo&position=12&from_view=keyword&track=robertav1_2_sidr'
  )
  const [dataLinkRoute, setDataLinkRoute] = useState<string>(
    'https://linqapp.com/profile'
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPushTokenTestData({
      title,
      body,
      image_url: imageUrl,
      data_link_route: dataLinkRoute,
    })
    onClose()
  }
  return (
    <Modal
      size="lg"
      onHide={onClose}
      show={show}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="border border-1 p-4 rounded-3">
          <Form.Group controlId="title" className="mb-4">
            <Form.Label>Title</Form.Label>

            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="body" className="mb-4">
            <Form.Label>Body</Form.Label>

            <Form.Control
              type="text"
              placeholder="Enter body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="imageUrl" className="mb-4">
            <Form.Label>Image Url</Form.Label>

            <Form.Control
              type="text"
              placeholder="Enter image url"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="dataLinkUrl" className="mb-4">
            <Form.Label>Data Link Url</Form.Label>

            <Form.Control
              type="text"
              placeholder="Enter data link url"
              value={dataLinkRoute}
              onChange={(e) => setDataLinkRoute(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" size="lg" className="w-100">
            Submit
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
