import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'
import { LinqProduct } from 'interfaces/linq-product'

const sendUpdateLinqProduct = async (linqProduct: LinqProduct) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/linq_products/${linqProduct.id}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(linqProduct),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useMutationUpdateLinqProduct = () => {
  return useMutation(
    ({ linqProduct }: { linqProduct: LinqProduct }) => {
      return sendUpdateLinqProduct(linqProduct)
    },
    {
      onSuccess: (data, variables, context) => {
        toast('Product Updated', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useMutationUpdateLinqProduct
