import React from 'react'
import moment from 'moment'
import { Table, Card } from 'react-bootstrap'
import { AuthConfirmation } from 'interfaces/auth-confirmation'

type UserAuthenticationTableProps = {
  userAuthConfirmations?: AuthConfirmation[]
  isLoading: boolean
  handleClickCopy: (confirmationCode?: string) => Promise<void>
}

const UserAuthenticationTable = ({
  userAuthConfirmations = [],
  isLoading,
  handleClickCopy,
}: UserAuthenticationTableProps) => {
  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Recent Authentication Codes</h4>
      </Card.Header>
      <Card.Body>
        {isLoading && <Card.Text>Loading....</Card.Text>}
        {userAuthConfirmations?.length > 0 ? (
          <UserTable
            userAuthConfirmations={userAuthConfirmations}
            handleClickCopy={handleClickCopy}
          />
        ) : (
          <Card.Text>No recent confirmation code found</Card.Text>
        )}
      </Card.Body>
    </Card>
  )
}
export default UserAuthenticationTable

const UserTable = ({
  userAuthConfirmations,
  handleClickCopy,
}: {
  userAuthConfirmations: AuthConfirmation[]
  handleClickCopy: (authConfirmationCode: string) => void
}) => {
  return (
    <Table striped bordered hover size="sm" variant="dark">
      <thead>
        <tr>
          <td>Created At</td>
          <td>Usable</td>
          <td>Kind</td>
          <td>Confirmation Code</td>
          <td>Expires At</td>
        </tr>
      </thead>

      <tbody>
        {userAuthConfirmations?.map((auth) => (
          <tr key={auth.created_at}>
            <td>{moment(auth.created_at).format('lll')}</td>
            <td>{auth.auth_confirmation_codes?.[0]?.usable ? 'Yes' : 'No'}</td>
            <td>{auth.kind}</td>
            <td
              className="pointer"
              onClick={() =>
                handleClickCopy(
                  auth.auth_confirmation_codes?.[0]?.confirmation_code
                )
              }
            >
              {auth.auth_confirmation_codes?.[0]?.confirmation_code}
              <ion-icon style={{ float: 'right' }} name="clipboard-outline" />
            </td>
            <td>{moment(auth.expires_at).format('lll')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
