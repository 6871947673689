import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { ID } from 'utils/types'
import { PageTemplate } from 'interfaces/page-template'
import { TileGroup } from 'interfaces/tile-group'

interface ResponseData {
  data: {
    tile_groups: TileGroup[]
  }
}

const fetchTileGroups = async (orgId?: ID) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/api/v2/organizations/${orgId}/tile_groups`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: ResponseData = await response.json()
    return json.data.tile_groups
  } catch (error) {
    console.error('error', error)
  }
}

export const useOrganizationTileGroups = (orgId?: ID) => {
  return useQuery(
    [`/api/v2/organizations/${orgId}/tile_groups`],
    () => fetchTileGroups(orgId),
    {
      enabled: !!orgId,
    }
  )
}
