import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { teamsKeys } from 'utils/query-keys'

const useTeam = (teamId: any, options: any = {}) => {
  return useQuery(teamsKeys.detail(teamId), () => fetchTeam(teamId), {
    enabled: !!teamId,
    ...options,
  })
}

const fetchTeam = async (teamId: string) => {
  let response = await fetch(`${BASE_API_URL}/api/v2/teams/${teamId}`, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json.data.team
}

export default useTeam
