import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'
import { ZakekeData } from 'interfaces/zakeke-product'

const fetchCreateZakekeProduct = async (zakekeProduct: ZakekeData) => {
  let response = await fetch(`${BASE_API_URL}/admin/api/v1/zakeke_products`, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify(zakekeProduct),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useCreateZakekeProduct = () => {
  return useMutation(
    ({ zakekeProduct }: { zakekeProduct: ZakekeData }) => {
      return fetchCreateZakekeProduct(zakekeProduct)
    },
    {
      onSuccess: (data, variables, context) => {
        toast('Zakeke Product Created', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useCreateZakekeProduct
