import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { teamsKeys } from 'utils/query-keys'
import { Team } from 'interfaces/team'

const useOrganizationTeams = (orgId: string) => {
  return useQuery(teamsKeys.index(orgId), () => fetchOrganizationTeams(orgId), {
    enabled: !!orgId,
  })
}

const fetchOrganizationTeams = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/organizations/${orgId}/teams`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: { data: { teams: Team[] } } = await response.json()
  return json.data.teams
}

export default useOrganizationTeams
