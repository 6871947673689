import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import CreateProductProductionCodesModal from 'components/create-product-production-codes-modal'
import TextInformationLine from 'components/text-information-line'
import { useGetAllLinqProducts } from 'hooks/use-get-linq-products'

const ProductDetailPage = () => {
  const { productId } = useParams<any>()
  const history = useHistory()
  const [linqProduct, setLinqProduct] = useState<any>()
  const [showCreateProductionCodesModal, setShowCreateProductionCodesModal] =
    useState(false)
  const { data, isFetching } = useGetAllLinqProducts()

  useEffect(() => {
    if (data) {
      const product = data.linq_products.find(
        (product) => product.id === parseInt(productId)
      )
      setLinqProduct(product)
    }
    // eslint-disable-next-line
  }, [data])

  const handleEditClick = () => {
    history.push(`/products/${linqProduct?.id}/edit`)
  }

  const handleGenerateCodesClicked = () => {
    setShowCreateProductionCodesModal(true)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Linq Products</Breadcrumb.Item>
        <Breadcrumb.Item active>
          {linqProduct?.name || `Linq Product`}
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <h3>Loading...</h3>
      ) : (
        <>
          <h3 className="mt-4 mb-4">Linq Product</h3>
          <Button onClick={handleEditClick} variant="success" className="mb-4">
            Edit
          </Button>
          <Button
            onClick={handleGenerateCodesClicked}
            variant="secondary"
            className="mb-4 ms-2"
          >
            {linqProduct && linqProduct?.prefix.includes('appclip')
              ? 'Generate HYPER Production Codes'
              : 'Generate Production Codes '}
          </Button>
          <Button
            onClick={() =>
              history.push(`/products/${productId}/qr-code-generation`)
            }
            variant="primary"
            className="mb-4 ms-2"
          >
            Generate Production QR Codes{' '}
          </Button>
          <Row>
            <Col>
              <Card className="mt-2">
                <div className="card-body">
                  <h4>Product Data</h4>
                  <TextInformationLine
                    label="Product Id"
                    data={linqProduct?.id}
                  />
                  <TextInformationLine label="Name" data={linqProduct?.name} />
                  <TextInformationLine
                    label="Prefix"
                    data={linqProduct?.prefix}
                  />
                  <TextInformationLine
                    label="Event Badge?"
                    data={linqProduct?.is_event_product ? 'Yes' : 'No'}
                  />
                  <TextInformationLine
                    label="Updated At"
                    data={moment(linqProduct?.updated_at).format('lll')}
                  />
                  <TextInformationLine
                    label="Created At"
                    data={moment(linqProduct?.created_at).format('lll')}
                  />
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-4">
                <div className="card-body">
                  <h4>Product Image</h4>
                  <img
                    alt="product"
                    className="bg-white img-fluid img-thumbnail"
                    src={linqProduct?.image_path}
                  />
                </div>
              </Card>
            </Col>
            <Col>
              <Card className="mt-4">
                <div className="card-body">
                  <h4>Regular Education Image</h4>
                  <img
                    alt="product education"
                    className="bg-white img-fluid img-thumbnail"
                    src={linqProduct?.education_image_path}
                  />
                </div>
              </Card>
            </Col>
            <Col>
              <Card className="mt-4">
                <div className="card-body">
                  <h4>QR Education Image</h4>
                  <img
                    alt="product qr education"
                    className="bg-white img-fluid img-thumbnail"
                    src={linqProduct?.education_image_path_qr}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <CreateProductProductionCodesModal
        product={linqProduct}
        show={showCreateProductionCodesModal}
        handleClose={() => setShowCreateProductionCodesModal(false)}
      />
    </Container>
  )
}

export default ProductDetailPage
