import { useMutation, UseMutationOptions } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'

interface GenerateLogResponseData {
  url: string
}

const fetchGenerateLogInUrl = async (userUuid: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/users/${userUuid}/log_in_url/create`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: GenerateLogResponseData = await response.json()
  return json
}

type MutationOptions = UseMutationOptions<
  GenerateLogResponseData,
  unknown,
  string
>

const useMutationAdminGenerateLogInUrl = (
  sideEffectsObject: Partial<MutationOptions>
) => {
  return useMutation(
    (userUuid: string) => {
      return fetchGenerateLogInUrl(userUuid)
    },
    {
      ...sideEffectsObject,
      onError: (e) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useMutationAdminGenerateLogInUrl
