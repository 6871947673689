import React from 'react'

export const PaginationComponent = ({
  currentPage,
  setCurrentPage,
  totalPages,
}: {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  totalPages: number
}) => {
  const viewPreviousPage = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage(Number(currentPage) - 1)
  }

  const viewNextPage = () => {
    if (totalPages === currentPage) {
      return
    }
    setCurrentPage(Number(currentPage) + 1)
  }

  const viewPage = (pageNumber: number) => {
    setCurrentPage(Number(pageNumber))
  }
  return (
    <>
      <span className="me-2">Total Pages: {totalPages}</span>
      <div className="d-inline-block">
        <ul className="pagination m-0 p-0">
          <li className="ms-auto page-item">
            <div
              className={`${
                currentPage === 1 ? 'disabled' : ''
              } btn btn-info rounded-0 me-1`}
              onClick={viewPreviousPage}
              tabIndex={-1}
            >
              {' '}
              {'<'}{' '}
            </div>
          </li>
          <li className="page-item active">
            <div className="btn btn-info rounded-0 me-1">{currentPage}</div>
          </li>
          <li
            className={`page-item ${
              currentPage === totalPages ? 'active' : ''
            }`}
          >
            <div
              className="btn btn-info rounded-0 me-1"
              onClick={() => {
                viewPage(totalPages)
              }}
            >
              {totalPages}
            </div>
          </li>
          <li className="page-item">
            <div
              className={`${
                currentPage === totalPages ? 'disabled' : ''
              } btn btn-info rounded-0`}
              onClick={viewNextPage}
            >
              {' '}
              {'>'}{' '}
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}
