import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { LinqProduct } from 'interfaces/linq-product'
import { ZakekeProduct } from 'interfaces/zakeke-product'
import useZakekeProducts from 'hooks/use-zakeke-products'

const ZakekeProductsPage = () => {
  const history = useHistory()
  const [zakekeProducts, setZakekeProducts] = useState<ZakekeProduct[]>([])

  const { data, isFetching } = useZakekeProducts()

  useEffect(() => {
    if (data) {
      setZakekeProducts(data.zakeke_products)
    }
  }, [data])

  const handleZakekeProductClicked = (zakekeProduct: ZakekeProduct) => {
    history.push(`/zakeke_products/${zakekeProduct.id}/edit`)
  }

  const handleNewProductClick = () => {
    history.push(`/create-zakeke-product`)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/zakeke_products">
          Zakeke Products
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Zakeke Products</h3>
      <Button
        className="mb-3"
        variant="success"
        onClick={handleNewProductClick}
      >
        Create New Product
      </Button>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Product Name</th>
            <th>Variant</th>
            <th>SKU</th>
            <th>Jig Template</th>
            <th>Updated At</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            zakekeProducts?.map((zakekeProduct) => (
              <tr
                className="pointer"
                key={zakekeProduct.id}
                onClick={() => handleZakekeProductClicked(zakekeProduct)}
              >
                <td>{zakekeProduct.id}</td>
                <td className="">{zakekeProduct.product_name}</td>
                <td>{zakekeProduct.variant}</td>
                <td>
                  <code>{zakekeProduct.sku}</code>
                </td>
                <td>{zakekeProduct.product_jig_template_name}</td>
                <td>{moment(zakekeProduct.updated_at).format('lll')}</td>
                <td>{moment(zakekeProduct.created_at).format('lll')}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default ZakekeProductsPage
