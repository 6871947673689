import React, { useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import LinqProductForm from 'components/linq-product-form'
import useMutationCreateLinqProduct from 'hooks/use-mutation-create-linq-product'
import { LinqProductData } from 'interfaces/linq-product'
import ZakekeProductForm from 'components/zakeke-product-form'
import { ZakekeData } from 'interfaces/zakeke-product'
import useCreateZakekeProduct from 'hooks/use-create-zakeke-product'

const ZakekeProductCreatePage = () => {
  const [zakekeProduct, setZakekeProduct] = useState<ZakekeData>({
    product_name: '',
    variant: '',
    sku: '',
    product_jig_template_id: 0,
  })
  const createZakekeProductMutation = useCreateZakekeProduct()
  const history = useHistory()

  const handleSaveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()

    createZakekeProductMutation.mutate(
      { zakekeProduct },
      {
        onSuccess: (data) => {
          history.push(`/zakeke_products`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/zakeke_products">
          Zakeke Products
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/create-zakeke-product" active>
          Create Linq Product
        </Breadcrumb.Item>
      </Breadcrumb>
      <>
        <h3 className="mt-4 mb-4">Create New Zakeke Product</h3>
        <ZakekeProductForm
          zakekeProduct={zakekeProduct}
          setZakekeProduct={setZakekeProduct}
          handleSaveClick={handleSaveClick}
        />
      </>
    </Container>
  )
}

export default ZakekeProductCreatePage
