import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { bulkUploadsKeys } from 'utils/query-keys'

const fetchBulkUpload = async (bulkUploadId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/bulk_uploads/${bulkUploadId}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json = await response.json()
  return json.data.bulk_upload
}

const useBulkUpload = ({ bulkUploadId }: { bulkUploadId: string }) => {
  return useQuery(
    bulkUploadsKeys.index(bulkUploadId),
    () => fetchBulkUpload(bulkUploadId),
    { enabled: !!bulkUploadId }
  )
}

export default useBulkUpload
