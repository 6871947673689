import useDeleteUserOrganization from 'hooks/use-delete-user-organization'
import { useGetOrganizationUsers } from 'hooks/use-get-organization-users'
import { UserOrganization } from 'interfaces/user-organization'
import { useEffect, useState } from 'react'
import { Card, Button, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

interface OrganizationMembersTableProps {
  handleAddUserToOrganizationClicked: () => void
  orgId: string
}
const OrganizationMembersTable = ({
  handleAddUserToOrganizationClicked,
  orgId,
}: OrganizationMembersTableProps) => {
  const history = useHistory()
  const [roleFilterValue, setRoleFilterValue] = useState('all')
  const [userOrganizations, setUserOrganizations] = useState<
    UserOrganization[]
  >([])

  const {
    data: userOrganizationsData,
    isFetching: isUserOrganizationsFetching,
    queryKey: getOrganizationUsersQueryKey,
  } = useGetOrganizationUsers(orgId)

  const { mutate: deleteUserOrganization } = useDeleteUserOrganization(orgId)

  useEffect(() => {
    if (userOrganizationsData?.data?.user_organizations) {
      setUserOrganizations(userOrganizationsData?.data?.user_organizations)
    }
  }, [userOrganizationsData?.data?.user_organizations])

  const handleBulkUploadClick = () => {
    history.push(`/organizations/${orgId}/bulk-uploads`)
  }

  const handleUserClicked = (userOrg: UserOrganization) => {
    history.push(`/user/${userOrg.user.uuid}`)
  }

  const handleRemoveUserClicked = (
    clickEvent: any,
    userOrg: UserOrganization
  ) => {
    clickEvent.stopPropagation()
    if (
      window.confirm(
        `Are you sure you want to remove the user '${userOrg.user.name}' from the organization?`
      ) === true
    ) {
      deleteUserOrganization(
        { userOrg: userOrg },
        {
          onSuccess: () => {
            toast(`${userOrg.user.name} removed from the organization`, {
              icon: `🚪`,
            })
          },
        }
      )
    }
  }

  const handleFilterRole = ({ value }: { value: string }) => {
    setRoleFilterValue(value)
    if (value === 'all') {
      setUserOrganizations(
        userOrganizationsData?.data?.user_organizations || []
      )
    } else if (value === 'admin_only') {
      const onlyAdminUsers =
        userOrganizationsData?.data?.user_organizations.filter(
          (userOrganization) => userOrganization.is_admin === true
        )
      setUserOrganizations(onlyAdminUsers || [])
    }
  }
  return (
    <Card className="mt-4">
      {isUserOrganizationsFetching ? (
        <div className="m-2">Loading...</div>
      ) : (
        <>
          <Card.Header>
            <h4>Organization Members</h4>
          </Card.Header>
          <Card.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <Button
              onClick={handleAddUserToOrganizationClicked}
              variant="success"
              className="mb-3 me-3"
            >
              Add User to Organization
            </Button>
            <Button className="mb-3" onClick={handleBulkUploadClick}>
              Bulk Uploads
            </Button>
            <Table striped bordered hover responsive variant="dark">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>
                    <select
                      value={roleFilterValue}
                      onChange={(e) => handleFilterRole(e.target)}
                    >
                      <option value="all">All Role</option>
                      <option value="admin_only">Admin Only</option>
                    </select>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userOrganizations &&
                  userOrganizations.map((userOrganization, index) => (
                    <tr
                      key={index}
                      className="pointer"
                      onClick={() => handleUserClicked(userOrganization)}
                    >
                      <td>{userOrganization.user.name}</td>
                      <td>{userOrganization.user.email}</td>
                      <td>{userOrganization.user.phone_number}</td>
                      <td>{userOrganization.is_admin ? 'Admin' : 'Member'}</td>
                      <td
                        onClick={(e) =>
                          handleRemoveUserClicked(e, userOrganization)
                        }
                      >
                        <ion-icon name="close-outline" color="danger" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </>
      )}
    </Card>
  )
}

export default OrganizationMembersTable
