import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'
import { ZakekeProduct } from 'interfaces/zakeke-product'

const putUpdateZakekeProduct = async (
  zakekeProduct: Partial<ZakekeProduct>
) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/zakeke_products/${zakekeProduct?.id}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(zakekeProduct),
    }
  )

  response = handleErrors(response)
  const json = await response.json()

  return json
}

const useUpdateZakekeProduct = () => {
  return useMutation(
    ({ zakekeProduct }: { zakekeProduct: Partial<ZakekeProduct> }) => {
      return putUpdateZakekeProduct(zakekeProduct)
    },
    {
      onSuccess: (data, variables, context) => {
        toast('Zakeke Product Updated', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useUpdateZakekeProduct
