import { Button, Modal, Table } from 'react-bootstrap'
import { PushToken } from 'interfaces/push-token'

export const PushTokenResultsModal = ({
  show,
  onClose,
  pushToken,
}: {
  show: boolean
  onClose: () => void
  pushToken: PushToken
}) => {
  const { token } = pushToken
  return (
    <Modal
      size="lg"
      onHide={onClose}
      show={show}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>
          Token: {token.length > 40 ? `${token.slice(0, 30)}...` : token}
        </Modal.Title>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>Request Result</th>
              <th>Request Options</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {pushToken?.push_notification_results?.map((result) => {
              const {
                id,
                request_result: requestResult,
                request_options: requestOptions,
                created_at: createdAt,
              } = result
              return (
                <tr key={id}>
                  <td>{requestResult}</td>
                  <td>{requestOptions}</td>
                  <td>{createdAt}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
