import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import LinqProductForm from 'components/linq-product-form'
import { useGetAllLinqProducts } from 'hooks/use-get-linq-products'
import useMutationUpdateLinqProduct from 'hooks/use-mutation-update-linq-product'

const ProductEditPage = () => {
  const { productId } = useParams<any>()
  const history = useHistory()
  const [linqProduct, setLinqProduct] = useState<any>()
  const { data, isFetching } = useGetAllLinqProducts()

  const updateLinqProductMutation = useMutationUpdateLinqProduct()

  useEffect(() => {
    if (data) {
      const product = data.linq_products.find(
        (product) => product.id === parseInt(productId)
      )
      setLinqProduct(product)
    }
    // eslint-disable-next-line
  }, [data])

  const handleSaveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    updateLinqProductMutation.mutate(
      { linqProduct },
      {
        onSuccess: (data) => {
          history.push(`/products/${linqProduct.id}`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Linq Products</Breadcrumb.Item>
        <Breadcrumb.Item href={`/products/${linqProduct?.id}`}>
          {linqProduct?.name || `Linq Product`}
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/products" active>
          Edit
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <h3>Loading...</h3>
      ) : (
        <>
          <h3 className="mt-4 mb-4">Edit Product</h3>
          <LinqProductForm
            isEdit
            linqProduct={linqProduct}
            setLinqProduct={setLinqProduct}
            handleSaveClick={handleSaveClick}
          />
        </>
      )}
    </Container>
  )
}

export default ProductEditPage
