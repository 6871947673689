import { useQuery } from 'react-query'
import { store } from '..'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'
import { userAuthConfirmationKeys } from 'utils/query-keys'
import { AuthConfirmation } from 'interfaces/auth-confirmation'

type AuthConfirmationResponseData = {
  data: {
    auth_confirmations: AuthConfirmation[]
  }
}

// get 5 recent auth confirmation code only.
const RESULTS_PER_PAGE = 5
const PAGE_NUMBER = 1

const fetchUserAuthConfirmation = async (
  uuid: string
): Promise<AuthConfirmationResponseData> => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${uuid}/auth_confirmations?page=${PAGE_NUMBER}&results_per_page=${RESULTS_PER_PAGE}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: AuthConfirmationResponseData = await response.json()
  return json
}

const useGetUserAuthConfirmation = (uuid: string) => {
  return useQuery(
    userAuthConfirmationKeys.detail(uuid),
    () => fetchUserAuthConfirmation(uuid),
    {
      enabled: !!uuid,
    }
  )
}
export default useGetUserAuthConfirmation
