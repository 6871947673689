import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'

const createIntegrationConnectionTest = async (
  integrationConnectionId: string | number
) => {
  const url = `${BASE_API_URL}/api/v2/integration_connections/${integrationConnectionId}/test`
  let response = await fetch(url, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
  })
  response = await handleErrorsAndJSON(response)
  const json = await response.json()
  return json.data
}

const useTestIntegrationConnection = () => {
  return useMutation(
    ({
      integrationConnectionId,
    }: {
      integrationConnectionId: string | number
    }) => createIntegrationConnectionTest(integrationConnectionId),
    {
      onError: (e: Error) => {
        alert(
          `Hmm... There was an error! Take a screenshot and send to an engineer: ${e.message}`
        )
        console.error(e)
      },
      onSettled: () => {},
    }
  )
}

export default useTestIntegrationConnection
