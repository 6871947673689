import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'

type UploadData = {}

const createBulkUpload = async (orgId: string, uploadData: UploadData) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/organizations/${orgId}/bulk_uploads`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(uploadData),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useCreateBulkUpload = ({ orgId }: { orgId: string }) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ uploadData }: any) => {
      return createBulkUpload(orgId, uploadData)
    },
    {
      onSuccess: (data, variables, context) => {
        toast('Data has been accepted and is being processed', { icon: `👍` })
        queryClient.invalidateQueries(
          organizationsKeys.bulkUploads.index(orgId)
        )
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useCreateBulkUpload
