import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'

interface IntegrationConnection {
  id: string
  organization_id: string
}

const updateIntegrationConnection = async (
  integrationConnection: IntegrationConnection
) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/integration_connections/${integrationConnection?.id}`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(integrationConnection),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const createIntegrationConnection = async (
  integrationConnection: IntegrationConnection
) => {
  let response = await fetch(`${BASE_API_URL}/api/v2/integration_connections`, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify(integrationConnection),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useSaveIntegrationConnection = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      integrationConnection,
    }: {
      integrationConnection: IntegrationConnection
    }) => {
      if (integrationConnection?.id) {
        return updateIntegrationConnection(integrationConnection)
      }
      return createIntegrationConnection(integrationConnection)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(
          organizationsKeys.integrationConnections.index(
            variables.integrationConnection?.organization_id
          )
        )
        toast('Integration has been saved', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useSaveIntegrationConnection
