import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationSubscriptionItemsKeys } from 'utils/query-keys'
import { StripeSubscriptionItem } from 'components/organization-details-page/organization-stripe-card'

const organizationSubscriptionItems = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/organization_subscription_items`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.stripe_subscription_items as StripeSubscriptionItem[]
}

const useOrganizationSubscriptionItems = (orgId: string) => {
  return useQuery(
    organizationSubscriptionItemsKeys.detail(orgId),
    () => organizationSubscriptionItems(orgId),
    {
      enabled: !!orgId,
    }
  )
}

export default useOrganizationSubscriptionItems
