import React from 'react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap'
import CloudinaryUploadInput from './cloudinary-upload-input'
import { LinqProduct, LinqProductData } from 'interfaces/linq-product'

const LinqProductForm = ({
  isEdit,
  linqProduct,
  setLinqProduct,
  handleSaveClick,
}: {
  isEdit?: boolean
  linqProduct: LinqProductData
  setLinqProduct: React.Dispatch<React.SetStateAction<LinqProductData>>
  handleSaveClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) => {
  const handleInputChange = (newValue: string | undefined, key: string) => {
    setLinqProduct((linqProduct) => ({
      ...linqProduct,
      [key]: newValue,
    }))
  }

  return (
    <>
      <Form>
        <FormGroup className="mb-4 mt-4">
          <FormLabel>Product Name</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) => handleInputChange(event.target.value, 'name')}
            value={linqProduct?.name}
            type="text"
            placeholder="Product Name"
            aria-label="Product Name"
          />
        </FormGroup>
        {!isEdit && (
          <FormGroup className="mb-4 mt-4">
            <FormLabel>Product Prefix</FormLabel>
            <FormControl
              autoComplete="off"
              onChange={(event) =>
                handleInputChange(event.target.value, 'prefix')
              }
              value={linqProduct?.prefix}
              type="text"
              placeholder="Product Prefix"
              aria-label="Product Prefix"
            />
          </FormGroup>
        )}
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Is Event Badge"
            checked={linqProduct?.is_event_product}
            onChange={(event) =>
              handleInputChange(
                event.target.checked.toString(),
                'is_event_product'
              )
            }
          />
        </Form.Group>

        <Row>
          <Col>
            <CloudinaryUploadInput
              inputLabel="Main Product Image"
              imageUrl={linqProduct?.image_path}
              onImageUrlChange={(value) => {
                handleInputChange(value, 'image_path')
              }}
            />
          </Col>
          <Col>
            <CloudinaryUploadInput
              inputLabel="Product Education Image"
              imageUrl={linqProduct?.education_image_path}
              onImageUrlChange={(value) => {
                handleInputChange(value, 'education_image_path')
              }}
            />
          </Col>
          <Col>
            <CloudinaryUploadInput
              inputLabel="Product Education QR Image"
              imageUrl={linqProduct?.education_image_path_qr}
              onImageUrlChange={(value) => {
                handleInputChange(value, 'education_image_path_qr')
              }}
            />
          </Col>
        </Row>
        <Button
          className="mt-4 mb-4"
          size="lg"
          variant="success"
          onClick={(e) => handleSaveClick(e)}
          type="submit"
        >
          Save
        </Button>
      </Form>
    </>
  )
}

export default LinqProductForm
