import useMutationDeleteWorkosOrganization from 'hooks/use-mutation-delete-workos-organization'
import { Button } from 'react-bootstrap'

const DeleteWorkosOrganizationButton = ({
  workosOrganizationId,
}: {
  workosOrganizationId: number | string
}) => {
  const { mutate: deleteWorkosOrganization } =
    useMutationDeleteWorkosOrganization()

  const deleteOrgFromWorkos = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete this organization from WorkOS? This will remove all SSO connections from this organization.'
      ) !== true
    )
      return null

    deleteWorkosOrganization({ workosOrganizationId })
  }

  return (
    <Button variant="outline-danger" onClick={deleteOrgFromWorkos}>
      Delete From WorkOS
    </Button>
  )
}

export default DeleteWorkosOrganizationButton
