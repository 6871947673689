import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

interface Params {
  productId: string
  numberOfCodes: number
  eventCode?: string
  qrCodeColor: string
  logoUrl: string
  fileFormat: string
}

const createQrCodes = async ({
  productId,
  numberOfCodes,
  eventCode,
  qrCodeColor,
  logoUrl,
  fileFormat,
}: Params) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/linq_products/${productId}/generate_qr_codes`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({
        number_of_codes: numberOfCodes,
        qr_code_color: qrCodeColor,
        logo_url: logoUrl,
        file_format: fileFormat,
        ...(eventCode && { event_code: eventCode }),
      }),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useCreateProductQrCodes = () => {
  return useMutation(
    ({
      productId,
      numberOfCodes,
      eventCode,
      qrCodeColor,
      logoUrl,
      fileFormat,
    }: Params) => {
      return createQrCodes({
        productId,
        numberOfCodes,
        eventCode,
        qrCodeColor,
        logoUrl,
        fileFormat,
      })
    },

    {
      onMutate: () => {
        toast(
          'QR Codes file is being generated - Check out the slack #ops-qr-codes-generation for download link',
          { icon: `🕓` }
        )
      },
      onError: (e) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useCreateProductQrCodes
