import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'
import { Organization } from 'interfaces/organization'

type ResponseData = {
  data: {
    organization: Organization
  }
}

type OrganizationData = Partial<Organization>

const createOrganization = async (organizationData: OrganizationData) => {
  let response = await fetch(`${BASE_API_URL}/admin/api/v2/organizations`, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify(organizationData),
  })
  response = handleErrors(response)
  const json: ResponseData = await response.json()
  return json.data.organization
}

const useAdminCreateOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ organizationData }: { organizationData: OrganizationData }) => {
      return createOrganization(organizationData)
    },
    {
      onSuccess: (data, variables, context) => {
        toast('Organization created!', { icon: `👍` })
        queryClient.invalidateQueries(organizationsKeys.all)
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
    }
  )
}

export default useAdminCreateOrganization
