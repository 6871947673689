import { useState } from 'react'
import { Form } from 'react-bootstrap'

interface EditableCellProps {
  value?: string
  onValueChange: (val: string) => void
  type: 'text' | 'dropdown'
  options?: string[]
}

const EditableCell = ({
  value,
  onValueChange,
  type,
  options = [],
}: EditableCellProps & { type: 'text' | 'dropdown'; options?: string[] }) => {
  const [editing, setEditing] = useState(false)
  const [newValue, setNewValue] = useState(value)

  const handleEdit = () => setEditing(true)

  const handleChange = (e: { target: { value: string } }) =>
    setNewValue(e.target.value)

  const handleBlur = () => {
    setEditing(false)
    onValueChange(newValue || '')
  }

  return editing ? (
    type === 'dropdown' ? (
      <Form.Control
        as="select"
        value={newValue}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      >
        {options.map((opt) => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </Form.Control>
    ) : (
      <Form.Control
        type="text"
        value={newValue}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      />
    )
  ) : (
    <div onClick={handleEdit}>{value}</div>
  )
}

export default EditableCell
