import { RootState } from 'index'
import { API_SECRET, ENVIRONMENT } from 'constants/env'

export const apiHeaders = (state: RootState): HeadersInit => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-LINQ-API-TOKEN': state.user.apiToken || '',
  'X-GA': API_SECRET,
  ...(ENVIRONMENT === 'development' && {
    'ngrok-skip-browser-warning': 'true',
  }),
})
