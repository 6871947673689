import React, { useState, useEffect, useCallback } from 'react'
import {
  Table,
  Breadcrumb,
  InputGroup,
  FormControl,
  Button,
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import useOrganizationsSearch from 'hooks/use-organizations-search'
import { PaginationComponent } from 'components/pagination-component'
import InternalAdminSelectComponent from 'components/internal-admin-select-component'
import { Organization } from 'interfaces/organization'

const OrganizationsPage = () => {
  const RESULTS_PER_PAGE = 50
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const [totalPages, setTotalPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [organizations, setOrganizations] = useState<Organization[]>()
  const [query, setQuery] = useState(params.query || '')
  const [accountManager, setAccountManager] = useState('all')
  const [saleRep, setSaleRep] = useState('all')
  const [filterByValue, setFilterByValue] = useState('ACCOUNT_MANAGER')
  const { data, refetch, isFetching } = useOrganizationsSearch({
    query: params.query || '',
    page: currentPage.toString(),
    results_per_page: RESULTS_PER_PAGE.toString(),
  })

  useEffect(() => {
    setQuery(params.query || '')
    refetch()
  }, [params.query, refetch, currentPage])

  const handleFilterAccountManager = useCallback(() => {
    if (accountManager === 'all') {
      setOrganizations(data?.organizations)
    } else if (accountManager === 'none') {
      const filteredOrganization = data?.organizations?.filter(
        (org) => org?.account_manager_user_id === null
      )
      setOrganizations(filteredOrganization)
    } else {
      const filteredOrganization = data?.organizations?.filter(
        (org) => org?.account_manager_user_id === parseInt(accountManager)
      )
      setOrganizations(filteredOrganization)
    }
  }, [accountManager, data?.organizations])

  const handleFilterSaleRep = useCallback(() => {
    if (saleRep === 'all') {
      setOrganizations(data?.organizations)
    } else if (saleRep === 'none') {
      const filteredOrganization = data?.organizations?.filter(
        (org) => org?.organization_subscription?.sale_rep_id === null
      )
      setOrganizations(filteredOrganization)
    } else {
      const filteredOrganization = data?.organizations?.filter(
        (org) =>
          org?.organization_subscription?.sale_rep_id === parseInt(saleRep)
      )
      setOrganizations(filteredOrganization)
    }
  }, [saleRep, data?.organizations])

  useEffect(() => {
    if (data) {
      if (filterByValue === 'ACCOUNT_MANAGER') {
        handleFilterAccountManager()
      } else if (filterByValue === 'SALE_REP') {
        handleFilterSaleRep()
      }
      setTotalPages(parseInt(data.number_of_pages))
      setCurrentPage(parseInt(data.page))
    }
  }, [
    data,
    accountManager,
    filterByValue,
    handleFilterSaleRep,
    handleFilterAccountManager,
  ])
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuery(event.target.value)
  }

  const handleOrganizationClick = (id: string) => {
    history.push(`/organizations/${id}`)
  }

  const handleSubmit = () => {
    history.push(`/organizations?query=${query}`)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/organizations">
          Organizations
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 className="mt-4 mb-4">Organizations</h3>
        <Button
          variant="success"
          onClick={() => history.push('/organizations/new')}
        >
          + New Organization
        </Button>
      </div>
      <InputGroup className="mb-4 mt-4">
        <FormControl
          autoComplete="off"
          onChange={handleSearchInputChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
              handleSubmit()
            }
          }}
          value={query}
          type="text"
          placeholder="Search"
          aria-label="Search Organizations"
        />
        <InputGroup.Append>
          <Button variant="success" onClick={handleSubmit}>
            <ion-icon name="search" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <Row>
        <Col>
          <div className="m-2">
            <span className="form-label h4" style={{ marginRight: '1rem' }}>
              Filter By
            </span>
            <select
              value={filterByValue}
              onChange={(e) => setFilterByValue(e.target.value)}
              className="d-inline-block form-select w-50"
            >
              <option value="ACCOUNT_MANAGER">Account Manager</option>
              <option value="SALE_REP">Sale Representative</option>
            </select>
          </div>
          {filterByValue === 'ACCOUNT_MANAGER' ? (
            <InternalAdminSelectComponent
              label="Account Manager"
              value={accountManager}
              setState={setAccountManager}
            />
          ) : (
            <InternalAdminSelectComponent
              label="Sale Representative"
              value={saleRep}
              setState={setSaleRep}
            />
          )}
        </Col>
        <Col md="auto">
          {totalPages && (
            <PaginationComponent
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </Col>
      </Row>
      {isFetching ? (
        <div className="m-2">Searching...</div>
      ) : (
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>Logo</th>
              <th>Name</th>
              <th>Tier</th>
            </tr>
          </thead>
          <tbody>
            {organizations?.map((org, index) => (
              <tr
                key={org.id}
                className="pointer"
                onClick={() => handleOrganizationClick(org.id)}
              >
                <td style={{ width: '100px' }}>{org.id}</td>
                <td style={{ width: '100px' }}>
                  {org.badge_logo_url && (
                    <img
                      src={org.badge_logo_url}
                      alt="org logo"
                      className="w-100"
                    />
                  )}
                </td>
                <td>{org.name}</td>
                <td>{org.organization_internal_metadatum?.tier}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default OrganizationsPage
