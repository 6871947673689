import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { Organization } from 'interfaces/organization'

const fetchOrganization = async (orgId: string) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/api/v2/organizations/${orgId}`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: { data: { organization: Organization } } = await response.json()
    return json.data.organization
  } catch (error) {
    console.error('error', error)
  }
}

const useOrganization = (orgId: string) => {
  return useQuery(organizationsKeys.detail(orgId), () =>
    fetchOrganization(orgId)
  )
}

export default useOrganization
