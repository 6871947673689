import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container, Table } from 'react-bootstrap'
import { useGetAllPrintFiles } from 'hooks/use-get-print-files'
import { PrintFile } from 'interfaces/print-file'

const PrintFilesPage = () => {
  const [printFiles, setPrintFiles] = useState<PrintFile[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const { data, isFetching } = useGetAllPrintFiles(currentPage)

  useEffect(() => {
    if (data) {
      setPrintFiles(data.print_files)
      setTotalPages(parseInt(data.number_of_pages))
      setCurrentPage(parseInt(data.page))
    }
  }, [data])

  const viewPreviousPage = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage(Number(currentPage) - 1)
  }

  const viewNextPage = () => {
    if (totalPages === currentPage) {
      return
    }
    setCurrentPage(Number(currentPage) + 1)
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/zakeke_orders">Zakeke Orders</Breadcrumb.Item>
        <Breadcrumb.Item active href="/print_files">
          Print Files
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className="mt-4 mb-4">Print Files</h3>
      {isFetching ? (
        <div className="m-2">Loading...</div>
      ) : (
        <>
          <div className="position-relative">
            <div className="text-end">
              Viewing Page {currentPage} of {totalPages}
            </div>
            <nav>
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <div
                    className="page-link"
                    onClick={viewPreviousPage}
                    tabIndex={-1}
                  >
                    Previous
                  </div>
                </li>
                <li className="page-item active">
                  <div className="page-link">
                    {currentPage === 1 ? 1 : currentPage - 1}
                  </div>
                </li>
                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <div className="page-link" onClick={viewNextPage}>
                    Next
                  </div>
                </li>
              </ul>
            </nav>
          </div>

          <Table striped bordered hover responsive variant="dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>Negative Print File</th>
                <th>Color Print File</th>
                <th>Order Reference File</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {printFiles?.map((printFile) => (
                <tr key={`printFile${printFile.id}`}>
                  <td>{printFile.id}</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={printFile.negative_s3_print_file_url}
                    >
                      {printFile.negative_s3_print_file_url
                        ? 'Download'
                        : 'N/A'}
                    </a>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={printFile.color_s3_print_file_url}
                    >
                      {printFile.color_s3_print_file_url ? 'Download' : 'N/A'}
                    </a>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={printFile.s3_order_reference_file_url}
                    >
                      {' '}
                      Download{' '}
                    </a>
                  </td>
                  <td>{printFile.created_by}</td>
                  <td>{moment(printFile?.created_at).format('lll')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default PrintFilesPage
