import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'
import { ID } from 'utils/types'

const resetLinqPhone = async (userId: ID) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/scripts/reset_linq_phone`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({ user_uuid: userId }),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useResetLinqPhone = () => {
  return useMutation(resetLinqPhone, {
    onSuccess: (data, variables, context) => {
      toast('Linq phone reset', { icon: `👍` })
    },
    onError: (e, variables, context) => {
      console.error(e)
      alert(
        'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
      )
    },
  })
}

export default useResetLinqPhone
