import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationSubscriptionItemsKeys } from 'utils/query-keys'
import { StripeSubscriptionItem } from 'components/organization-details-page/organization-stripe-card'
import toast from 'react-hot-toast'

const sendEmails = async (orgId: string, userIds: string[]) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/send_product_credits_emails`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify({ user_ids: userIds }),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.stripe_subscription_items as StripeSubscriptionItem[]
}

const useSendProductCreditsEmails = (orgId: string) => {
  return useMutation((userIds: string[]) => sendEmails(orgId, userIds), {
    onError: (e: Error) => {
      toast(`Uh oh! There was an error. ${e.message}`)
    },
  })
}

export default useSendProductCreditsEmails
