import React from 'react'
import { Button, Card } from 'react-bootstrap'

const CloudinaryUploadInput = ({
  inputLabel,
  imageUrl,
  onImageUrlChange,
}: {
  inputLabel: string
  imageUrl?: string
  onImageUrlChange: (url?: string) => void
}) => {
  const handleUploadClicked = () => {
    showUploadWidget()
  }

  const handleDeleteImageclick = () => {
    onImageUrlChange()
  }

  const showUploadWidget = () => {
    ;(window as any).cloudinary.openUploadWidget(
      {
        cloudName: 'linq',
        uploadPreset: 'linq_product_images_upload_preset',
        sources: ['local', 'url'],
        googleApiKey: '<image_search_google_api_key>',
        showAdvancedOptions: true,
        cropping: true,
        multiple: false,
        defaultSource: 'local',
        styles: {
          palette: {
            window: '#ffffff',
            sourceBg: '#f4f4f5',
            windowBorder: '#90a0b3',
            tabIcon: '#000000',
            inactiveTabIcon: '#555a5f',
            menuIcons: '#555a5f',
            link: '#000000',
            action: '#335F99',
            inProgress: '#0433ff',
            complete: '#339933',
            error: '#cc0000',
            textDark: '#000000',
            textLight: '#fcfffd',
          },
          fonts: {
            default: null,
            "'Space Mono', monospace": {
              url: 'https://fonts.googleapis.com/css?family=Space+Mono',
              active: true,
            },
          },
        },
      },
      (
        error: Error,
        result: { event: string; info: { secure_url: string } }
      ) => {
        if (!error && result && result.event === 'success') {
          if (process.env.NODE_ENV !== 'production') {
            console.info('cloudinary image data', result)
          }
          onImageUrlChange(result.info.secure_url)
        }
      }
    )
  }

  return (
    <>
      <Card className="mt-4 mb-4 p-2">
        <Card.Body>
          <Card.Title>{inputLabel}</Card.Title>
          <Button
            className="d-flex align-items-center gap-2"
            onClick={handleUploadClicked}
            variant="secondary"
          >
            <ion-icon name="cloud-upload-outline" />{' '}
            {imageUrl ? 'Change Image' : 'Upload Image'}
          </Button>
          {imageUrl ? (
            <Button
              className="ms-1"
              onClick={handleDeleteImageclick}
              variant="outline-danger"
            >
              <ion-icon name="trash-outline" />
            </Button>
          ) : (
            <></>
          )}
        </Card.Body>
        <Card.Img variant="top" src={imageUrl} style={{ maxWidth: '512px' }} />
      </Card>
    </>
  )
}

export default CloudinaryUploadInput
