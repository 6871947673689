import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { LinqProduct } from 'interfaces/linq-product'

interface ResponseData {
  linq_products: LinqProduct[]
}

const fetchLinqProducts = async () => {
  try {
    let response = await fetch(`${BASE_API_URL}/api/v1/linq_products`, {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    })
    response = handleErrors(response)
    const json: ResponseData = await response.json()
    return json
  } catch (error) {
    console.error('error', error)
  }
}

export const useGetAllLinqProducts = () => {
  return useQuery([`api/v1/linq_products`], () => fetchLinqProducts(), {
    enabled: true,
  })
}
