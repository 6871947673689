import useOrganization from 'hooks/use-organization'
import useOrganizationSubscriptionItems from 'hooks/use-organization-subscription-items'
import { Organization } from 'interfaces/organization'
import { Card, Button, Table } from 'react-bootstrap'

interface OrganizationStripeCardProps {
  setShowSubscriptionIdUpdateModal: (show: boolean) => void
  org?: Organization
  orgId: string
  isInHouseTrial: boolean
}
export interface StripeSubscriptionItem {
  product_name: string
  quantity: number
  status: string
}
export const OrganizationStripeCard = ({
  setShowSubscriptionIdUpdateModal,
  org,
  orgId,
  isInHouseTrial,
}: OrganizationStripeCardProps) => {
  const { data: subscriptionItems } = useOrganizationSubscriptionItems(orgId)
  return (
    <Card className="mt-4">
      <Card.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '24px',
          }}
        >
          Stripe Subscription Details
        </p>
        <span
          style={{
            cursor: 'pointer',
            backgroundColor: '#303030',
            color: '#fff',
            padding: '8px',
            fontSize: '16px',
            borderRadius: '4px',
          }}
          onClick={() => setShowSubscriptionIdUpdateModal(true)}
        >
          {org?.organization_subscription?.subscription_id}
        </span>
      </Card.Header>
      <Card.Body>
        {!isInHouseTrial && (
          <Button
            className="mb-4"
            style={{
              width: '100%',
            }}
            onClick={() => {
              window.open(
                `https://dashboard.stripe.com/subscriptions/${org?.organization_subscription?.subscription_id}`,
                '_blank'
              )
            }}
          >
            Go to Stripe
          </Button>
        )}
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>Product</th>
              <th>Status</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionItems &&
              subscriptionItems.length > 0 &&
              subscriptionItems.map((item) => (
                <tr key={item.product_name}>
                  <td>{item.product_name}</td>
                  <td>{item.status}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
