import React from 'react'
import {
  Accordion,
  Button,
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap'
import { ProductJigTemplateData } from 'interfaces/product-jig-template'

const ProductJigTemplateForm = ({
  productJigTemplate,
  setProductJigTemplate,
  handleSaveClick,
}: {
  productJigTemplate: ProductJigTemplateData
  setProductJigTemplate: React.Dispatch<
    React.SetStateAction<ProductJigTemplateData>
  >
  handleSaveClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) => {
  const handleInputChange = (
    value: string | boolean | undefined,
    fieldName: string
  ) => {
    setProductJigTemplate({
      ...productJigTemplate,
      [fieldName]: value,
    })
  }

  return (
    <>
      <Form>
        <FormGroup className="mb-4">
          <FormLabel>Jig Name</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'jig_name')
            }
            value={productJigTemplate.jig_name}
            type="text"
            placeholder="Jig Name"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Design Width (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'design_width')
            }
            value={productJigTemplate.design_width}
            type="number"
            placeholder="Design Width"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Design Height (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'design_height')
            }
            value={productJigTemplate.design_height}
            type="number"
            placeholder="Design Height"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>
            S3 Template File Name (e.g. custom_cards_white.tif)
          </FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 's3_template_file_name')
            }
            value={productJigTemplate.s3_template_file_name}
            type="text"
            placeholder="Custom Card S3 Template File Name"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Left Margin (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'left_margin')
            }
            value={productJigTemplate.left_margin}
            type="number"
            placeholder="Left Margin"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Top Margin (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'top_margin')
            }
            value={productJigTemplate.top_margin}
            type="number"
            placeholder="Top Margin"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Bottom Margin (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'bottom_margin')
            }
            value={productJigTemplate.bottom_margin}
            type="number"
            placeholder="Bottom Margin"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Right Margin (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'right_margin')
            }
            value={productJigTemplate.right_margin}
            type="number"
            placeholder="Right Margin"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>
            Output Print File Name (e.g. final_custom_cards_logo.tif)
          </FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'output_print_file_name')
            }
            value={productJigTemplate.output_print_file_name}
            type="text"
            placeholder="Output Print File Name"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>
            Output Reference File Name (e.g. final_custom_cards_logo.tif)
          </FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(
                event.target.value,
                'output_reference_file_name'
              )
            }
            value={productJigTemplate.output_reference_file_name}
            type="text"
            placeholder="Output Reference File Name"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Rows</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) => handleInputChange(event.target.value, 'rows')}
            value={productJigTemplate.rows}
            type="number"
            placeholder="Rows"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Columns</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(event.target.value, 'columns')
            }
            value={productJigTemplate.columns}
            type="number"
            placeholder="Columns"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Horizontal Distance Between Designs (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(
                event.target.value,
                'horizontal_distance_between_designs'
              )
            }
            value={productJigTemplate.horizontal_distance_between_designs}
            type="number"
            placeholder="Horizontal Distance Between Designs"
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormLabel>Vertical Distance Between Designs (mm)</FormLabel>
          <FormControl
            autoComplete="off"
            onChange={(event) =>
              handleInputChange(
                event.target.value,
                'vertical_distance_between_designs'
              )
            }
            value={productJigTemplate.vertical_distance_between_designs}
            type="number"
            placeholder="Vertical Distance Between Designs"
          />
        </FormGroup>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Button} eventKey="1">
            Manage Optional Fields
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <>
              <FormGroup className="mb-4 mt-4">
                <FormLabel>Gutter Start</FormLabel>
                <FormControl
                  autoComplete="off"
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'gutter_start')
                  }
                  value={productJigTemplate.gutter_start}
                  type="number"
                  placeholder="Gutter Start"
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Gutter Width</FormLabel>
                <FormControl
                  autoComplete="off"
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'gutter_width')
                  }
                  value={productJigTemplate.gutter_width}
                  type="number"
                  placeholder="Gutter Width"
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Use PNG</FormLabel>
                <FormCheck
                  onChange={(event) =>
                    handleInputChange(event.target.checked, 'use_png')
                  }
                  checked={productJigTemplate.use_png}
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Use SVG</FormLabel>
                <FormCheck
                  onChange={(event) =>
                    handleInputChange(event.target.checked, 'use_svg')
                  }
                  checked={productJigTemplate.use_svg}
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Rotate Column 0</FormLabel>
                <FormControl
                  autoComplete="off"
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'rotate_column_0')
                  }
                  value={productJigTemplate.rotate_column_0}
                  type="number"
                  placeholder="Rotate Column 0"
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Rotate Column 1</FormLabel>
                <FormControl
                  autoComplete="off"
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'rotate_column_1')
                  }
                  value={productJigTemplate.rotate_column_1}
                  type="number"
                  placeholder="Rotate Column 1"
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Rotate Column 2</FormLabel>
                <FormControl
                  autoComplete="off"
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'rotate_column_2')
                  }
                  value={productJigTemplate.rotate_column_2}
                  type="number"
                  placeholder="Rotate Column 2"
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Rotate Column 3</FormLabel>
                <FormControl
                  autoComplete="off"
                  onChange={(event) =>
                    handleInputChange(event.target.value, 'rotate_column_3')
                  }
                  value={productJigTemplate.rotate_column_3}
                  type="number"
                  placeholder="Rotate Column 3"
                />
              </FormGroup>
            </>
          </Accordion.Collapse>
        </Accordion>
        <Button
          className="mt-4 mb-4"
          size="lg"
          variant="success"
          onClick={(e) => handleSaveClick(e)}
          type="submit"
        >
          Save
        </Button>
      </Form>
    </>
  )
}

export default ProductJigTemplateForm
