const useConstructUrl = () => {
  const constructUrl = (
    url: string,
    params: Record<string, string | number | boolean>
  ) => {
    const formattedParams = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&')
    return formattedParams ? `${url}?${formattedParams}` : url
  }

  return { constructUrl }
}

export default useConstructUrl
