import React, { useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProductJigTemplateForm from 'components/product-jig-template-form'
import { ProductJigTemplateData } from 'interfaces/product-jig-template'
import useCreateProductJigTemplate from 'hooks/use-create-product-jig-template'
import { toast } from 'react-hot-toast'

const ProductJigTemplateCreatePage = () => {
  const [productJigTemplate, setProductJigTemplate] =
    useState<ProductJigTemplateData>({
      jig_name: '',
      design_width: 0,
      design_height: 0,
      s3_template_file_name: '.tif',
      left_margin: 0,
      top_margin: 0,
      bottom_margin: 0,
      right_margin: 0,
      output_print_file_name: '.tif',
      output_reference_file_name: '.tif',
      rows: 0,
      columns: 0,
      horizontal_distance_between_designs: 0,
      vertical_distance_between_designs: 0,
      gutter_start: 0,
      gutter_width: 0,
      use_png: true,
      use_svg: false,
      rotate_column_0: 0,
      rotate_column_1: 0,
      rotate_column_2: 0,
      rotate_column_3: 0,
    })

  const createProductJigTemplate = useCreateProductJigTemplate()

  const history = useHistory()

  const handleSaveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    createProductJigTemplate.mutate(
      { newProductJigTemplate: productJigTemplate },
      {
        onSuccess: (data) => {
          toast.success('Product Jig Template created successfully!')
          // history.push(`/products/${data.id}`)
        },
      }
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/product-jig-templates">
          Product Jig Templates
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/create-product-jig-template" active>
          New
        </Breadcrumb.Item>
      </Breadcrumb>
      <>
        <h3 className="mt-4 mb-4">Create New Product Jig Template</h3>
        <ProductJigTemplateForm
          productJigTemplate={productJigTemplate}
          setProductJigTemplate={setProductJigTemplate}
          handleSaveClick={handleSaveClick}
        />
      </>
    </Container>
  )
}

export default ProductJigTemplateCreatePage
