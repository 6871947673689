import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { zakekeOrderLineQueryKeys } from 'utils/query-keys'

const fetchZakekeOrder = async (orderId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v1/zakeke_line_items/${orderId}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useZakekeOrderDetail = (id: string) => {
  return useQuery(
    zakekeOrderLineQueryKeys.detail(id),
    () => fetchZakekeOrder(id),
    {
      enabled: !!id,
    }
  )
}

export default useZakekeOrderDetail
