import { Button, Modal, Table } from 'react-bootstrap'
import { User } from 'interfaces/user'
import { PushToken } from 'interfaces/push-token'
import { useState } from 'react'
import moment from 'moment'

export const PushTokensModal = ({
  show,
  onClose,
  user,
  pushTokens: pushTokenData,
  handleSeePushTokenClicked,
  handleTestPushTokenClicked,
  setShowEditPushTokenModal,
}: {
  show: boolean
  onClose: () => void
  user: User
  pushTokens: PushToken[]
  handleSeePushTokenClicked: (pushToken: PushToken) => void
  handleTestPushTokenClicked: (pushToke?: PushToken) => void
  setShowEditPushTokenModal: (show: boolean) => void
}) => {
  const [pushTokens, setPushTokens] = useState<PushToken[]>(pushTokenData)
  const [filterTokenValue, setFilterTokenValue] = useState<string>('all')

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setFilterTokenValue(value)
    if (value === 'valid_token') {
      setPushTokens(pushTokenData.filter((pushToken) => pushToken.is_valid))
    } else if (value === 'invalid_token') {
      setPushTokens(pushTokenData.filter((pushToken) => !pushToken.is_valid))
    } else {
      setPushTokens(pushTokenData)
    }
  }
  return (
    <Modal
      size="lg"
      onHide={onClose}
      show={show}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>{user?.name}'s Push Tokens</Modal.Title>
        <Button onClick={() => handleTestPushTokenClicked()}>
          Test for User
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>Token</th>
              <th>Device Type</th>
              <th>Device UUID</th>
              <th>
                <select value={filterTokenValue} onChange={handleFilterChange}>
                  <option value="all">All Tokens</option>
                  <option value="valid_token">Valid Tokens</option>
                  <option value="invalid_token">Invalid Tokens</option>
                </select>
              </th>
              <th>Results Count</th>
              <th>Results</th>
              <th>Test</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {pushTokens?.map((pushToken) => {
              const {
                id,
                token,
                device_type: deviceType,
                device_uuid: deviceUuid,
                is_valid: isValid,
                push_notification_results: results,
                created_at: createdAt,
              } = pushToken
              return (
                <tr key={id}>
                  <td>
                    {token.length > 40 ? `${token.slice(0, 30)}...` : token}
                  </td>
                  <td>{deviceType}</td>
                  <td>{deviceUuid}</td>
                  <td>{isValid ? 'Valid' : 'Invalid'}</td>
                  <td>{results?.length}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleSeePushTokenClicked(pushToken)}
                    >
                      See Results
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleTestPushTokenClicked(pushToken)}
                    >
                      Send Test
                    </Button>
                  </td>
                  <tr>{moment(createdAt).format('lll')}</tr>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowEditPushTokenModal(true)}>
          Edit Notification
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
