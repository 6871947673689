import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'
import { teamsKeys } from 'utils/query-keys'
import { Organization } from 'interfaces/organization'
import { UserOrganization } from 'interfaces/user-organization'

const useUserTeams = (teamId: string) => {
  return useQuery(
    teamsKeys.userTeams.index(teamId),
    () => fetchUserTeams(teamId),
    { enabled: !!teamId }
  )
}

interface ResponseData {
  data: { user_teams: UserOrganization[] }
}

const fetchUserTeams = async (teamId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v2/teams/${teamId}/user_teams`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: ResponseData = await response.json()
  return json.data.user_teams
}

export default useUserTeams
