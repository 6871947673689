export const BASE_API_URL = process.env.REACT_APP_API_URL
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL
export const APP_CLIP_WEB_URL = 'https://clip.trylinq.com'
export const API_SECRET: string = process.env.REACT_APP_API_SECRET as string

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
export const STRIPE_MONTHLY_PRICE_KEY =
  process.env.REACT_APP_STRIPE_MONTHLY_PRICE_KEY
export const STRIPE_YEARLY_PRICE_KEY =
  process.env.REACT_APP_STRIPE_YEARLY_PRICE_KEY

export const STRIPE_TEAMS_YEARLY_PRICE_KEY =
  process.env.REACT_APP_STRIPE_TEAMS_YEARLY_PRICE_KEY
export const STRIPE_TEAMS_MONTHLY_PRICE_KEY =
  process.env.REACT_APP_STRIPE_TEAMS_MONTHLY_PRICE_KEY
export const STRIPE_CUSTOM_CARD_PRICE_KEY =
  process.env.REACT_APP_STRIPE_CUSTOM_CARD_PRICE_KEY
export const STRIPE_CUSTOM_CARD_DISCOUNT_ID =
  process.env.REACT_APP_STRIPE_CUSTOM_CARD_DISCOUNT_ID

export const STRIPE_CONNECT_CLIENT_KEY =
  process.env.REACT_APP_STRIPE_CONNECT_CLIENT_KEY

export const FLATFILE_LICENSE = process.env.REACT_APP_FLATFILE_LICENSE

export const LINQ_FOR_TEAMS_PRICING_URL =
  process.env.REACT_APP_LINQ_FOR_TEAMS_PRICING_URL

export const FLATFILE_PUBLISHABLE_KEY =
  process.env.REACT_APP_FLATFILE_PUBLISHABLE_KEY ||
  'pk_a3ee3c98dbe14fe198f3361ca795f41f'
