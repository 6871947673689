import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys, teamsKeys } from 'utils/query-keys'
import toast from 'react-hot-toast'
import { DirectoryMapping } from 'interfaces/directory-mapping'
import { ID } from 'utils/types'

interface RequestParams {
  orgId: ID
  directoryMapping: Partial<DirectoryMapping>
}

const fetchCreateDirectoryMapping = async ({
  orgId,
  directoryMapping,
}: RequestParams) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v3/organizations/${orgId}/directory_mappings`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(directoryMapping),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.directory_mapping
}

const useCreateDirectoryMapping = (orgId: ID) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ directoryMapping }: { directoryMapping: Partial<DirectoryMapping> }) =>
      fetchCreateDirectoryMapping({ orgId, directoryMapping }),
    {
      onError: (e) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      onSuccess: (data) => {
        toast('Directory Mapping Created', { icon: `👍` })
      },
      onSettled: (userTeam) => {
        queryClient.invalidateQueries(
          organizationsKeys.directoryMappings.index(orgId)
        )
      },
    }
  )
}

export default useCreateDirectoryMapping
