import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { userDeviceInfoKeys } from 'utils/query-keys'
import { MobileDeviceInfo } from 'interfaces/mobile-device-info'

type MobileDeviceInfoResponseData = {
  data: {
    mobile_device_infos: MobileDeviceInfo[]
  }
}

const fetchUserDeviceInfos = async (uuid: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/users/${uuid}/mobile_device_infos?page=1&results_per_page=5`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json: MobileDeviceInfoResponseData = await response.json()
  return json.data.mobile_device_infos
}

const useUserDeviceInfos = (uuid: string) => {
  return useQuery(userDeviceInfoKeys.all, () => fetchUserDeviceInfos(uuid), {
    enabled: !!uuid,
  })
}

export default useUserDeviceInfos
