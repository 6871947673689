import { useQuery } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { entitlementGroupingsKeys } from 'utils/query-keys'
import { EntitlementGrouping } from 'interfaces/entitlement-grouping'

const fetchEntitlementGrouping = async (entitlementGroupingId?: number) => {
  try {
    let response = await fetch(
      `${BASE_API_URL}/admin/api/v2/entitlement_groupings/${entitlementGroupingId}`,
      {
        method: API_METHODS.GET,
        headers: apiHeaders(store.getState()),
      }
    )
    response = handleErrors(response)
    const json: { data: { entitlement_grouping: EntitlementGrouping } } =
      await response.json()
    return json.data.entitlement_grouping
  } catch (error) {
    console.error('error', error)
  }
}

const useEntitlementGrouping = (entitlementGroupingId?: number) => {
  return useQuery(
    entitlementGroupingsKeys.detail(entitlementGroupingId || ''),
    () => fetchEntitlementGrouping(entitlementGroupingId),
    {
      enabled: !!entitlementGroupingId,
    }
  )
}

export default useEntitlementGrouping
