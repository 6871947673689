import React, { useState } from 'react'
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap'
import useUpdateOrganizationOrganizationSubscriptionId from 'hooks/use-update-organization-subscription'

export const UpdateOrgSubscriptionIdModal = ({
  show,
  handleClose,
  orgId,
  setShowSubscriptionIdUpdateModal,
}: {
  show: boolean
  handleClose: () => void
  orgId: string
  setShowSubscriptionIdUpdateModal: (show: boolean) => void
}) => {
  const [newSubscriptionId, setNewSubscriptionId] = useState('')
  const {
    mutate: updateOrgSubscriptionId,
    isLoading: isUpdatingOrgSubscriptionId,
  } = useUpdateOrganizationOrganizationSubscriptionId(orgId)

  const onClose = () => {
    setNewSubscriptionId('')
    handleClose()
  }

  const handleUpdateOrgSubscriptionId = () => {
    updateOrgSubscriptionId(
      {
        subscription_id: newSubscriptionId,
      },
      {
        onSuccess: () => {
          setShowSubscriptionIdUpdateModal(false)
        },
      }
    )
  }
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>Add New Subscription Id</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewSubscriptionId(e.target.value)
            }
            type="text"
            placeholder="Enter new subscription id here"
            aria-label="new subscription id"
            aria-describedby="new subscription id"
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isUpdatingOrgSubscriptionId}
          onClick={handleUpdateOrgSubscriptionId}
        >
          Update
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
