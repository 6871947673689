import React from 'react'
import { useGetInternalAdmins } from 'hooks/use-get_internal-admins'

const InternalAdminSelectComponent = ({
  label,
  value,
  setState,
}: {
  label: string
  value: string
  setState: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { data: internalAdmins, isLoading: isInternalAdminsLoading } =
    useGetInternalAdmins()

  return (
    <div className="m-2">
      <span className="form-label h4" style={{ marginRight: '1rem' }}>
        {' '}
        {label}
      </span>
      {isInternalAdminsLoading ? (
        <span>{label} is loading......</span>
      ) : (
        <select
          value={value}
          onChange={(e) => setState(e.target.value)}
          className="d-inline-block form-select w-50"
        >
          <option value="all">Select {label}.....</option>
          <option value="none">No {label}</option>
          {internalAdmins?.map((option) => (
            <option value={option.id} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

export default InternalAdminSelectComponent
