import { useMutation } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import toast from 'react-hot-toast'

interface ResponseData {
  data: {}
}

const sendAnalyticsEmail = async () => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/analytics_email/generate_all`,
    {
      method: API_METHODS.POST,
      headers: apiHeaders(store.getState()),
    }
  )
  response = await handleErrorsAndJSON(response)
  const json: ResponseData = await response.json()
  return json.data
}

const useSendAnalyticsEmail = () => {
  return useMutation((x: null) => sendAnalyticsEmail(), {
    onError: (e: Error) => {
      toast(`Uh oh! There was an error. ${e.message}`)
    },
    onSuccess: () => {
      toast(`Email jobs have been queued...`, { icon: '🤙' })
    },
  })
}

export default useSendAnalyticsEmail
