import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'
import { unclaimedCardsKeys } from 'utils/query-keys'

const fetchDeleteCard = async (cardId: string) => {
  const response = await fetch(`${BASE_API_URL}/admin/api/v2/cards/${cardId}`, {
    method: API_METHODS.DELETE,
    headers: apiHeaders(store.getState()),
  })
  handleErrors(response)
}

const useDeleteCard = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ cardId }: any) => {
      return fetchDeleteCard(cardId)
    },
    {
      // When mutate is called:
      onMutate: () => {},
      onSuccess: (data, variables, context) => {
        toast('Page successfully deleted', { icon: `👍` })
      },
      onError: (e, variables, context) => {
        console.error(e)
        alert(
          'Uh oh! There was an error! Check the console. Take a screenshot and send to engineers.'
        )
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(unclaimedCardsKeys as any)
      },
    }
  )
}

export default useDeleteCard
