import React, { useState } from 'react'
import { Button, Form, FormControl, Modal } from 'react-bootstrap'

const EditStripeSubscriptionIdModal = ({
  show,
  handleSaveClick,
  initialStripeSubscriptionId,
  handleClose,
}: {
  show: boolean
  handleSaveClick: (id: string) => void
  initialStripeSubscriptionId: string
  handleClose: () => void
}) => {
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState(
    initialStripeSubscriptionId
  )

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Enter Stripe Subscription Id</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Stripe Subscription Id</Form.Label>
            <FormControl
              autoComplete="off"
              onChange={(event) => setStripeSubscriptionId(event.target.value)}
              value={stripeSubscriptionId}
              type="text"
              placeholder="Stripe Subscription Id"
              aria-label="Stripe Subscription Id"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => handleSaveClick(stripeSubscriptionId)}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditStripeSubscriptionIdModal
