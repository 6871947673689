import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ID } from 'utils/types'

export const EditOrgProductCreditQuantityModal = ({
  show,
  handleUpdateClick,
  productId,
  initialQuantity,
  handleClose,
}: {
  show: boolean
  handleUpdateClick: (
    productCreditId: ID,
    productCreditQuantity: number
  ) => void
  productId: ID
  initialQuantity: number
  handleClose: () => void
}) => {
  const [productCreditQuantity, setProductCreditQuantity] =
    useState<number>(initialQuantity)
  useEffect(() => {
    setProductCreditQuantity(initialQuantity)
  }, [initialQuantity])
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Edit Product Credit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="productCreditQuantity">
            <Form.Control
              type="number"
              min={0}
              value={productCreditQuantity}
              onChange={(event) =>
                setProductCreditQuantity(Number(event.target.value))
              }
              placeholder="Enter quantity"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Dismiss
        </Button>
        <Button
          variant="success"
          onClick={() => handleUpdateClick(productId, productCreditQuantity)}
        >
          Update Credits
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
